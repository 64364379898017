import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";
import casino from "./config";
import React from "react";

//affdynasty versions translated + 7.2 changed
const untilWeDontHaveOther = {
    title: "INTRODUCTION",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Playing at the casino, whether for “fun play” or “real play” constitutes the member’s (player)
                agreement to be bound by the following terms and conditions contained herein. The player accepts that
                this is a binding agreement between the player and the casino and is the sole agreement governing the
                player’s relationship with the casino, to the exclusion of all other agreements, statements, and
                representations.
            </li>

            <li>1.2. Each clause contained in these terms and conditions shall be separate and severable from each of
                the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining
                terms and conditions shall remain of full force and effect.
            </li>
            <li>1.3. The Company reserves the right of final decision on the interpretation of these Terms and
                Conditions. Email and chat services may provide information and updates about our service. Communication
                by email or chat cannot modify or revoke the Terms and Conditions. Failure on chat or email to cover our
                Terms and Conditions or to mention a portion of our Terms and Conditions does not constitute grounds for
                violating the Terms and Conditions. In case of dispute, the Terms and Conditions hold final authority
                and will be enforced over any information provided over chat or email. All players are required to read
                the Terms and Conditions prior to registration.
            </li>
            <li>1.4. The following Terms and Conditions govern the use of the {casino.name} website and online casino:
            </li>
            <li>1.5. IMPORTANT: All deposits are final. No refund can be completed once the deposits have been played at
                our games.
            </li>
        </ul>
    </>],

    sections: [{
        title: "WEBSITE AND CASINO CONTENT",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. All software and website content found on the {casino.name} Casino website is copyrighted by
                    the
                    Casino (the “Licensed Materials”). All Licensed Material that are presented visually or audibly to
                    the player are for personal use only and remain the intellectual property of the Casino and its
                    licensees. No player shall acquire any right whatsoever to all or any part of the Licensed Materials
                    except the right to use the Licensed Materials in accordance with this Agreement.
                </li>
            </ul>
        </>]

    },
        {
            title: "PLAYER OBLIGATION",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. In becoming a member (player) of the casino, you fully understand and agree to abide by all
                        rules, terms and conditions set forth by {casino.name} from time to time. It is the player’s
                        responsibility to keep up to date with these rules, terms and conditions as they are subject to
                        periodic change.
                    </li>
                    <li>3.2. Playing at {casino.name} Online Casino is restricted to adults only. Players must be 25
                        years
                        of age or older to play. Age restrictions may change according to local national law, and it is
                        the responsibility of the player to check for the minimum age allowed in their country. The
                        Casino reserves the right to require age verification from the player at any time.
                    </li>
                    <li>3.3. Players from the following countries may not be allowed to open an account at our casino:
                        Afghanistan, Armenia, Azerbaijan, Bangladesh, Belarus, Bhutan, Bulgaria, Cayman Islands, Costa
                        Rica, Denmark, Estonia, Hungary, Kazakhstan, Kyrgyzstan, Netherlands, Netherlands Antilles,
                        Poland, Romania, Singapore, Syria, Turkmenistan, and Virgin Islands. Accounts will be closed and
                        all game winnings or losses voided.
                    </li>
                    <li>3.4. In creating an account and becoming a player at the casino, whether for “fun play” or “real
                        play”, you guarantee the authenticity and accuracy of all information you submit. If it is
                        determined by {casino.name} that any information it was given by the player is
                        false, {casino.name}
                        reserves the right to suspend a player’s account along with all related cash-ins. The account
                        will remain suspended until such time as {casino.name} has determined in its sole discretion
                        that
                        the player’s information was authentic and accurate.
                    </li>
                    <li>3.5. {casino.name} reserves the right to refuse membership in the casino to any applicant or to
                        suspend an existing member’s playing privileges without cause and at {casino.name}’s sole
                        discretion.
                    </li>
                    <li>3.6. In placing wagers at the casino, the player realizes that this is their voluntary
                        involvement and is equal to gambling. Participation carries the risk of losing money and
                        {casino.name} shall under no circumstances be liable for any losses or damages whatsoever
                        incurred
                        by the Player in connection with the player’s use of the casino.
                    </li>
                    <li>3.7. Any deposit made to the casino must be wagered once before a withdrawal can be requested.
                        If deposit is not wagered at least once before withdrawal, the casino reserves the right to
                        charge the player for any processing fees.
                    </li>
                    <li>3.8. Using electronic, mechanical, robotic or other devices to automatically make playing
                        decisions is resolutely forbidden. If it is detected by {casino.name} that a player has violated
                        this term, action may and will be taken including banning the player from future promotions
                        and/or play, confiscating of net winnings, and the offering of such information to a casino
                        industry database of “restricted players”.
                    </li>
                    <li>3.9. By playing any game at the casino, the player warrants that the player is familiar with the
                        rules of such a game and acknowledges that the rules of each game are available through using
                        the “help” facility at the casino.
                    </li>
                    <li>3.10. When depositing with debit/credit card, additional third-party costs may be applied by the
                        Player's credit card company and/or the card associations and as such are beyond the Company's
                        control. Please note that you may be charged an additional foreign transaction fee per
                        transaction depending on the deposited amount by the credit card processing banks. Usually,
                        foreign transaction fees are a percentage of the amount of each foreign currency purchase, with
                        no minimum or maximum, reaching even 6-8% at times. These are fees charged by the transaction
                        processors such as VISA and MC. Most issuing banks choose to pass this fee along to their
                        customer.
                    </li>
                    <li>3.11. The casino reserves the right to request a Security Verification Form at any time. If a
                        person does not return a faxback form and the required personal identification within 30 days of
                        such request, risks suspension and possible forfeiture of his/her account. In such a case, the
                        casino may unilaterally determine resolution of any account funds.
                    </li>
                    <li>3.12. When making a Bitcoin (BTC) deposit, the player is solely responsible for correctly
                        transferring the bitcoins to the address provided by the cashier. To ensure every Bitcoin
                        transfer is credited automatically, the player must initiate the Bitcoin deposit process in the
                        cashier for EVERY Bitcoin deposit to be made. We do not process or accept any other
                        cryptocurrency and cannot credit player accounts for funds sent through other cryptocurrencies;
                        for example, if a player transfers Bitcoin Cash (BCH), we cannot credit the player account for
                        that cryptocurrency.
                    </li>
                </ul>
            </>]

        },
        {
            title: "ACCOUNTS & PASSWORDS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} permits only one casino account per person, household, IP address, email
                        address
                        or computer at each of its casinos. Having a linked account at the casino may cause your account
                        to be closed and any winnings forfeited.
                    </li>
                    <li>4.2. Due to an overwhelming history of promotional abuse, players from certain countries are
                        forced to abide by separate wagering requirements for offered promotions. These can be found in
                        our “Promotions terms and conditions”.
                    </li>
                    <li>4.3. It is the responsibility of the player to keep confidential their account number and
                        password. It is understood that having any third party involved in a player’s online casino
                        account is a violation of this agreement. The player assumes full responsibility and agrees not
                        to hold {casino.name} liable for any losses or damages resulting from the player’s failure to
                        keep
                        his account number and password confidential.
                    </li>
                    <li>4.4. In providing their email address upon registration, the player agrees to receive occasional
                        email correspondence at that address. If the player is no longer interested in receiving these
                        emails, they may unsubscribe by emailing support.
                    </li>
                    <li>4.5. By providing their postal address, the player agrees to receive occasional hardcopy
                        material from the casino. If the player is no longer interested in receiving mail, they may
                        unsubscribe by emailing support.
                    </li>
                </ul>
            </>]

        },
        {
            title: "GENERAL TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. The Casino is committed to offering fair and honest games and confirms the software and all
                        games offered by {casino.name} Online Casino rely on an audited and validated Random Number
                        Generator (RNG) by a third party ensuring that all games are fair and honest.
                    </li>
                    <li>5.2. The Casino reserves the right to modify the balance on a user's account to redeem sums owed
                        to the Casino.
                    </li>
                    <li>5.3. The user agrees to pay all sums due to {casino.name} Online Casino and to never try to
                        re-attribute, deny, revoke, dispute, chargeback, block, hold or cancel any of these payments.
                    </li>
                    <li>5.4. If the user has deposited or withdrawn funds using an E-Wallet such as MoneyBookers,
                        Neteller or EcoCard, the Casino may request copies of other transactions showing that specific
                        E-Wallet in use.
                    </li>
                    <li>5.5. Any illegal or criminal activity made by the player will result in immediate termination of
                        the Casino account and the Casino reserves the right to inform the proper authorities and other
                        online casinos and merchants of these actions with full disclosure.
                    </li>
                    <li>5.6. The Casino reserves the right to change any of its offered services at any time, including
                        a temporary unavailability of customer support, technical support or any other services given to
                        the player.
                    </li>
                    <li>5.7. In the event of technical failure or computer glitch, all bets in play may be considered
                        null and void. The Casino cannot be held responsible for losses, wins or other issues generated
                        by a technical failure or glitch in any way or form.
                    </li>
                    <li>5.8. Should a player abstain from logging into their Casino account for a period of 90 days or
                        more or the player 90 days without making a single bet or wager, the Casino will be entitled to
                        any balance in that player's account and the sum forfeit. Should the player wish to withdraw
                        this amount after this period, we will examine each situation on a case by case basis but
                        reserve the right to invoke the above 3 month rule.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PROMOTIONS AND BONUS TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. All and any free bonuses and deposit bonuses cannot be withdrawn from the Casino, and are
                        meant to enhance game time only. Upon withdrawal, the bonus amounts will be deducted by the
                        Casino before the withdrawal is sent.
                    </li>
                    <li>6.2. All bonus money will be removed at the point of cash out, bonus money is money that can be
                        used to help generate winnings but cannot be withdrawn as part of the winnings. The bonus will
                        be treated as 'Non-Withdrawable' and will not be reissued to your account but deducted from the
                        winnings by the casinos withdrawal manager during the payout process. Cashback money is also
                        'Non-Withdrawable' and the casino reserves the right to deduct cashback money from a players'
                        pending withdrawal.
                    </li>
                    <li>6.3. Bonuses, unless specifically specified by the Casino beforehand, carry a wagering
                        requirement that must be completed before a player can withdraw.
                    </li>
                    <li>6.4. All cashback promotions carry a wagering requirement of 1X. Players cannot withdraw a
                        cashback bonus until the sum of the cashback is wagered in full. All cashback money is
                        restricted to a maximum withdrawal limit of 10X the cashback amount. This includes cashback
                        awarded as 'gifts', 'comps' or 'free chips'. The Casino reserves the right to deduct Cashback
                        money from a pending withdrawal
                    </li>
                    <li>6.5. All cashback money won from a Tournament prize, Raffle prize or Special Promotion have a
                        maximum withdrawal limit of 10X the cashback bonus and carry a wagering requirement of 1X. All
                        bonuses of 200% or more, cashback insurance of 75% or more will be considered Special
                        Promotions. The Casino reserves the right to deduct Cashback money from a pending withdrawal.
                        Players who have a pending withdrawal or balance of over 100 $/€/£/ do not qualify to receive
                        Tournament prizes or to receive cash from a Raffle or cashback from any ongoing Cashback
                        promotion or any other special promotion offered by the Promotions Team.
                    </li>
                    <li>6.5.1 Special Bonus - all bonuses of 200% or more, cashback bonuses of 100% or more will be
                        considered Special Bonuses
                    </li>
                    <li>6.6. It is the responsibility of the player to be aware of the details of the bonus they request
                        or is given to them, including the wagering requirement, restricted games and any other details,
                        terms and requirements associated with said bonus.
                    </li>
                    <li>6.7. Players choosing not to play with bonuses, must state so before starting to play with a
                        bonus. If a player does not want the bonus that has been deposited into their account by the
                        casino, then they are entitled to request a casino customer support representative reverse the
                        bonus out of their casino account, by way of email, telephone call or live Chat. If a bonus is
                        received and a wager is made (of any amount), the bonus will have to be played all the way
                        through, until the balance is zero or the wagering requirement is complete. If the player has
                        not begun to play, support will remove the bonus should they ask for it.
                    </li>
                    <li>6.8. Free bonuses (Comps, Free Chips, converted Comp Points or any other non-deposit bonuses)
                        are playable bonuses awarded to players so they may play free of charge. Free bonuses have a
                        different wagering requirement than other bonuses, and have a fixed withdrawal limit of no more
                        than 50 Credits (50 $,€,£,AUD, or 500 ZAR). Any remaining or extra balance is
                        'Non-Withdrawable' and is to be cleared from a player's balance. Each player is allowed to
                        withdraw winnings from a free bonus only once during his/her gaming lifetime unless specifically
                        approved by the casino manager.
                    </li>
                    <li>6.9. A player who has never made a deposit and wishes to withdraw a free chip win, is required
                        to first make one successful minimum deposit of 15.00 ($,€,£,AUD, or 150 ZAR). This first
                        deposit amount is not allowed to be withdrawn or added to an existing pending withdrawal. If
                        added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. A
                        player who has previously made a successful deposit and is now wishing to withdraw a free chip
                        win, is required to have deposited within the last 30 days in order for the withdrawal to be
                        approved and the deposit cannot be added to an existing pending withdrawal. If added to an
                        existing withdrawal, the amount will be deducted from the total withdrawal amount. Free Bonuses
                        (Free Chips) have a wagering requirement of 35X to 100X unless otherwise specified
                    </li>
                    <li>6.10. All promotions offered by the Casino are only valid for the period specified in the
                        promotional offer itself which is posted on the Casino website and/or any other communication
                        provided by the Casino itself.
                    </li>
                    <li>6.11. A bonus equal to or greater than 200% that has been claimed with any deposit, will be
                        subject to a max cashout of 10x the deposit amount.
                    </li>
                    <li>6.12. A free bonus player (a player who plays only free bonuses, and didn't make a deposit yet)
                        can withdraw winnings when playing his first three free bonuses on his account, during the first
                        30 days after account registration After three bonuses played or after 30 days since
                        registration date, the funds received from a free bonus will be considered not cashable and will
                        be deducted from the balance or from the withdrawal.
                    </li>
                    <li>6.13. In case a player has finished \ played down, his wager requirement ,
                        the casino holds the right to adjust balance to the maximum withdrawal amount
                        allowed and clear any extra balance. In cases where a player is not eligible to
                        make a withdrawal , the casino holds the right to clear any winnings and balance once wager is
                        met.
                    </li>

                </ul>
            </>]

        },
        {
            title: "WITHDRAWAL TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Please keep in mind that if you make a deposit of 249 USD/EURO/GBP/AUD or less, you can
                        only withdraw 10 times the amount of your deposit for that play session (all other currencies
                        are tied to USD). The casino will apply this rule to the withdrawal request and adjust the
                        withdrawal amount accordingly.
                        Any unused funds will be classified as "Non-Withdrawable" and subtracted from the player
                        account. Additionally, if a win is obtained when using a deposit bonus during a gaming session
                        and a withdrawal request is made, this win will also be regarded as a "Non-Withdrawable" amount
                        and will be subtracted from the particular pending withdrawal.
                    </li>
                    <li>7.2. Customers can submit their withdrawal request at anytime. The approval time may take
                        between one - fourteen (1 - 14) working days starting the date authorization process was
                        completed successfully. Processing a pending withdrawal requires authorization from the Fraud
                        department as well.
                    </li>
                    <li>7.3. Any and all bonuses redeemed by the player cannot be withdrawn or redeemed from the Casino,
                        and will be removed from the player's winnings upon processing of the withdrawal.
                    </li>
                    <li>7.4. All withdrawals are subject to a 10-40 $,€,£ transfer fee. Fees are based on location and
                        are determined by our payment processor and are the responsibility of the individual player to
                        absorb.
                    </li>
                    <li>7.5. For a withdrawal to be approved, the player will be asked to submit certain documentation
                        and information in order to verify the account. Failure to comply or failure to submit said
                        documents and information 30 days from the time the withdrawal was requested, will result in the
                        withdrawal being cancelled. It is the responsibility of the individual player to send the
                        required documentation. Withdrawal approval depends on all conditions outlined and the
                        verification of all required documents.
                    </li>
                    <li>7.6. A player may not withdraw winnings as long as there is still a wagering requirement
                        attached to their balance. They must complete the wagering requirements before making a
                        withdrawal request, or risk having the withdrawal denied.
                    </li>
                    <li>7.7. The casino has the right to refuse or withhold a bonus or winnings in the event of a
                        deposit being cancelled, returned or denied by any payment service or processor at any time.
                    </li>
                    <li>7.8. If the player has wagered on restricted games with a bonus that does not allow said games,
                        the Casino reserves the right to cancel any withdrawal and confiscate any winnings. Before
                        starting to play with a deposit bonus or free chip, it is the responsibility of the player to
                        check and understand what are the restrictions of that particular bonus or chip.
                    </li>
                    <li>7.9. The minimum amount for a withdrawal is 50 €/$/£/AUD or 500 ZAR.</li>
                    <li>7.10. Any and all deposits must be wagered at least once (1X) the sum of the deposit in order to
                        qualify for a withdrawal. A withdrawal cannot be approved unless a minimum wager of 1X the
                        deposit is first met. Deposits made as part of promotions are subject to that specific
                        promotion's Terms and Conditions and wagering requirement.
                    </li>
                    <li>7.11. By depositing funds into your account, you direct us and we agree to hold them, along with
                        any winnings, for the sole and specific purpose of using them (i) to place any gaming wagers;
                        and (ii) to settle any fees or charges that you might incur in connection with the use of our
                        services. We reserve the right to charge a fee of 3% - 6.5% for the processing of Credit Card
                        deposits.
                    </li>
                    <li>7.12. A player that didn't add funds to his account for one month-long or more (30 days), will
                        be considered a free bonus player and will not be eligible to withdraw winnings from free
                        bonuses or cash granted to him by the casino. The possibility of withdrawing winnings from free
                        bonuses is reserved for our active players only.
                    </li>
                    <li>7.13. Mixing a balance of a free-play with a balance of real-play is not allowed and is under
                        the player's responsibility. If a deposit was made on top of a balance from a free chip, any
                        playable balance the player had prior to depositing to play for real, will be considered free
                        play, therefore, under the rules of free play.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PRIZES & WINNINGS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. Players who have their account verified , are allowed to withdraw up to €/£/AU$/$1000
                        (R10,000) per week . Incase no documents were sent yet and the account is not verified yet,
                        players will be permitted to withdraw up to €/£/AU$/$500 (R5000) per week . Our highest VIP
                        players are permitted to withdraw a maximum of €/£/AU$/$10,000 (R100,000) of their winnings per
                        12 day period at management's discretion.
                    </li>
                    <li>8.2. It is our goal to pay withdrawals within 1 and 12 business days. This time frame can vary
                        due to processor availability but we will always do everything in our power to pay our players
                        as fast as possible so you can get your winnings quickly and easily. During times of high cash
                        out levels, the casino reserves the right to lower the weekly withdrawal limits to €/£/AU$/$500.
                        Your withdrawal enters the withdrawal queue on the first business day after your verification
                        documents have been accepted.
                    </li>
                    <li>8.3. The casino reserves the right to request documentation for the purpose of identity
                        verification at any time; prior to granting any deposits or withdrawals, or upon suspicion of
                        fraudulent activity by the player. Accounts under fraud investigation may have their accounts
                        suspended and winnings frozen indefinitely until the investigation period is over. In the case
                        of suspected fraud, winnings emerging from credit/debit card deposits can be frozen and held for
                        up to 12 months.
                    </li>
                    <li>8.4. The casino reserves the right to reverse any withdrawal over 30 days old, due to missing
                        security documents or incomplete verification of the player account.
                    </li>
                    <li>8.5. Players winning a sizeable amount deemed to be worthy of publicity by the casino agree to
                        make themselves available for any event arranged by {casino.name} in relation to that
                        win. {casino.name}
                        will at all times respect the player’s privacy in this event.
                    </li>
                    <li>8.6. The casino reserves the right to use your first name and first initial of your surname
                        (e.g. John S.) in any casino announcement regarding winning results.
                        Any winnings accrued from a technical malfunction of the software or casino, will be voided and
                        removed from the player's account. Any deposit(s) involved will be refunded to the player's
                        account and may be cashed out for the original deposit(s) amount.
                    </li>
                    <li>8.7. The casino reserves the right to deny winnings and close account(s) if you have been
                        designated as a bonus abuser, playing no or low-risk strategies in order to withdraw money; if
                        you have not played at the Casino for personal entertainment only (i.e. if you systematically
                        employ betting systems to beat the casino, for example: Martingale); or if your personal
                        information is found blacklisted in our casino-industry-wide security database.
                    </li>
                    <li>8.8. Please be aware that only residents in the United Kingdom may opt for the British Pound
                        (GBP) currency. Winnings that occur in British Pounds will only be paid in the same currency to
                        legal residents within the UK.
                    </li>
                    <li>8.9. Winnings will be voided if player account has undergone chargeback(s) or unauthorized
                        refund(s) or is in any way linked to other account(s) where chargeback(s) have been placed
                        within the inter-casino security network.
                    </li>
                    <li>8.10. If a player deposits with a credit card he/she is required to have a security form
                        documents in order to process a cash-out, and there may be a waiting period of up to 7 days
                        before withdrawal is processed.
                    </li>
                    <li>8.11. Please note that players in the US, Australia, and UK can currently withdraw winnings
                        through Bitcoin only.
                    </li>
                    <li>8.12. When requesting a Bitcoin withdrawal, the player must enter their BTC wallet address in
                        the cashier. We will only pay to the BTC wallet registered at the time of requesting the
                        withdrawal. The casino will not be held responsible if a player enters an incorrect Bitcoin
                        address when requesting a BTC withdrawal. If we complete a BTC payment to a BTC address that
                        does not belong to the player (due to an error by the player when requesting the withdrawal), we
                        will not re-issue the payment.
                    </li>
                </ul>
            </>]

        },
        {
            title: "REFUND POLICY",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Thanks for opening an account with us and depositing funds into your account at our
                        websites.
                    </li>
                    <li>We offer the option of a refund on all l deposits made on our websites. However, certain
                        conditions have to be met. We invite you to read the conditions explained in more detail below.
                    </li>
                    <li>Our returns policy is simple, straight forward, and we will be processed immediately.</li>
                    <li>However, there is a limited window of time as indicated:</li>
                    <ul>
                        <li>Within 24 hours of the original deposit.</li>
                        <li>As required at the time of opening the account, the compliance must be completed and
                            approved by our compliance department.
                        </li>
                        <li>Games malfunction - Screenshot required.</li>
                        <li>Unable to play credits - Balance unplayed.</li>
                        <li>Wrong charges - statement required.</li>
                        <li>Processing Fee</li>
                    </ul>
                    <li>There is a fee involved in processing and issuing your refund. The different fees (at your
                        currency) are as follows
                    </li>
                    <ul>
                        <li>Paper Check - 55.00</li>
                        <li>SEPA wire - 20.00</li>
                        <li>SWIFT wire - 75.00</li>
                    </ul>
                    <li>Late or missing refunds (if applicable):</li>
                    <ul>
                        <li>If you haven’t received your refund yet, first check your bank account again.</li>
                        <li>Then contact your credit card company, it may take some time before your refund is
                            officially posted.
                        </li>
                        <li>If you’ve done all this and you still have not received your refund yet, please contact us
                            at <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a>
                        </li>
                    </ul>
                    <li>Kindly note that for cryptocurrency deposits under $25, refunds cannot be facilitated as the transaction fees exceed the minimum refund threshold. In these instances, the deposited amount will be credited to the player's balance as credits, treated as a deposit, and subjected to the casino's terms and conditions applicable to all deposits.</li>
                </ul>
            </>]

        },
        {
            title: "PRIZES & WINNINGS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. Player's personal information that was used to register at the casino will be used for the
                        following purposes:
                    </li>
                    <ul>
                        <li>To determine the validity of player's identity and verifying activity under that name.</li>
                        <li>In providing information to a third party to collect any sums of money owed to the casino by
                            the player.
                        </li>
                        <li>When law requires, the reporting to the proper authorities for the detection and prevention
                            of crime.
                        </li>
                    </ul>

                </ul>
            </>]

        },
        {
            title: "MISUSE OF ACCOUNT",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. The casino reserves the right to suspend your account pending investigation in the event
                        of the casino having reasonable grounds for suspecting that you have been involved in any form
                        of collusion, syndication or fraud during your use of the casino platform, website and/or
                        systems. We also reserve the right to withhold the whole or part of your account balance, close
                        your account permanently, as well as recover from your account the amount of any winnings,
                        pay-outs or bonuses which have been affected by any of the following activities:
                    </li>
                    <li>11.2. Collusion: The joint effort of two or more individuals to gain an unfair advantage over
                        the software and other players via pooling of funds to achieve an unnatural increase of
                        available bankroll through multiple bonus awards (similarly to multi-accounting) to benefit/take
                        advantage from game engine design. This is usually done at a casino in order to increase the
                        total number of deposit bonuses awarded, thereby un-naturally maximizing probability of
                        achieving a large winning slot pay-out (for example: slot bonus round) while ensuring that the
                        pay-out of such an event is awarded to one of the colluding members, effectively detracting from
                        the winning potential of any other players using the casino.
                    </li>
                    <li>11.3. Syndication: We become aware that you are a member and/or form part of a syndicate, club
                        or group where players receive advantage play strategies in order to create positive expectancy
                        scenarios through the acquisition of promotional offers.
                    </li>
                    <li>11.4. Exploitation of a fault, loophole or error in our or any third party software provided for
                        the services of this agreement.
                    </li>
                    <li>11.5. We become aware that you have been found guilty of fraudulent or collusive behavior at
                        another online gaming business including charge-backs, collusion, or criminal activities.
                    </li>
                    <li>11.6. We become aware that you have charged back any or denied any of the transfers, deposits
                        and purchases to your account in a fraudulent manner.
                    </li>
                    <li>11.7. Make use of any automated play software such as "bots", or any type of simulation tools or
                        systems designed to provide an unfair advantage over the casino.
                    </li>
                    <li>11.8. As a player who makes use of this casino, you agree that you are not colluding or
                        attempting to collude, nor intending to participate directly or indirectly in a collusion scheme
                        which involves the sharing of winnings between selected players, sharing of funds, or working
                        together toward mutual end goal while benefitting from multiple acquisition incentive offers, to
                        the detriment of other players whom are not involved in the colluding party. You also agree that
                        you will inform the casino through one of the means necessary should you suspect a player to be
                        colluding, or planning to collude with other players. You also agree that you do not form part
                        of a syndicate or group that engages in advantage play strategies.
                    </li>
                    <li>11.9. We will thoroughly investigate any case in which we believe there may be any existence of
                        an element of fraudulent activity, collusion, syndication or illegal activity. We also reserve
                        the right to inform our business units, processing companies, electronic payment providers or
                        other financial institutions we use, of your identity and of any suspected unlawful, fraudulent
                        or improper activity.
                    </li>
                    <li>11.10. The following actions by the player will be considered a violation of the terms and
                        conditions of the Casino, but not limited to this list. Consequences may include termination of
                        the player's casino account, confiscation of winnings and existing balances, denial of services,
                        promotions and any other offers by the Casino.
                    </li>
                    <ul>
                        <li>More than one account at {casino.name} Online Casino and/or more than one account per
                            household and/or more than one account per device, and/or more than one account from the
                            same IP address, and/ or more than one account on an affiliated sister casino under the same
                            management as {casino.name} Online Casino.
                        </li>
                        <li>Should at any time, the casino discovers a linkage or connection to a fraudulent, suspended
                            or refunded or previously closed account on any brand within the Rival network or any other
                            online casino.
                        </li>
                        <li>Should at any time the casino discover an active or past complaint/dispute/criticism on a
                            public forum or blog or any other type of website.
                        </li>
                        <li>Should at any time the player abuse, harass or speak inappropriately to any of the Casino
                            staff, including verbal and written threats or intimidation.
                        </li>
                        <li>A mismatch on the Player's Casino account not matching the name on the credit card(s) or
                            payment method(s) used to make deposits on the account.
                        </li>
                        <li>Incorrect or false registration information.</li>
                        <li>The player is playing from a territory or region not fully authorized to allow online
                            gaming.
                        </li>
                        <li>The player is not of the required legal age according to the Casino Terms and Conditions.
                        </li>
                        <li>If the Player has allowed or permitted (intentionally or unintentionally) another player to
                            play on their own Casino account.
                        </li>
                        <li>If an individual has not played in the Casino purely as an entertainment and has only
                            participated in a professional capacity or in association with another player from another
                            club or group or part thereof.
                        </li>
                        <li>If the Player has been found deliberately making a 'chargeback' or 'dispute' of funds
                            deposited from their credit card, or any other payment method, into their Casino account; or
                            have been found to have made a 'chargeback' or threatened to do so in any other capacity on
                            a sister casino on the Rival platform or any other online casino or website. This includes
                            verbal and written threats to dispute, chargeback or stop transactions.
                        </li>
                        <li>If the Player is discovered to be cheating or has been found making irregular betting
                            patterns, or suspicious or irregular betting strategies and or the Player has instigated a
                            system (i.e. Martingale, machines, computers, software or any other form of automation) to
                            proliferate a cheat designed for the Casino to lose revenue due to this operation.
                        </li>
                        <li>The player is linked to any employee, ex-employee, company, third party or agency connected
                            to the Casino currently or in the past.
                        </li>
                        <li>The player has made any attempts to 'hack' the Casino software or website, or made any
                            changes to it in any way not specifically allowed by the Casino.
                        </li>
                    </ul>
                    <li>11.11 Players who choose to make a deposit using cryptocurrency must ensure that the token/coin
                        has successfully arrived and been confirmed on the blockchain to the designated crypto wallet
                        address provided during the deposit process. If a deposit is made to the wallet but subsequently
                        gets 'voided' or reversed, it will be regarded as fraudulent activity. Consequently, any
                        previous, ongoing, or future withdrawal requests made by the player will also be deemed void
                    </li>
                </ul>
            </>]

        },
        {
            title: "REFER-A-FRIEND TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. The general terms and conditions apply to the refer-a-friend program and all other
                        programs offered by {casino.name} Online Casino. By submitting all required information into the
                        refer-a-friend program, both you and the referred party (person being referred) consent to all
                        terms of this program and the referred party has consented to their data being supplied by you
                        to the referring party. By submitting this information, the referred party consents to having an
                        email sent to him/her by {casino.name} Online Casino requesting him/her to sign up for a real
                        player account. By submitting information into the refer-a-friend program, the referred party is
                        fully aware that the referring party has submitted their details in good faith.
                    </li>
                    <li>12.2. Persons utilizing the same computer (registering from the same IP address) will not be
                        permitted to partake in the refer-a-friend bonus for security purposes. Referred players must
                        not be existing members of {casino.name} Online Casino. The email address provided by the
                        referring party must not be associated with any current player of {casino.name} Online Casino
                        nor shall it be the same email as the referring member.
                    </li>
                    <li>12.3. The referring member will be required to fill out specific information about his/her
                        referred friend; this information includes, but is not limited to their given name, surname and
                        email address. After all required information has been provided, submitted and then verified
                        by {casino.name} Online Casino, then and only then shall the bonus be supplied to both the
                        referring and referred members.
                    </li>
                    <li>12.4. In order to qualify for the refer-a-friend bonus, the referred party must first sign up
                        for a real money account and deposit a minimum of 25 $,€,£ into that account. Both the referring
                        friend and the referred friend will receive their bonus only after the referred friend has
                        deposited 25 $,€,£ into their real player account and must allow for 48 hours before receiving
                        the bonus in their real player accounts.
                    </li>

                </ul>
            </>]

        },]
}
const untilWeDontHaveOtherFR = {
    title: "INTRODUCTION",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Jouer dans un casino, que ce soit pour vous amuser ou pour le jeu réel, constitue un accord entre
                le joueur et le casino en ligne, le joueur est lié par les termes et conditions suivants le contenu dans
                les termes et conditions. Le joueur accepte qu'il s'agit d'un accord entre le joueur et le casino et que
                c'est le seul accord régissant la relation du joueur avec le casino, à l'exclusion de tous les autres
                accords, déclarations et représentations.
            </li>

            <li>1.2. Chaque clause contenue dans ces termes et conditions sera séparée et séparable de chacune des
                autres. Si une clause est jugée nulle, invalide ou inapplicable pour quelque raison que ce soit, les
                autres termes et conditions resteront pleinement en vigueur.
            </li>

            <li>1.3. La Société se réserve le droit de décision finale sur l'interprétation des présentes Termes et
                Conditions. Les services de courrier électronique et de chat peuvent fournir des informations et des
                mises à jour sur notre service. Communication
                par email ou chat ne peut pas modifier ou révoquer les Conditions Générales. Échec sur le chat ou
                l'e-mail pour couvrir notre
                Conditions générales ou la mention d'une partie de nos conditions générales ne constitue pas un motif de
                violer les termes et conditions. En cas de litige, les Conditions Générales font foi
                et sera appliqué à toute information fournie par chat ou par e-mail. Tous les joueurs doivent lire
                les conditions générales avant l'inscription.
            </li>
            <li>1.4. Les conditions générales suivantes régissent l'utilisation du site Web et du casino en
                ligne {casino.name} :
            </li>
            <li>1.5. IMPORTANT : Tous les dépôts sont définitifs. Aucun remboursement ne pourra être effectué une fois
                les dépôts effectués sur nos jeux.
            </li>
        </ul>
    </>],

    sections: [{
        title: "CONTENU DU SITE WEB ET DU CASINO",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. Tout le logiciel et le contenu du site Web trouvé sur le site du Casino {casino.name} sont
                    protégés par le droit d'auteur du Casino (les "Matériaux sous licence"). Tous les Matériaux sous
                    licence qui sont présentés visuellement ou auditivement au joueur sont destinés à un usage personnel
                    uniquement et restent la propriété intellectuelle du Casino et de ses licenciés. Aucun joueur
                    n'acquiert de droit quel qu'il soit sur l'ensemble ou une partie quelconque des Matériaux sous
                    licence, à l'exception du droit d'utiliser les Matériaux sous licence conformément à cet accord.
                </li>
            </ul>
        </>]

    },
        {
            title: "OBLIGATION DU JOUEUR",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. En devenant membre (joueur) du casino, vous comprenez pleinement et acceptez de respecter
                        toutes les règles, termes et conditions énoncés par {casino.name} de temps à autre. Il incombe
                        au joueur de se tenir informé de ces règles, termes et conditions, car ils sont sujets à des
                        changements périodiques.
                    </li>
                    <li>3.2. Jouer sur Casino {casino.name} est limité aux adultes seulement. Les joueurs doivent avoir
                        25 ans ou plus pour jouer. La restriction d'âge peut changer selon le droit national local, et
                        il relève de la responsabilité du joueur de vérifier l'âge minimum autorisé dans son pays. Le
                        Casino se réserve le droit d'exiger la vérification de l'âge du joueur à tout moment.
                    </li>
                    <li>3.3. Les joueurs des pays suivants peuvent ne pas être autorisés à ouvrir un compte sur notre
                        casino :
                        Afghanistan, Arménie, Azerbaïdjan, Bangladesh, Biélorussie, Bhoutan, Bulgarie, îles Caïmans,
                        Costa
                        Rica, Danemark, Estonie, Hongrie, Kazakhstan, Kirghizistan, Pays-Bas, Antilles néerlandaises,
                        Pologne, Roumanie, Singapour, Syrie, Turkménistan et îles Vierges. Les comptes seront fermés et
                        tous les gains ou pertes du jeu sont annulés.
                    </li>
                    <li>3.4. En créant un compte et en devenant joueur au casino, que ce soit pour le "jeu en mode démo"
                        ou le "jeu en argent réel", vous garantissez l'authenticité et l'exactitude de toutes les
                        informations que vous soumettez. Si {casino.name} détermine que les informations fournies par le
                        joueur sont fausses, {casino.name} se réserve le droit de suspendre le compte du joueur ainsi
                        que tous les retraits associés. Le compte restera suspendu jusqu'à ce que {casino.name} ait
                        déterminé, à sa seule discrétion, que les informations du joueur étaient authentiques et
                        précises.
                    </li>
                    <li>3.5. {casino.name} se réserve le droit de refuser l'adhésion au casino à tout candidat ou de
                        suspendre les privilèges de jeu d'un membre existant sans motif et à la seule discrétion
                        de {casino.name}.
                    </li>
                    <li>3.6. En plaçant des paris au casino, le joueur réalise que cela relève de son engagement
                        volontaire et équivaut à du jeu. La participation comporte le risque de perdre de l'argent
                        et {casino.name} ne saurait en aucun cas être tenu responsable des pertes ou dommages subis par
                        le joueur en relation avec l'utilisation du casino.
                    </li>
                    <li>3.7. Tout dépôt effectué au casino doit être misé au moins une fois avant qu'un retrait puisse
                        être demandé. Si le dépôt n'est pas misé au moins une fois avant le retrait, le casino se
                        réserve le droit de facturer au joueur des frais de traitement.
                    </li>
                    <li>3.8. L'utilisation de dispositifs électroniques, mécaniques, robotiques ou autres pour prendre
                        automatiquement des décisions de jeu est résolument interdite. S'il est détecté
                        par {casino.name} qu'un joueur a violé cette clause, des mesures peuvent et seront prises,
                        notamment l'interdiction au joueur de participer à des promotions et/ou de jouer à l'avenir, la
                        confiscation des gains nets, et la communication de telles informations à une base de données de
                        joueurs « restreints » de l'industrie des casinos.
                    </li>
                    <li>3.9. En jouant à n'importe quel jeu au casino, le joueur garantit qu'il est familier avec les
                        règles d'un tel jeu et reconnaît que les règles de chaque jeu sont disponibles en utilisant la
                        fonction « aide » du casino.
                    </li>
                    <li>3.10. Lors du dépôt avec une carte de débit/crédit, des coûts supplémentaires de tiers peuvent
                        être appliqués par la société émettrice de la carte de crédit du joueur et/ou les associations
                        de cartes, et en tant que tels, ils échappent au contrôle de la société. Veuillez noter que des
                        frais supplémentaires de transaction étrangère peuvent vous être facturés par transaction en
                        fonction du montant déposé par les banques de traitement des cartes de crédit. En général, les
                        frais de transaction étrangère sont un pourcentage du montant de chaque achat en devise
                        étrangère, sans minimum ni maximum, atteignant parfois 6 à 8%. Il s'agit de frais facturés par
                        les processeurs de transactions tels que VISA et MC. La plupart des banques émettrices
                        choisissent de répercuter ces frais sur leurs clients.
                    </li>
                    <li>3.11. Le casino se réserve le droit de demander un formulaire de vérification de sécurité à tout
                        moment. Si une personne ne renvoie pas un formulaire de télécopie et l'identification
                        personnelle requise dans les 30 jours suivant une telle demande, elle risque la suspension et la
                        confiscation possible de son compte. Dans un tel cas, le casino peut déterminer unilatéralement
                        la résolution des fonds du compte.
                    </li>
                    <li>3.12. Lors d’un dépôt Bitcoin (BTC), le joueur est seul responsable du bon
                        transférer les bitcoins à l'adresse fournie par le caissier. Pour garantir que chaque transfert
                        Bitcoin soit crédité automatiquement, le joueur doit lancer le processus de dépôt Bitcoin à la
                        caisse pour que CHAQUE dépôt Bitcoin soit effectué. Nous ne traitons ni n’acceptons aucune autre
                        crypto-monnaie et ne pouvons pas créditer les comptes des joueurs pour les fonds envoyés via
                        d’autres crypto-monnaies ; par exemple, si un joueur transfère du Bitcoin Cash (BCH), nous ne
                        pouvons pas créditer le compte du joueur pour cette crypto-monnaie.
                    </li>
                </ul>
            </>]

        },
        {
            title: "COMPTES ET MOTS DE PASSE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} autorise uniquement un compte de casino par personne, foyer, adresse IP,
                        adresse e-mail ou ordinateur dans chacun de ses casinos. La présence d'un compte lié au casino
                        peut entraîner la fermeture de votre compte et la confiscation de tout gain.
                    </li>
                    <li>4.2. En raison d'une histoire écrasante d'abus promotionnels, les joueurs de certains pays sont
                        contraints de respecter des conditions de mise distinctes pour les promotions proposées. Vous
                        pouvez les trouver dans nos "Conditions des promotions".
                    </li>
                    <li>4.3. Il incombe au joueur de garder confidentiels son numéro de compte et son mot de passe. Il
                        est interdit à toute tierce partie de participer au compte de casino en ligne d'un joueur. Le
                        joueur assume l'entière responsabilité et accepte de ne pas tenir {casino.name} responsable des
                        pertes ou dommages résultant du défaut du joueur à garder confidentiels son numéro de compte et
                        son mot de passe.
                    </li>
                    <li>4.4. En fournissant leur adresse e-mail lors de l'inscription, le joueur accepte de recevoir
                        occasionnellement une correspondance par e-mail à cette adresse. Si le joueur n'est plus
                        intéressé par la réception de ces e-mails, il peut se désabonner en envoyant un e-mail au
                        support.
                    </li>
                    <li>4.5. En fournissant leur adresse postale, le joueur accepte de recevoir occasionnellement des
                        documents imprimés du casino. Si le joueur n'est plus intéressé par la réception du courrier, il
                        peut se désabonner en envoyant un e-mail au support.
                    </li>
                </ul>
            </>]

        },
        {
            title: "CONDITIONS ET TERMES GÉNÉRAUX",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. Le Casino s'engage à offrir des jeux équitables et honnêtes et confirme que le logiciel et
                        tous les jeux proposés par Casino {casino.name} s'appuye sur un générateur de nombre aléatoire
                        audité et validé (RNG) par un tiers veillant à ce que tous les jeux soient justes et honnêtes.
                    </li>
                    <li>5.2. Le Casino se réserve le droit de modifier et de réajuster la balance sur le compte d'un
                        utilisateur des sommes dues au Casino.
                    </li>
                    <li>5.5. L'utilisateur s'engage à payer toutes les sommes dues au Casino {casino.name} et à ne
                        jamais essayer de réattribuer, refuser, révoquer, contester, rétrofacturer, bloquer, maintenir
                        ou annuler aucun de ces paiements.
                    </li>
                    <li>5.4. Si l'utilisateur a déposé ou retiré des fonds au moyen d'un porte-monnaie électronique tels
                        que Moneybookers, Neteller ou EcoCard, le Casino peut demander des copies des autres
                        transactions montrant que le porte-feuilles en ligne spécifique est en cours d'utilisation.
                    </li>
                    <li>5.5. Toute activité illégale ou criminelle faite par le joueur entraînera la résiliation
                        immédiate du compte Casino et le Casino se réserve le droit d'informer les autorités compétentes
                        et d'autres casinos en ligne ainsi que les commerçants de ces actions avec la divulgation
                        complète.
                    </li>
                    <li>5.6. Le Casino se réserve le droit de modifier l'un de ses services offerts à tout moment, y
                        compris une indisponibilité temporaire du support clientèle, du support technique ou d'autres
                        services offerts au joueur.
                    </li>
                    <li>5.7. En cas de défaillance technique ou problème informatique, tous les paris en jeu peuvent
                        être considérés comme nuls et non avenus. Le Casino ne peut être tenu responsable des pertes,
                        victoires ou autres problèmes générés par une défaillance technique ou d'une panne de quelque
                        manière que ce soit.
                    </li>
                    <li>5.8. Si un joueur s'abstient de se connecter à son compte Casino sur une période de 90 jours ou
                        plus ou si le joueur se connecte sans faire un seul pari ou mise sur une période de 90 jours ou
                        plus, le Casino aura droit à tout solde sur le compte dudit joueur et la somme forfaitaire. Si
                        le joueur souhaite retirer cette somme après cette période, le Casino examinera chaque situation
                        au cas par cas, mais se réserve le droit d'invoquer la règle des 3 mois ci-dessus.
                    </li>
                </ul>
            </>]

        },
        {
            title: "CONDITIONS DES PROMOTIONS & BONUS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. Aucun bonus gratuit et bonus sur dépôt ne peut être retiré du Casino, et sont destinés à
                        améliorer le temps de jeu seulement. En cas de retrait, les montants de bonus seront déduits par
                        le Casino avant l'effectuation et l'envoi du retrait.
                    </li>
                    <li>6.2. Tout l'argent des bonus devra être retiré au moment de l'encaissement, l'argent des bonus
                        est de l'argent qui peut être utilisé afin d' aider à générer des gains, mais ne peut pas être
                        retiré dans le cadre des gains. Le bonus sera considéré comme «non-retirable» et ne sera pas
                        réédité à votre compte, mais déduit des gains par le gestionnaire des retraits au cours du
                        processus de paiement. L'argent des Cashback est également «non retirable» et le casino se
                        réserve le droit de déduire les cashback du/es retrait/s en attente du joueur.
                    </li>
                    <li>6.3. Les bonus, sauf spécifiquement indiqué par le Casino au préalable, sont contraints par une
                        exigence de mises devant être remplie avant qu'un joueur ne puisse retirer.
                    </li>
                    <li>6.4. Toutes les promotions cashback sont contraintes par une exigence de mise d'1 fois. Les
                        joueurs ne peuvent pas retirer un bonus cashback jusqu'à ce que la somme du cashback soit misée
                        en entier. Les cashback ont une limite de retrait maximum de 10 fois la quantité du montant de
                        cashback crédité. Cela comprend les cashback récompensés comme «cadeaux», les «points comps» ou
                        les «jetons gratuits». Le Casino se réserve le droit de déduire le Cashback d'un retrait en
                        attente.
                    </li>
                    <li>6.5. Tout l'argent cashback gagné grâce à un prix de tournoi, un prix de tombola ou une
                        promotion spéciale a une limite de retrait maximale de 10X le bonus de cashback et comporte une
                        condition de mise de 1X. Tous les bonus de 200 % ou plus, l'assurance cashback de 75 % ou plus
                        seront considérés comme des promotions spéciales. Le Casino se réserve le droit de déduire
                        l'argent du Cashback d'un retrait en attente. Les joueurs qui ont un retrait en attente ou un
                        solde supérieur à 100 $/€/£/ ne sont pas qualifiés pour recevoir des prix de tournoi ou pour
                        recevoir de l'argent d'une tombola ou du cashback de toute promotion Cashback en cours ou de
                        toute autre promotion spéciale proposée par l'équipe de promotion.
                    </li>
                    <li>6.5.1 Bonus spécial – tout bonus de 200% ou plus, tout bonus cashback de 100% ou plus sera
                        considéré comme bonus spécial et sera limité à un maximum de retrait de 3 fois le montant du
                        dépôt.
                    </li>
                    <li>6.6. Il est de la responsabilité du joueur de connaître les détails du bonus qu'il demande ou
                        qui lui est donné, y compris l'exigence de mises, les jeux restreintes et d'autres détails,
                        modalités et exigences associées avec ledit bonus.
                    </li>
                    <li>6.7. Les joueurs choisissant de jouer sans bonus, devront l'indiquer avant de commencer à jouer
                        avec un bonus. Si un joueur ne veut pas du bonus qui a été déposé sur son compte par le casino,
                        sera alors en droit de demander a un représentant du support clientèle du casino de supprimer le
                        bonus de son compte de casino, via courriel, appel téléphonique ou chat en direct. Si un bonus
                        est reçu et qu'un pari est fait (de tout montant), le bonus devra alors être joué dans son
                        intégralité, jusqu'à ce que le solde soit nul ou que l'exigence de pari soit complète. Si le
                        joueur n'a pas commencé à jouer, le support pourra alors supprimer le dit bonus.
                    </li>
                    <li>6.8. Les bonus gratuits (Points comps, jetons gratuits) sont des bonus jouables attribués a un
                        joueur afin qu'il puisse jouer gratuitement. Les bonus gratuits ont une exigence de mises
                        différentes des autres bonus, et ont une limite de retrait fixe de pas plus de 50 Crédits (50 $,
                        €, £, AUD ou 5 00 ZAR). Tout solde restant ou extra est considéré comme «non retirable »et sera
                        effacé de la balance du joueur. Chaque joueur est autorisé à retirer les gains d'un bonus
                        gratuit qu'une seule fois au cours de son / sa vie de jeu sauf si spécifiquement approuvé par le
                        directeur du casino.
                    </li>
                    <li>6.9. Un joueur qui n'a jamais fait un dépôt et souhaite retirer un gain issue d'un bonus
                        gratuit, doit tout d'abord faire un dépôt minimum de 15,00 ($, €, £, AUD) ou de 150 ZAR. Ce
                        premier dépôt n'est pas autorisé à être retiré ou ajouté à un existant retrait en attente. S'il
                        est ajouté à un prélèvement existant, le montant sera déduit du montant de retrait total. Un
                        joueur ayant déjà effectué un dépôt et maintenant désireux de retirer Un gain issu d'un bonus
                        gratuit, doit au préalable avoir déposé dans les 30 derniers jours pour que le retrait soit
                        approuvé et le dépôt ne peut pas être ajouté à un existant retrait en attente. S'il est ajouté à
                        un retrait en attente, le montant sera déduit du montant de retrait total. Les bonus gratuits
                        (jetons gratuits) ont une exigence de mise de 20X à 60X, sauf indication contraire.
                    </li>
                    <li>6.10. Toutes les promotions proposées par le Casino ne sont valables que pour la période
                        spécifiée dans l'offre promotionnelle elle-même qui est publiée sur le site Web du Casino et/ou
                        dans toute autre communication fournie par le Casino lui-même.
                    </li>
                    <li>6.11. Un bonus égal ou supérieur à 200 % réclamé avec tout dépôt sera soumis à un retrait
                        maximum de 10 fois le montant du dépôt.
                    </li>
                    <li>6.12. Un joueur de bonus gratuit (un joueur qui joue uniquement des bonus gratuits et n'a pas
                        encore effectué de dépôt) peut retirer ses gains en jouant ses trois premiers bonus gratuits sur
                        son compte, pendant les 30 premiers jours après la date d'enregistrement du compte. Après trois
                        bonus joués ou après 30 jours depuis la date d'inscription, les fonds reçus d'un bonus gratuit
                        seront considérés comme non encaissables et seront déduits du solde ou du retrait.
                    </li>
                    <li>6.13. Dans le cas où un joueur a terminé ses exigences de mise, le casino se réserve le droit
                        d'ajuster le solde au montant du retrait maximum autorisé et d'effacer tout solde
                        supplémentaire. Dans les cas où un joueur n'est pas éligible pour effectuer un retrait, le
                        casino se réserve le droit d'effacer tous les gains et le solde une fois le pari atteint.
                    </li>

                </ul>
            </>]

        },
        {
            title: "CONDITIONS GÉNÉRALES DE RETRAIT",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Tous les dépôts de 249 USD / EURO / GBP / AUS ou moins ont un retrait maximum de 10X le
                        dépôt de cette session spécifique de jeu. Une fois un retrait approuvé, le casino applique la
                        règle du 10X et corrige le retrait pour refléter fidèlement la règle du 10X. Les fonds restants
                        sont considérés comme «non-retirables» et sont effacés de la balance du joueur. Les bonus sur
                        dépôt utilisés au cours d'une session de jeu et se traduisant par une demande de retrait, sont
                        également «non retirables» et pourront être déduits de ce retrait en attente.
                    </li>
                    <li>7.2. Les joueurs peuvent soumettre leur demande de retrait à tout moment. Le délai d'approbation
                        peut prendre de un à quatorze (1-14) jours ouvrables à compter de la date d'autorisation
                        correspondant au moment où le processus a été achevé avec succès. Le traitement d'un retrait en
                        attente nécessite une autorisation du département des fraudes.
                    </li>
                    <li>7.3. Aucun bonus activé par le joueur ne peut être retiré du Casino ou échangé, et sera donc
                        annulé des gains du joueur lors du traitement du retrait.
                    </li>
                    <li>7.4. Tous les retraits (Wire, Neteller, Direct-to-Card, etc.) sont soumis à des frais de
                        transfert allant de 10 a 40 $, €, £. Les frais sont basés en fonction de l'emplacement et sont
                        déterminés par notre processeur de paiement et a la charge du joueur.
                    </li>
                    <li>7.5. Afin qu'un retrait soit approuvé, le joueur aura à soumettre certains documents et
                        informations afin de vérifier son compte. Le non-respect ou omission de fournir lesdits
                        documents et les informations requises sous 30 jours après que retrait ait été demandé, se
                        traduira par l'annulation du retrait en cours. Il est de la responsabilité du joueur d'envoyer
                        la documentation requise. L'approbation du retrait dépend ainsi de toutes les conditions
                        décrites et la vérification de tous les documents requis.
                    </li>
                    <li>7.6. Un joueur ne peut pas retirer les gains tant qu'il est encore soumis à une exigence de
                        mises attaché à sa balance. Il doit remplir les conditions de mises avant de pouvoir effectuer
                        une demande de retrait, au risque de voir la demande de retrait refusée.
                    </li>
                    <li>7.7. Le casino se réserve le droit de refuser ou d'annuler un bonus ou des gains dans le cas où
                        un dépôt est annulé, retourné ou refusé par un service de paiement ou un processeur à tout
                        moment.
                    </li>
                    <li>7.8. Si le joueur a misé sur des jeux restreints avec un bonus n'autorisant un de ces jeux, le
                        Casino se réserve le droit d'annuler tout retrait et de confisquer tous les gains. Avant de
                        commencer à jouer avec un bonus sur dépôt ou un bonus gratuit, il est de la responsabilité du
                        joueur de vérifier et de comprendre quelles sont les restrictions de ce bonus.
                    </li>
                    <li>7.9. Sauf indication contraire, et même s'il n'y a pas de limites supérieures aux gains
                        encaissables, le casino opère une limite de retrait maximum de 10 000 crédits par mois et 1 500
                        crédits par retrait. Pour les retraits au-dessus du seuil mensuel, les paiements se font par
                        tranches jusqu'à ce que le montant total soit versé. Ce seuil ne s'applique pas à un jackpot
                        progressif. Les joueurs sont autorisés à retirer jusqu'à 1500 crédits (équivalant à 1500 USD)
                        par demande. Si le montant que le joueur souhaite retirer est supérieur à 1500, alors les fonds
                        restants sont laissés sur le compte. Une fois le premier retrait approuvé, un joueur pourra
                        effectuer une nouvelle demande de retrait avec l'argent resté sur son compte. Un joueur a le
                        droit d'annuler la demande de retrait à tout moment tant qu'elle est encore en cours. Cette
                        règle s'applique également aux retraits de jackpots progressifs.",
                    </li>
                    <li>7.10. Le montant minimum pour un retrait est de 50 €/$/£/AUD ou 500 ZAR.
                    </li>
                    <li>7.11. Tous les dépôts doivent être misés au moins une fois (1X) le montant du dépôt afin de se
                        qualifier pour un retrait. Un retrait ne peut être approuvé que si le dépôt a été misé au moins
                        1 fois au préalable. Les dépôts effectués dans le cadre de promotions sont soumis aux Termes et
                        Conditions de cette promotion spécifique et à l'exigence de mise y correspondant.
                    </li>
                    <li>7.12. Points de fidélité (comp points): ont une limite de retrait de 10 fois le montant converti
                        avec une limite maximale de 300 € / $ / £ / AUD. Les fonds restants sont considérés comme
                        «non-retirables» et sont effacés de la balance du joueur.
                    </li>
                    <li>7.13. Prix de tournoi / loterie / jeu: ont une limite de retrait de 2 fois maximum du montant
                        reçu. Les fonds restants sont considérés comme «non-retirables» et seront supprimés de la
                        balance du joueur.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PRIX & GAINS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. Les joueurs dont le compte a été vérifié sont autorisés à retirer jusqu'à 1 000 €/£/AU$/$
                        par semaine. Si aucun document n'a été envoyé et que le compte n'est pas encore vérifié, les
                        joueurs seront autorisés à retirer jusqu'à 500 €/£/AU$/$ par semaine. Nos joueurs VIP les plus
                        élevés sont autorisés à retirer un maximum de 10 000 €/£/AU$/$ de leurs gains par période de 12
                        jours, à la discrétion de la direction.
                    </li>
                    <li>8.2. Notre objectif est de payer les retraits dans un délai de 1 à 12 jours ouvrables. Ce délai
                        peut varier en raison de la disponibilité du processeur, mais nous ferons toujours tout en notre
                        pouvoir pour payer nos joueurs aussi rapidement que possible afin que vous puissiez recevoir vos
                        gains rapidement et facilement. En période de forts niveaux de retrait, le casino se réserve le
                        droit de réduire les limites de retrait hebdomadaires à 500 €/£/AU$/$. Votre retrait entre dans
                        la file d'attente de retrait le premier jour ouvrable suivant l'acceptation de vos documents de
                        vérification.
                    </li>
                    <li>8.3. Le casino se réserve le droit de demander des documents aux fins de vérification d'identité
                        à tout moment; avant d'accorder tout dépôt ou retrait, ou en cas de suspicion d'activité
                        frauduleuse de la part du joueur. Les comptes faisant l'objet d'une enquête pour fraude peuvent
                        avoir leurs comptes suspendus et leurs gains gelés indéfiniment jusqu'à la fin de la période
                        d'enquête. En cas de fraude présumée, les gains issus de dépôts par carte de crédit/débit
                        peuvent être gelés et conservés pendant jusqu'à 12 mois.
                    </li>
                    <li>8.4. Le casino se réserve le droit d'annuler tout retrait de plus de 30 jours en raison de
                        documents de sécurité manquants ou de la vérification incomplète du compte du joueur.
                    </li>
                    <li>8.5. Les joueurs remportant un montant considérable jugé digne de publicité par le casino
                        acceptent de se rendre disponibles pour tout événement organisé par {casino.name} concernant
                        cette victoire. {casino.name} respectera toujours la vie privée du joueur dans cet événement.
                    </li>
                    <li>8.6. Le casino se réserve le droit d'utiliser votre prénom et la première lettre de votre nom de
                        famille (par exemple, John S.) dans toute annonce de casino concernant les résultats gagnants.
                        Tout gain résultant d'un dysfonctionnement technique du logiciel ou du casino sera annulé et
                        retiré du compte du joueur. Tout dépôt(s) impliqué sera remboursé sur le compte du joueur et
                        pourra être retiré pour le montant du dépôt(s) original.
                    </li>
                    <li>8.7. Le casino se réserve le droit de refuser les gains et de fermer le(s) compte(s) si vous
                        avez été désigné comme abusant des bonus, en utilisant des stratégies sans risque ou à faible
                        risque pour retirer de l'argent ; si vous n'avez pas joué au casino uniquement pour le plaisir
                        personnel (c'est-à-dire si vous utilisez systématiquement des systèmes de paris pour battre le
                        casino, par exemple : Martingale) ; ou si vos informations personnelles sont trouvées sur liste
                        noire dans notre base de données de sécurité de l'industrie du casino.
                    </li>
                    <li>8.8. Veuillez noter que seuls les résidents du Royaume-Uni peuvent opter pour la livre
                        britannique (GBP) comme devise. Les gains en livres sterling seront payés uniquement dans la
                        même devise aux résidents légaux du Royaume-Uni.
                    </li>
                    <li>8.9. Les gains seront annulés si le compte du joueur a fait l'objet de rétrofacturation(s) ou
                        de remboursement(s) non autorisé(s) ou s'il est lié de quelque manière que ce soit à d'autres
                        comptes où des rétrofacturations ont été effectuées dans le réseau de sécurité intercasino.
                    </li>
                    <li>8.10. Si un joueur dépose avec une carte de crédit, il doit avoir un formulaire de sécurité
                        pour traiter un retrait, et il peut y avoir une période d'attente pouvant aller jusqu'à 7 jours
                        avant que le retrait ne soit traité.
                    </li>
                    <li>8.11. Veuillez noter que les joueurs aux États-Unis, en Australie et au Royaume-Uni peuvent
                        actuellement retirer leurs gains uniquement via Bitcoin.
                    </li>
                    <li>8.12. Lorsqu'il demande un retrait Bitcoin, le joueur doit saisir son
                        adresse de portefeuille
                        BTC dans la caisse. Nous ne paierons qu'au portefeuille BTC enregistré au moment de la
                        demande de retrait. Le casino ne sera pas tenu responsable si un joueur saisit une adresse
                        Bitcoin incorrecte lors de la demande de retrait. Si nous effectuons un paiement BTC à une
                        adresse BTC qui n'appartient pas au joueur (en raison d'une erreur du joueur lors de la demande
                        de retrait), nous ne réémettrons pas le paiement.
                    </li>
                </ul>
            </>]

        },
        {
            title: "POLITIQUE DE REMBOURSEMENT",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Merci d'avoir ouvert un compte chez nous et d'y avoir fait un dépôt sur nos sites Web.</li>
                    <li>Nous offrons la possibilité d'un remboursement sur tous les dépôts effectués sur nos sites Web.
                        Cependant, certaines conditions doivent être remplies. Nous vous invitons à lire les conditions
                        expliquées plus en détail ci-dessous, afin de voir la condition qui s'applique à votre
                        situation, tout en suivant la bonne procédure.
                    </li>
                    <li>Notre politique de retour est simple, et sera traité immédiatement.</li>
                    <li>Cependant, il y a un délai limité comme indiqué:</li>
                    <ul>
                        <li>Dans les 24 heures suivant le dépôt initial.</li>
                        <li>Au besoin au moment de l'ouverture du compte, la conformité requise au moment de l'ouverture
                            du
                            compte doit être complétée et approuvée par notre service de conformité.
                        </li>
                        <li>Dysfonctionnement des jeux - Capture d'écran requise.</li>
                        <li>Impossible de jouer les crédits - Solde non joué.</li>
                        <li>Frais bancaires ou prélèvement injustifié - relevé bancaire requis.</li>
                        <li>Frais de traitement</li>
                    </ul>
                    <li>Il y a des frais impliqués dans le traitement et l'émission de votre
                        remboursement. Les différents frais (dans votre devise) sont les suivantes:
                    </li>
                    <ul>
                        <li>Chèque papier - 55,00</li>
                        <li>Fil SEPA - 20,00</li>
                        <li>Fil SWIFT - 75,00</li>
                    </ul>
                    <li>Remboursements tardifs ou manquants (le cas échéant):</li>
                    <ul>
                        <li>Si vous n'avez pas encore reçu votre remboursement, vérifiez à nouveau votre compte
                            bancaire.
                        </li>
                        <li>Ensuite, contactez votre société de carte de crédit, cela peut prendre un certain temps
                            avant
                            que votre remboursement ne soit officiellement affiché.
                        </li>
                        <li>Si vous avez fait tout cela et que vous n'avez toujours pas reçu votre remboursement,
                            veuillez
                            nous contacter à <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a>.
                        </li>
                    </ul>
                </ul>
            </>]

        },
        {
            title: "POLITIQUE DE CONFIDENTIALITÉ",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. Les informations personnelles du joueur utilisées pour s'inscrire au casino seront
                        utilisées à des fins suivantes :
                    </li>
                    <ul>
                        <li>Pour déterminer la validité de l'identité du joueur et vérifier l'activité sous ce nom.</li>
                        <li>En fournissant des informations à un tiers pour collecter toute somme d'argent due au casino
                            par le joueur.
                        </li>
                        <li>Lorsque la loi l'exige, pour signaler aux autorités compétentes en matière de détection et
                            de prévention du crime.
                        </li>
                    </ul>

                </ul>
            </>]

        },
        {
            title: "ABUS DE COMPTE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. Le casino se réserve le droit de suspendre votre compte pendant l'enquête dans le cas où
                        le casino aurait des motifs raisonnables de soupçonner que vous avez été impliqué dans une forme
                        de collusion, de syndication ou de fraude lors de votre utilisation de la plateforme du casino,
                        du site Web et/ou des systèmes. Nous nous réservons également le droit de retenir la totalité ou
                        une partie de votre solde de compte, de fermer définitivement votre compte, ainsi que de
                        récupérer de votre compte le montant de tous gains, paiements ou bonus qui ont été affectés par
                        l'une des activités suivantes :
                    </li>
                    <li>11.2. Collusion : L'effort conjoint de deux individus ou plus pour obtenir un avantage déloyal
                        sur le logiciel et les autres joueurs en mettant en commun des fonds pour obtenir une
                        augmentation artificielle du fonds de roulement disponible grâce à de multiples attributions de
                        bonus (similaire au multi-comptage) pour bénéficier/tirer avantage de la conception du moteur de
                        jeu. Cela se fait généralement dans un casino afin d'augmenter le nombre total de bonus de dépôt
                        attribués, maximisant ainsi de manière non naturelle la probabilité d'obtenir un gros gain lors
                        d'un tour de bonus de machine à sous, par exemple, tout en veillant à ce que le paiement d'un
                        tel événement soit attribué à l'un des membres en collusion, détournant efficacement le
                        potentiel de gain de tout autre joueur utilisant le casino.
                    </li>
                    <li>11.3. Syndication : Nous constatons que vous êtes membre et/ou faites partie d'un syndicat, club
                        ou groupe où les joueurs reçoivent des stratégies de jeu avantageuses afin de créer des
                        scénarios d'espérance positive grâce à l'acquisition d'offres promotionnelles.
                    </li>
                    <li>11.4. Exploitation d'une faille, d'une lacune ou d'une erreur dans notre logiciel ou tout autre
                        logiciel tiers fourni pour les services de cet accord.
                    </li>
                    <li>11.5. Nous constatons que vous avez été reconnu coupable d'un comportement frauduleux ou
                        collusif auprès d'une autre entreprise de jeux en ligne, y compris des rétrofacturations, une
                        collusion ou des activités criminelles.
                    </li>
                    <li>11.6. Nous constatons que vous avez effectué une rétrofacturation ou nié l'une des transferts,
                        dépôts et achats sur votre compte de manière frauduleuse.
                    </li>
                    <li>11.7. Utilisation de tout logiciel de jeu automatisé tel que des "bots", ou tout type d'outils
                        de simulation ou de systèmes conçus pour fournir un avantage déloyal sur le casino.
                    </li>
                    <li>11.8. En tant que joueur utilisant ce casino, vous convenez que vous ne colludez pas ou
                        n'essayez pas de colluder, n'avez pas l'intention de participer directement ou indirectement à
                        un complot de collusion qui implique le partage des gains entre des joueurs sélectionnés, le
                        partage de fonds, ou le travail ensemble vers un objectif final mutuel tout en bénéficiant de
                        multiples offres d'acquisition, au détriment d'autres joueurs qui ne sont pas impliqués dans la
                        collusion. Vous convenez également que vous informerez le casino par l'un des moyens nécessaires
                        si vous soupçonnez un joueur de collusion ou de planification de collusion avec d'autres
                        joueurs. Vous convenez également de ne pas faire partie d'un syndicat ou d'un groupe qui
                        s'engage dans des stratégies de jeu avantageuses.
                    </li>
                    <li>11.9. Nous enquêterons attentivement sur tout cas où nous croyons qu'il pourrait y avoir une
                        existence de fraude, de collusion, de syndication ou d'activité illégale. Nous nous réservons
                        également le droit d'informer nos unités commerciales, les sociétés de traitement, les
                        fournisseurs de paiement électronique ou d'autres institutions financières que nous utilisons,
                        de votre identité et de toute activité suspectée illégale, frauduleuse ou impropre.
                    </li>
                    <li>11.10. Les actions suivantes par le joueur seront considérées comme une violation des termes et
                        conditions du casino, mais sans s'y limiter. Les conséquences peuvent inclure la résiliation du
                        compte de casino du joueur, la confiscation des gains et des soldes existants, le refus de
                        services, de promotions et de toutes autres offres par le casino.
                    </li>
                    <ul>
                        <li>Plus d'un compte sur {casino.name} Online Casino et/ou plus d'un compte par foyer et/ou plus
                            d'un compte par appareil, et/ou plus d'un compte avec la même adresse IP, et/ou plus d'un
                            compte sur un casino affilié sous la même gestion que {casino.name} Online Casino.
                        </li>
                        <li>Si le casino découvre à tout moment une liaison ou une connexion à un compte frauduleux,
                            suspendu, remboursé ou précédemment fermé sur une autre marque du réseau Rival ou tout autre
                            casino en ligne.
                        </li>
                        <li>Si le casino découvre à tout moment une plainte/différend/critique active ou passée sur un
                            forum public, un blog ou tout autre type de site Web.
                        </li>
                        <li>Si le joueur abuse, harcèle ou s'exprime de manière inappropriée envers le personnel du
                            casino, y compris des menaces verbales et écrites ou des intimidations.
                        </li>
                        <li>Une incohérence sur le compte Casino du joueur ne correspondant pas au nom sur la ou les
                            cartes de crédit ou les méthodes de paiement utilisées pour effectuer des dépôts sur le
                            compte.
                        </li>
                        <li>Informations d'inscription incorrectes ou fausses.</li>
                        <li>Le joueur joue depuis un territoire ou une région qui n'est pas entièrement autorisé à
                            permettre les jeux en ligne.
                        </li>
                        <li>Le joueur n'a pas l'âge légal requis selon les termes et conditions du casino.</li>
                        <li>Si le joueur a autorisé ou permis (intentionnellement ou involontairement) à un autre joueur
                            de jouer sur son propre compte de casino.
                        </li>
                        <li>Si une personne n'a pas joué au casino uniquement à des fins de divertissement et a
                            uniquement participé à des activités professionnelles ou en association avec un autre joueur
                            d'un autre club ou groupe ou en partie.
                        </li>
                        <li>Si le joueur a été découvert à faire délibérément une 'rétrofacturation' ou 'cont

                            estation' de fonds déposés de sa carte de crédit ou de toute autre méthode de paiement, dans
                            son compte de casino; ou a été découvert à avoir effectué une 'rétrofacturation' ou a menacé
                            de le faire dans tout autre casino en ligne ou site Web.
                        </li>
                        <li>Si le joueur est découvert à tricher ou a été trouvé à adopter des schémas de paris
                            irréguliers, ou des stratégies de paris suspectes ou irrégulières, ou si le joueur a
                            instigué un système (par exemple, Martingale, machines, ordinateurs, logiciels ou toute
                            autre forme d'automatisation) pour proliférer une triche conçue pour que le Casino perde des
                            revenus en raison de cette opération.
                        </li>
                        <li>Le joueur est lié à tout employé, ex-employé, entreprise, tiers ou agence liés au Casino
                            actuellement ou dans le passé.
                        </li>
                        <li>Le joueur a fait des tentatives de 'piratage' du logiciel ou du site Web du Casino, ou y a
                            apporté des modifications de quelque manière que ce soit non spécifiquement autorisée par le
                            Casino.
                        </li>
                    </ul>
                    <li>11.11 Les joueurs qui choisissent de faire un dépôt en utilisant une crypto-monnaie doivent
                        s'assurer que le jeton/la pièce est arrivé(e) avec succès et a été confirmé(e) sur la blockchain
                        à l'adresse du portefeuille crypto désignée fournie lors du processus de dépôt. Si un dépôt est
                        effectué dans le portefeuille mais est ultérieurement 'annulé' ou inversé, il sera considéré
                        comme une activité frauduleuse. Par conséquent, toutes les demandes de retrait précédentes, en
                        cours ou futures effectuées par le joueur seront également considérées comme nulles
                    </li>
                </ul>
            </>]

        },
        {
            title: "CONDITIONS GÉNÉRALES DE PARRAINER UN AMI",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. Les termes et conditions généraux sont appliqués au programme 'Parrainer un ami' et tous
                        les autres programmes offerts par Casino {casino.name}. En soumettant toutes les informations
                        requises dans le programme 'Parrainer un ami', vous et la partie parrainée (personne étant
                        parrainée) consentent à tous les termes de ce programme et la partie parrainée consent a ce que
                        ses données soient transmises par son 'parrain'. En soumettant ces informations, la partie
                        parrainée consent à recevoir un courriel/mail envoyé à lui / elle par Casino {casino.name} lui
                        demandant d'ouvrir un compte réel. En soumettant des informations dans le programme 'Parrainer
                        un ami', la partie parrainée est pleinement consciente que son 'parrain' a effectivement soumis
                        ses données en toute bonne foi.
                    </li>
                    <li>12.2. Les personnes utilisant le même ordinateur (enregistrement à partir de la même adresse IP)
                        ne seront pas autorisées à prendre part au bonus de parrainage pour des raisons de sécurité. Les
                        joueurs parrainés ne doivent pas être détenteur d'un compte sur Casino {casino.name} Casino.
                        L'adresse e-mail fournie par la partie parrainant ne doit être associée à aucun joueur actuel
                        sur Casino {casino.name} et ne doit pas être le même email que le membre parrainant.
                    </li>
                    <li>12.3. Les 'parrains' seront tenus de remplir des informations spécifiques à propos de leur
                        filleul/e, cette information inclut mais ne se limite pas à leur nom, prénom et adresse e-mail
                        donnée. Une fois que toutes les informations requises auront été fournies, soumises et vérifiées
                        par Casino {casino.name}, alors seulement le bonus pourra être fourni à la fois au parrain et
                        filleul.
                    </li>
                    <li>12.4. Afin de bénéficier du bonus de parrainage, le filleul doit d'abord ouvrir un compte en
                        argent réel et effectuer un dépôt minimum de 25 $, €, £ sur ce compte. Le parrain et le filleul
                        recevront leur bonus uniquement après que le filleul ait déposé 25 $, €, £ sur son compte joueur
                        puis après un délai de 48 heures, chaque partie recevra le bonus sur son compte respectif.
                    </li>
                    <li> Merci de noter que la version française des conditions générales n'a aucune valeur juridique et
                        est une simple traduction de la version anglaise officielle et originale des termes et
                        conditions du casino produite par courtoisie pour nos joueurs francophones.
                    </li>
                </ul>
            </>]

        },]
}
const untilWeDontHaveOtherDE = {
    title: "EINFÜHRUNG",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Das Spielen im Casino, sei es im "Fun-Modus" oder im "Echtgeld-Modus", stellt die Zustimmung des
                Mitglieds (Spielers) zu den folgenden hier enthaltenen Geschäftsbedingungen dar. Der Spieler akzeptiert,
                dass dies eine verbindliche Vereinbarung zwischen dem Spieler und dem Casino ist und die alleinige
                Vereinbarung ist, die die Beziehung des Spielers zum Casino regelt, unter Ausschluss aller anderen
                Vereinbarungen, Erklärungen und Darstellungen.
            </li>
            <li>1.2. Jede Klausel in diesen Geschäftsbedingungen ist separat und von den anderen trennbar. Sollte eine
                Klausel aus irgendeinem Grund für nichtig, ungültig oder undurchsetzbar befunden werden, bleiben die
                verbleibenden Geschäftsbedingungen in vollem Umfang gültig.
            </li>
            <li>1.3. Das Unternehmen behält sich das letzte Entscheidungsrecht über die Auslegung dieser
                Geschäftsbedingungen vor. E-Mail- und Chat-Dienste können Informationen und Updates über unseren Service
                bereitstellen. Die Kommunikation per E-Mail oder Chat kann die Geschäftsbedingungen nicht ändern oder
                aufheben. Das Versäumnis im Chat oder per E-Mail, unsere Geschäftsbedingungen zu behandeln oder einen
                Teil unserer Geschäftsbedingungen zu erwähnen, begründet keine Verletzung der Geschäftsbedingungen. Im
                Streitfall haben die Geschäftsbedingungen abschließende Autorität und werden über alle im Chat oder per
                E-Mail bereitgestellten Informationen durchgesetzt. Alle Spieler sind verpflichtet, die
                Geschäftsbedingungen vor der Anmeldung zu lesen.
            </li>
            <li>1.4. Die folgenden Geschäftsbedingungen regeln die Nutzung der Website und des Online-Casinos
                von {casino.name}:
            </li>
            <li>1.5. WICHTIG: Alle Einzahlungen sind endgültig. Eine Rückerstattung kann nicht erfolgen, sobald die
                Einzahlungen in unseren Spielen gespielt wurden.
            </li>
        </ul>
    </>],

    sections: [{
        title: "WEBSITE- UND CASINO-INHALTE",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. Alle auf der {casino.name} Casino-Website gefundenen Software- und Website-Inhalte unterliegen
                    dem Urheberrecht des Casinos (die „lizenzierten Materialien“). Sämtliches lizenzierte Material, das
                    dem Spieler visuell oder akustisch präsentiert wird, ist nur für den persönlichen Gebrauch bestimmt
                    und bleibt geistiges Eigentum des Casinos und seiner Lizenznehmer. Kein Spieler erwirbt irgendwelche
                    Rechte an allen oder Teilen der lizenzierten Materialien, mit Ausnahme des Rechts, die lizenzierten
                    Materialien gemäß dieser Vereinbarung zu nutzen.
                </li>
            </ul>
        </>]

    },
        {
            title: "VERPFLICHTUNG DES SPIELERS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. Durch die Mitgliedschaft im Casino erklären Sie sich vollständig damit einverstanden, alle
                        Regeln, Bedingungen und Bestimmungen, die von {casino.name} von Zeit zu Zeit festgelegt werden,
                        zu befolgen. Es liegt in der Verantwortung des Spielers, sich regelmäßig über diese Regeln,
                        Bedingungen und Bestimmungen auf dem Laufenden zu halten, da sie periodischen Änderungen
                        unterliegen.
                    </li>
                    <li>3.2. Das Spielen im {casino.name} Online Casino ist nur für Erwachsene gestattet. Spieler müssen
                        25 Jahre oder älter sein, um zu spielen. Altersbeschränkungen können sich gemäß den örtlichen
                        nationalen Gesetzen ändern, und es liegt in der Verantwortung des Spielers, das Mindestalter in
                        ihrem Land zu überprüfen. Das Casino behält sich das Recht vor, vom Spieler jederzeit eine
                        Altersverifikation zu verlangen.
                    </li>
                    <li>3.3. Spielern aus den folgenden Ländern ist möglicherweise nicht gestattet, ein Konto in unserem
                        Casino zu eröffnen: Afghanistan, Armenien, Aserbaidschan, Bangladesch, Belarus, Bhutan,
                        Bulgarien, Kaimaninseln, Costa Rica, Dänemark, Estland, Ungarn, Kasachstan, Kirgisistan,
                        Niederlande, Niederländische Antillen, Polen, Rumänien, Singapur, Syrien, Turkmenistan und
                        Virgin Islands. Konten werden geschlossen und alle Spielgewinne oder -verluste werden
                        annulliert.
                    </li>
                    <li>3.4. Bei der Erstellung eines Kontos und beim Spielen im Casino, egal ob zum "Spaß spielen" oder
                        zum "Echt spielen", garantieren Sie die Authentizität und Genauigkeit aller von Ihnen
                        angegebenen Informationen. Stellt {casino.name} fest, dass die vom Spieler gegebenen
                        Informationen falsch waren, behält sich {casino.name} das Recht vor, das Konto des Spielers
                        zusammen mit allen damit verbundenen Einnahmen auszusetzen. Das Konto bleibt so lange gesperrt,
                        bis {casino.name} nach eigenem Ermessen entschieden hat, dass die Informationen des Spielers
                        authentisch und korrekt waren.
                    </li>
                    <li>3.5. {casino.name} behält sich das Recht vor, die Mitgliedschaft im Casino für jeden Bewerber
                        abzulehnen oder die Spielprivilegien eines bestehenden Mitglieds ohne Angabe von Gründen und
                        nach alleinigem Ermessen von {casino.name} auszusetzen.
                    </li>
                    <li>3.6. Beim Platzieren von Wetten im Casino ist sich der Spieler bewusst, dass dies seine
                        freiwillige Beteiligung ist und dem Glücksspiel gleichkommt. Die Teilnahme birgt das Risiko,
                        Geld zu verlieren, und {casino.name} haftet unter keinen Umständen für Verluste oder Schäden,
                        die dem Spieler in Verbindung mit der Nutzung des Casinos entstehen.
                    </li>
                    <li>3.7. Jede Einzahlung ins Casino muss einmal eingesetzt werden, bevor eine Auszahlung beantragt
                        werden kann. Wenn die Einzahlung nicht mindestens einmal vor der Abhebung eingesetzt wird,
                        behält sich das Casino das Recht vor, dem Spieler eventuelle Bearbeitungsgebühren in Rechnung zu
                        stellen.
                    </li>
                    <li>3.8. Die Verwendung von elektronischen, mechanischen, robotergesteuerten oder anderen Geräten
                        zur automatischen Spielentscheidung ist strikt verboten. Stellt {casino.name} fest, dass ein
                        Spieler gegen diese Bedingung verstoßen hat, können und werden Maßnahmen ergriffen,
                        einschließlich des Ausschlusses des Spielers von zukünftigen Werbeaktionen und/oder Spielen, der
                        Beschlagnahme von Netto-Gewinnen und der Weitergabe solcher Informationen an eine Datenbank der
                        Casino-Branche von "gesperrten Spielern".
                    </li>
                    <li>3.9. Durch das Spielen eines Spiels im Casino garantiert der Spieler, dass er mit den Regeln
                        eines solchen Spiels vertraut ist und anerkennt, dass die Regeln jedes Spiels durch die Nutzung
                        der "Hilfe"-Einrichtung im Casino verfügbar sind.
                    </li>
                    <li>3.10. Bei Einzahlungen mit Debit-/Kreditkarte können zusätzliche Kosten von der
                        Kreditkartengesellschaft des Spielers und/oder den Kartenverbänden anfallen, die außerhalb der
                        Kontrolle des Unternehmens liegen. Bitte beachten Sie, dass Ihnen je nach Einzahlungsbetrag
                        durch die Kreditkartenverarbeitungsbanken eine zusätzliche Fremdwährungsgebühr pro Transaktion
                        berechnet werden kann. Normalerweise handelt es sich bei Fremdwährungsgebühren um einen
                        Prozentsatz des Betrags jeder Fremdwährungstransaktion, ohne Mindest- oder Höchstbetrag, der
                        manchmal sogar 6-8% erreichen kann. Dies sind Gebühren, die von den Transaktionsverarbeitern wie
                        VISA und MC erhoben werden. Die meisten herausgebenden Banken wählen, diese Gebühr an ihre
                        Kunden weiterzugeben.
                    </li>
                    <li>3.11. Das Casino behält sich das Recht vor, jederzeit ein Sicherheitsverifizierungsformular
                        anzufordern. Wenn eine Person ein Faxback-Formular und die erforderlichen persönlichen
                        Identifikationsunterlagen nicht innerhalb von 30 Tagen nach einer solchen Anforderung
                        zurücksendet, besteht die Gefahr der Sperrung und möglichen Einziehung ihres Kontos. In einem
                        solchen Fall kann das Casino einseitig über die Lösung von Kontofonds entscheiden.
                    </li>
                    <li>3.12. Bei einer Bitcoin (BTC)-Einzahlung ist der Spieler allein dafür verantwortlich, die
                        Bitcoins korrekt an die vom Kassierer bereitgestellte Adresse zu übertragen. Um sicherzustellen,
                        dass jede Bitcoin-Überweisung automatisch gutgeschrieben wird, muss der Spieler den
                        Bitcoin-Einzahlungsvorgang im Kassierer für JEDE zu tätigende Bitcoin-Einzahlung starten. Wir
                        verarbeiten oder akzeptieren keine anderen Kryptowährungen und können Spielerkonten nicht für
                        Gelder gutschreiben, die durch andere Kryptowährungen gesendet wurden. Wenn ein Spieler
                        beispielsweise Bitcoin Cash (BCH) überweist, können wir das Spielerkonto nicht für diese
                        Kryptowährung gutschreiben.
                    </li>
                </ul>
            </>]

        },
        {
            title: "KONTEN & PASSWÖRTER",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} erlaubt nur ein Casino-Konto pro Person, Haushalt, IP-Adresse, E-Mail-Adresse
                        oder Computer in jedem seiner Casinos. Das Vorhandensein eines verknüpften Kontos im Casino kann
                        dazu führen, dass Ihr Konto geschlossen wird und etwaige Gewinne verfallen.
                    </li>
                    <li>4.2. Aufgrund einer überwältigenden Historie von Missbrauch von Werbeaktionen müssen Spieler aus
                        bestimmten Ländern separate Wettanforderungen für angebotene Aktionen einhalten. Diese finden
                        Sie in unseren "Geschäftsbedingungen für Promotionen".
                    </li>
                    <li>4.3. Es liegt in der Verantwortung des Spielers, seine Kontonummer und sein Passwort vertraulich
                        zu behandeln. Es ist bekannt, dass die Beteiligung Dritter an einem Online-Casino-Konto eines
                        Spielers eine Verletzung dieser Vereinbarung darstellt. Der Spieler übernimmt die volle
                        Verantwortung und erklärt sich damit einverstanden, {casino.name} nicht für Verluste oder
                        Schäden haftbar zu machen, die aus dem Versäumnis des Spielers resultieren, seine Kontonummer
                        und sein Passwort vertraulich zu behandeln.
                    </li>
                    <li>4.4. Indem sie ihre E-Mail-Adresse bei der Registrierung angeben, erklärt sich der Spieler damit
                        einverstanden, gelegentlich E-Mail-Korrespondenz an diese Adresse zu erhalten. Wenn der Spieler
                        kein Interesse mehr an diesen E-Mails hat, kann er sich durch eine E-Mail an den Support
                        abmelden.
                    </li>
                    <li>4.5. Durch Angabe ihrer Postanschrift erklärt sich der Spieler damit einverstanden, gelegentlich
                        Postmaterial vom Casino zu erhalten. Wenn der Spieler kein Interesse mehr an der Erhaltung von
                        Post hat, kann er sich durch eine E-Mail an den Support abmelden.
                    </li>
                </ul>
            </>]

        },
        {
            title: "ALLGEMEINE GESCHÄFTSBEDINGUNGEN",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. Das Casino ist verpflichtet, faire und ehrliche Spiele anzubieten und bestätigt, dass die
                        Software und alle von {casino.name} Online Casino angebotenen Spiele auf einem durch eine dritte
                        Partei geprüften und validierten Zufallszahlengenerator (RNG) basieren, der sicherstellt, dass
                        alle Spiele fair und ehrlich sind.
                    </li>
                    <li>5.2. Das Casino behält sich das Recht vor, den Kontostand eines Benutzers zu ändern, um Schulden
                        gegenüber dem Casino einzulösen.
                    </li>
                    <li>5.3. Der Nutzer erklärt sich damit einverstanden, alle an {casino.name} Online Casino fälligen
                        Beträge zu zahlen und niemals zu versuchen, diese Zahlungen neu zuzuweisen, abzulehnen,
                        zurückzuziehen, anzufechten, zurückzubuchen, zu blockieren, zurückzuhalten oder zu stornieren.
                    </li>
                    <li>5.4. Hat der Nutzer Gelder mit Hilfe eines E-Wallets wie MoneyBookers, Neteller oder EcoCard
                        eingezahlt oder abgehoben, kann das Casino Kopien anderer Transaktionen verlangen, die zeigen,
                        dass dieses spezielle E-Wallet verwendet wurde.
                    </li>
                    <li>5.5. Jede illegale oder kriminelle Handlung des Spielers führt zur sofortigen Beendigung des
                        Casino-Kontos und das Casino behält sich das Recht vor, die zuständigen Behörden sowie andere
                        Online-Casinos und Händler über diese Handlungen mit vollständiger Offenlegung zu informieren.
                    </li>
                    <li>5.6. Das Casino behält sich das Recht vor, jederzeit Änderungen an seinen angebotenen
                        Dienstleistungen vorzunehmen, einschließlich einer vorübergehenden Nichtverfügbarkeit des
                        Kundensupports, technischen Supports oder anderer dem Spieler angebotener Dienstleistungen.
                    </li>
                    <li>5.7. Im Falle eines technischen Ausfalls oder Computerproblems können alle im Spiel befindlichen
                        Wetten für nichtig erklärt werden. Das Casino kann nicht für Verluste, Gewinne oder andere durch
                        einen technischen Ausfall oder Fehler auf irgendeine Weise verursachte Probleme verantwortlich
                        gemacht werden.
                    </li>
                    <li>5.8. Sollte ein Spieler es unterlassen, sich über einen Zeitraum von 90 Tagen oder mehr in sein
                        Casino-Konto einzuloggen, oder der Spieler macht 90 Tage lang keine einzige Wette, hat das
                        Casino Anspruch auf jegliches Guthaben auf dem Konto des Spielers und die Summe verfällt. Möchte
                        der Spieler diesen Betrag nach diesem Zeitraum abheben, prüfen wir jede Situation einzeln,
                        behalten uns jedoch das Recht vor, die oben genannte Frist von 3 Monaten geltend zu machen.
                    </li>
                </ul>
            </>]

        },
        {
            title: "GESCHÄFTSBEDINGUNGEN FÜR AKTIONEN UND BONUS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. Alle und jegliche kostenlosen Boni und Einzahlungsboni können nicht vom Casino abgehoben
                        werden, sondern dienen nur dazu, die Spielzeit zu verlängern. Bei der Auszahlung werden die
                        Bonusbeträge vom Casino abgezogen, bevor die Auszahlung gesendet wird.
                    </li>
                    <li>6.2. Alles Bonusgeld wird zum Zeitpunkt der Auszahlung entfernt, Bonusgeld ist Geld, das zur
                        Generierung von Gewinnen genutzt werden kann, aber nicht als Teil der Gewinne abgehoben werden
                        kann. Der Bonus wird als 'nicht auszahlbar' behandelt und wird nicht auf Ihr Konto
                        zurückgebucht, sondern von den Gewinnen durch den Auszahlungsmanager des Casinos während des
                        Auszahlungsprozesses abgezogen. Cashback-Geld ist ebenfalls 'nicht auszahlbar' und das Casino
                        behält sich das Recht vor, Cashback-Geld von einer ausstehenden Auszahlung eines Spielers
                        abzuziehen.
                    </li>
                    <li>6.3. Boni haben, sofern vom Casino nicht im Voraus speziell angegeben, eine Wettanforderung, die
                        erfüllt werden muss, bevor ein Spieler auszahlen kann.
                    </li>
                    <li>6.4. Alle Cashback-Aktionen haben eine Wettanforderung von 1X. Spieler können einen
                        Cashback-Bonus nicht abheben, bis die Summe des Cashbacks vollständig gewettet wurde. Für alles
                        Cashback-Geld gilt ein maximaler Auszahlungslimit von 10X des Cashback-Betrags. Dies beinhaltet
                        Cashback, das als 'Geschenke', 'Gratis' oder 'Freie Chips' vergeben wurde. Das Casino behält
                        sich das Recht vor, Cashback-Geld von einer ausstehenden Auszahlung abzuziehen.
                    </li>
                    <li>6.5. Alle Cashback-Gewinne aus einem Turnierpreis, Raffle-Preis oder einer Spezialaktion haben
                        ein maximales Auszahlungslimit von 10X des Cashback-Bonus und müssen 1X gesetzt werden. Alle
                        Boni von 200% oder mehr, Cashback-Versicherung von 75% oder mehr werden als spezielle Aktionen
                        betrachtet. Das Casino behält sich das Recht vor, Cashback-Geld von einer ausstehenden
                        Auszahlung abzuziehen. Spieler, die eine ausstehende Auszahlung oder einen Saldo von über 100
                        $/€/£ haben, qualifizieren sich nicht für den Erhalt von Turnierpreisen oder für den Erhalt von
                        Geld aus einer Verlosung oder Cashback aus einer laufenden Cashback-Aktion oder einer anderen
                        Sonderaktion, die vom Promotionsteam angeboten wird.
                    </li>
                    <li>6.5.1 Spezialbonus - alle Boni von 200% oder mehr, Cashback-Boni von 100% oder mehr werden als
                        Spezialboni betrachtet.
                    </li>
                    <li>6.6. Es liegt in der Verantwortung des Spielers, sich über die Details des Bonus, den sie
                        anfordern oder der ihnen gegeben wird, einschließlich der Wettanforderung, eingeschränkter
                        Spiele und aller anderen Details, Bedingungen und Anforderungen, die mit dem genannten Bonus
                        verbunden sind, im Klaren zu sein.
                    </li>
                    <li>6.7. Spieler, die sich dafür entscheiden, nicht mit Boni zu spielen, müssen dies angeben, bevor
                        sie anfangen, mit einem Bonus zu spielen. Wenn ein Spieler den Bonus nicht möchte, der ihm vom
                        Casino auf sein Konto eingezahlt wurde, dann hat er das Recht, einen Kundenbetreuer des Casinos
                        zu bitten, den Bonus aus seinem Casino-Konto rückgängig zu machen, per E-Mail, Telefonanruf oder
                        Live-Chat. Wenn ein Bonus erhalten wird und eine Wette gemacht wird (von irgendeinem Betrag),
                        muss der Bonus ganz durchgespielt werden, bis das Guthaben Null ist oder die Wettanforderung
                        erfüllt ist. Wenn der Spieler noch nicht begonnen hat zu spielen, wird der Support den Bonus
                        entfernen, wenn sie danach fragen.
                    </li>
                    <li>6.8. Kostenlose Boni (Comps, kostenlose Chips, umgewandelte Comp-Punkte oder andere Boni ohne
                        Einzahlung) sind Boni, die den Spielern gewährt werden, damit sie kostenlos spielen können.
                        Kostenlose Boni haben andere Umsatzanforderungen als andere Boni und haben eine festgelegte
                        Auszahlungsgrenze von maximal 50 Credits (50 $, €, £, AUD oder 500 ZAR). Jeglicher verbleibender
                        oder zusätzlicher Betrag ist 'Nicht auszahlbar' und muss aus dem Spielerkonto ausgeglichen
                        werden. Jeder Spieler darf Gewinne aus einem kostenlosen Bonus nur einmal während seiner
                        gesamten Spielzeit abheben, es sei denn, es wurde vom Casinomanager ausdrücklich genehmigt.
                    </li>
                    <li>6.9. Ein Spieler, der noch nie eine Einzahlung getätigt hat und einen Gewinn aus einem
                        kostenlosen Chip abheben möchte, muss zuerst eine erfolgreiche Mindesteinzahlung von 15,00 ($,
                        €, £, AUD oder 150 ZAR) tätigen. Dieser Einzahlungsbetrag darf nicht abgehoben oder einer
                        bestehenden ausstehenden Abhebung hinzugefügt werden. Wenn er einer bestehenden Abhebung
                        hinzugefügt wird, wird der Betrag von der Gesamtabhebungssumme abgezogen. Ein Spieler, der zuvor
                        eine erfolgreiche Einzahlung getätigt hat und nun einen Gewinn aus einem kostenlosen Chip
                        abheben möchte, muss innerhalb der letzten 30 Tage eine Einzahlung getätigt haben, damit die
                        Abhebung genehmigt wird. Die Einzahlung kann nicht einer bestehenden ausstehenden Abhebung
                        hinzugefügt werden. Wenn sie einer bestehenden Abhebung hinzugefügt wird, wird der Betrag von
                        der Gesamtabhebungssumme abgezogen. Kostenlose Boni (kostenlose Chips) haben eine
                        Umsatzanforderung von 35X bis 100X, sofern nicht anders angegeben.
                    </li>
                    <li>6.10. Alle vom Casino angebotenen Promotionen sind nur für den im Angebot selbst angegebenen
                        Zeitraum gültig, der auf der Casino-Website und/oder in jeder anderen vom Casino
                        bereitgestellten Kommunikation veröffentlicht wird.
                    </li>
                    <li>6.11. Ein Bonus in Höhe von 200% oder mehr, der mit einer Einzahlung beansprucht wurde,
                        unterliegt einer maximalen Auszahlung von 10x dem Einzahlungsbetrag.
                    </li>
                    <li>6.12. Ein Spieler, der nur kostenlose Boni spielt (ein Spieler, der nur kostenlose Boni spielt
                        und noch keine Einzahlung getätigt hat), kann Gewinne abheben, wenn er seine ersten drei
                        kostenlosen Boni auf seinem Konto spielt, innerhalb der ersten 30 Tage nach der Kontoeröffnung.
                        Nach drei gespielten Boni oder nach 30 Tagen seit dem Anmeldedatum werden die aus einem
                        kostenlosen Bonus erhaltenen Mittel als nicht auszahlbar betrachtet und werden vom Guthaben oder
                        von der Abhebung abgezogen.
                    </li>
                    <li>6.13. Falls ein Spieler seine Umsatzanforderung erfüllt hat, behält sich das Casino das Recht
                        vor, das Guthaben auf den maximal zulässigen Auszahlungsbetrag anzupassen und jeglichen
                        zusätzlichen Betrag auszugleichen. In Fällen, in denen ein Spieler nicht berechtigt ist, eine
                        Abhebung vorzunehmen, behält sich das Casino das Recht vor, jegliche Gewinne und das Guthaben zu
                        löschen, sobald die Umsatzanforderung erfüllt ist.
                    </li>
                </ul>
            </>]

        },
        {
            title: "WIDERRUFSBELEHRUNG",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Bitte beachten Sie, dass Sie, wenn Sie eine Einzahlung von 249 USD/EURO/GBP/AUD oder
                        weniger tätigen, nur das Zehnfache des Einzahlungsbetrags für diese Spielsitzung abheben können
                        (alle anderen Währungen sind an USD gebunden). Das Casino wird diese Regel auf den
                        Auszahlungsantrag anwenden und den Auszahlungsbetrag entsprechend anpassen. Alle nicht
                        verwendeten Gelder werden als "Nicht auszahlbar" klassifiziert und vom Spielerkonto abgezogen.
                        Wenn während einer Spielsitzung ein Gewinn erzielt wird, wenn ein Einzahlungsbonus verwendet
                        wird und ein Auszahlungsantrag gestellt wird, wird auch dieser Gewinn als "Nicht auszahlbar"
                        betrachtet und vom entsprechenden ausstehenden Auszahlungsbetrag abgezogen.
                    </li>

                    <li>7.2. Kunden können ihren Auszahlungsantrag jederzeit einreichen. Die Genehmigungszeit kann
                        zwischen einem und vierzehn (1-14) Werktagen ab dem Datum der erfolgreichen Abschluss des
                        Genehmigungsprozesses dauern. Die Bearbeitung einer ausstehenden Auszahlung erfordert ebenfalls
                        die Genehmigung durch die Betrugsabteilung.
                    </li>

                    <li>7.3. Alle vom Spieler eingelösten Boni können nicht aus dem Casino abgehoben oder eingelöst
                        werden und werden bei der Bearbeitung der Auszahlung von den Gewinnen des Spielers abgezogen.
                    </li>

                    <li>7.4. Alle Auszahlungen unterliegen einer Transfergebühr von 10-40 $, €, £. Die Gebühren basieren
                        auf dem Standort und werden von unserem Zahlungsabwickler festgelegt. Sie sind die Verantwortung
                        des einzelnen Spielers.
                    </li>

                    <li>7.5. Damit eine Auszahlung genehmigt wird, wird der Spieler aufgefordert, bestimmte Unterlagen
                        und Informationen einzureichen, um das Konto zu überprüfen. Der Auszahlungsantrag wird
                        storniert, wenn der Spieler nicht innerhalb von 30 Tagen ab dem Zeitpunkt der Anforderung die
                        erforderlichen Unterlagen und Informationen einreicht. Es liegt in der Verantwortung des
                        einzelnen Spielers, die erforderlichen Unterlagen zu senden. Die Genehmigung der Auszahlung
                        hängt von allen festgelegten Bedingungen und der Überprüfung aller erforderlichen Dokumente ab.
                    </li>

                    <li>7.6. Ein Spieler darf keine Gewinne abheben, solange noch eine Wettanforderung an sein Guthaben
                        gekoppelt ist. Sie müssen die Wettanforderungen erfüllen, bevor sie eine Auszahlungsanforderung
                        stellen, oder riskieren, dass die Auszahlung abgelehnt wird.
                    </li>
                    <li>7.7. Das Casino hat das Recht, einen Bonus oder Gewinne zu verweigern oder zurückzuhalten, wenn
                        eine Einzahlung zu irgendeinem Zeitpunkt von einem Zahlungsdienst oder -prozessor storniert,
                        zurückgegeben oder abgelehnt wird.
                    </li>
                    <li>7.8. Wenn der Spieler mit einem Bonus auf eingeschränkte Spiele gewettet hat, die das genannte
                        Spiel nicht zulassen, behält sich das Casino das Recht vor, jede Auszahlung zu stornieren und
                        alle Gewinne zu konfiszieren. Bevor man mit einem Einzahlungsbonus oder freien Chip spielt, ist
                        es die Verantwortung des Spielers, zu prüfen und zu verstehen, welche Einschränkungen für diesen
                        speziellen Bonus oder Chip gelten.
                    </li>
                    <li>7.9. Der Mindestbetrag für eine Auszahlung beträgt 50 €/$/£/AUD oder 500 ZAR.</li>
                    <li>7.10. Alle Einzahlungen müssen mindestens einmal (1X) die Summe der Einzahlung gesetzt worden
                        sein, um für eine Auszahlung in Frage zu kommen. Eine Auszahlung kann nicht genehmigt werden, es
                        sei denn, es wurde zuerst ein Mindesteinsatz von 1X der Einzahlung getätigt. Im Rahmen von
                        Aktionen getätigte Einzahlungen unterliegen den spezifischen Geschäftsbedingungen und
                        Wettanforderungen dieser Aktion.
                    </li>
                    <li>7.11. Durch die Einzahlung von Geldern auf Ihr Konto beauftragen Sie uns und wir stimmen zu,
                        diese zusammen mit eventuellen Gewinnen, für den alleinigen und spezifischen Zweck zu halten,
                        sie (i) zum Platzieren von Glücksspielwetten und (ii) zur Begleichung von Gebühren oder Kosten,
                        die Ihnen im Zusammenhang mit der Nutzung unserer Dienste entstehen könnten, zu verwenden. Wir
                        behalten uns das Recht vor, eine Gebühr von 3% - 6.5% für die Bearbeitung von
                        Kreditkarteneinzahlungen zu erheben.
                    </li>
                    <li>7.12. Ein Spieler, der seinem Konto einen Monat oder länger (30 Tage) lang keine Geldmittel
                        hinzugefügt hat, wird als ein Freispiel-Spieler betrachtet und ist nicht berechtigt, Gewinne aus
                        Freispielen oder vom Casino gewährten Geld abzuheben. Die Möglichkeit, Gewinne aus Freispielen
                        abzuheben, ist nur unseren aktiven Spielern vorbehalten.
                    </li>
                    <li>7.13. Die Vermischung eines Guthabens aus einem Freispiel mit einem Guthaben aus einem Echtspiel
                        ist nicht erlaubt und liegt in der Verantwortung des Spielers. Wenn eine Einzahlung aufgrund
                        eines Guthabens aus einem freien Chip getätigt wurde, wird jedes spielbare Guthaben, das der
                        Spieler vor der Einzahlung zum Spielen hatte, als Freispiel betrachtet und unterliegt daher den
                        Regeln des Freispiels.
                    </li>

                </ul>
            </>]

        },
        {
            title: "PREISE & GEWINNE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. Spieler, deren Konto überprüft wurde, dürfen bis zu €/£/AU$/$1000 (R10,000) pro Woche
                        abheben. Falls noch keine Dokumente gesendet wurden und das Konto noch nicht überprüft ist,
                        dürfen Spieler bis zu €/£/AU$/$500 (R5000) pro Woche abheben. Unsere höchsten VIP-Spieler dürfen
                        nach Ermessen der Geschäftsleitung maximal €/£/AU$/$10,000 (R100,000) ihrer Gewinne pro
                        12-Tage-Zeitraum abheben.
                    </li>

                    <li>8.2. Unser Ziel ist es, Auszahlungen innerhalb von 1 bis 12 Werktagen durchzuführen. Dieser
                        Zeitrahmen kann aufgrund der Verfügbarkeit von Zahlungsprozessoren variieren, aber wir werden
                        immer alles in unserer Macht Stehende tun, um unsere Spieler so schnell wie möglich zu bezahlen,
                        damit Sie Ihre Gewinne schnell und einfach erhalten können. In Zeiten hoher Auszahlungsvolumen
                        behält sich das Casino das Recht vor, die wöchentlichen Auszahlungslimits auf €/£/AU$/$500 zu
                        senken. Ihr Auszahlungsantrag gelangt am ersten Geschäftstag nach Akzeptanz Ihrer
                        Verifizierungsdokumente in die Auszahlungswarteschlange.
                    </li>

                    <li>8.3. Das Casino behält sich das Recht vor, jederzeit Dokumente zur Identitätsprüfung
                        anzufordern, bevor Einzahlungen oder Auszahlungen gewährt werden, oder bei Verdacht auf
                        betrügerische Aktivitäten durch den Spieler. Konten, die unter Betrugsverdacht stehen, können
                        gesperrt werden, und Gewinne können bis zum Abschluss der Untersuchungsperiode unbefristet
                        eingefroren werden. Bei Verdacht auf Betrug können Gewinne aus Einzahlungen mit Kredit- /
                        Debitkarten bis zu 12 Monate eingefroren werden.
                    </li>

                    <li>8.4. Das Casino behält sich das Recht vor, jede Auszahlung über 30 Tage alt aufgrund fehlender
                        Sicherheitsdokumente oder unvollständiger Verifizierung des Spielerkontos rückgängig zu machen.
                    </li>

                    <li>8.5. Spieler, die einen beträchtlichen Betrag gewonnen haben, der vom Casino als
                        öffentlichkeitswürdig erachtet wird, erklären sich damit einverstanden, sich für jede
                        vom {casino.name} in Bezug auf diesen Gewinn arrangierte Veranstaltung zur Verfügung zu
                        stellen. {casino.name} respektiert jederzeit die Privatsphäre des Spielers in diesem Fall.
                    </li>

                    <li>8.6. Das Casino behält sich das Recht vor, Ihren Vornamen und den ersten Buchstaben Ihres
                        Nachnamens (z.B. John S.) in jeder Casino-Ankündigung bezüglich Gewinnergebnissen zu verwenden.
                        Alle Gewinne, die aufgrund eines technischen Fehlers der Software oder des Casinos entstanden
                        sind, werden annulliert und vom Spielerkonto entfernt. Etwaige beteiligte Einzahlung(en) werden
                        dem Spielerkonto zurückerstattet und können in Höhe der ursprünglichen Einzahlung(en) ausgezahlt
                        werden.
                    </li>
                    <li>8.7. Das Casino behält sich das Recht vor, Gewinne abzulehnen und Konten zu schließen, wenn Sie
                        als Bonusmissbraucher eingestuft wurden, keine oder nur risikoarme Strategien anwenden, um Geld
                        abzuheben; wenn Sie nicht nur zum persönlichen Vergnügen im Casino gespielt haben (d.h. wenn Sie
                        systematisch Wettstrategien anwenden, um das Casino zu schlagen, z.B.: Martingale); oder wenn
                        Ihre persönlichen Informationen in unserer casinoindustrieweiten Sicherheitsdatenbank auf der
                        schwarzen Liste stehen.
                    </li>
                    <li>8.8. Bitte beachten Sie, dass nur Bewohner des Vereinigten Königreichs die britische Pfund (GBP)
                        Währung wählen dürfen. Gewinne, die in britischen Pfund erzielt werden, werden nur in derselben
                        Währung an legale Bewohner innerhalb des UK ausgezahlt.
                    </li>
                    <li>8.9. Gewinne werden für ungültig erklärt, wenn das Spielerkonto Rückbuchungen oder unberechtigte
                        Rückerstattungen vorgenommen hat oder in irgendeiner Weise mit anderen Konten verknüpft ist, auf
                        denen innerhalb des inter-casino Sicherheitsnetzwerks Rückbuchungen vorgenommen wurden.
                    </li>
                    <li>8.10. Wenn ein Spieler mit einer Kreditkarte einzahlt, muss er ein Sicherheitsformular Dokumente
                        haben, um eine Auszahlung zu bearbeiten, und es kann eine Wartezeit von bis zu 7 Tagen geben,
                        bevor die Auszahlung bearbeitet wird.
                    </li>
                    <li>8.11. Bitte beachten Sie, dass Spieler in den USA, Australien und UK derzeit nur Gewinne über
                        Bitcoin auszahlen lassen können.
                    </li>
                    <li>8.12. Bei der Anforderung einer Bitcoin-Auszahlung muss der Spieler seine BTC-Wallet-Adresse in
                        der Kasse eingeben. Wir zahlen nur an die zum Zeitpunkt der Anforderung der Auszahlung
                        registrierte BTC-Wallet aus. Das Casino haftet nicht, wenn ein Spieler eine falsche
                        Bitcoin-Adresse eingibt, wenn er eine BTC-Auszahlung anfordert. Wenn wir eine BTC-Zahlung an
                        eine BTC-Adresse durchführen, die nicht dem Spieler gehört (aufgrund eines Fehlers des Spielers
                        bei der Anforderung der Auszahlung), werden wir die Zahlung nicht erneut ausstellen.
                    </li>

                </ul>
            </>]

        },
        {
            title: "RÜCKGABERECHT",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Danke, dass Sie ein Konto bei uns eröffnet und Geld auf Ihr Konto auf unseren Websites
                        eingezahlt haben.
                    </li>
                    <li>Wir bieten die Möglichkeit einer Rückerstattung auf alle auf unseren Websites getätigten
                        Einzahlungen an. Allerdings müssen bestimmte Bedingungen erfüllt sein. Wir laden Sie ein, die
                        unten ausführlicher erläuterten Bedingungen zu lesen.
                    </li>
                    <li>Unsere Rückgaberegelung ist einfach, unkompliziert und wird sofort bearbeitet.</li>
                    <li>Allerdings gibt es ein begrenztes Zeitfenster, wie angegeben:</li>
                    <ul>
                        <li>Innerhalb von 24 Stunden nach der ursprünglichen Einzahlung.</li>
                        <li>Wie zum Zeitpunkt der Kontoeröffnung erforderlich, muss die Compliance abgeschlossen und von
                            unserer Compliance-Abteilung genehmigt worden sein.
                        </li>
                        <li>Spielstörung - Screenshot erforderlich.</li>
                        <li>Spielguthaben nicht nutzbar - Guthaben ungespielt.</li>
                        <li>Falsche Kosten - Kontoauszug erforderlich.</li>
                        <li>Bearbeitungsgebühr</li>
                    </ul>
                    <li>Bei der Bearbeitung und Ausstellung Ihrer Rückerstattung fallen Gebühren an. Die verschiedenen
                        Gebühren (in Ihrer Währung) sind wie folgt
                    </li>
                    <ul>
                        <li>Papierscheck - 55.00</li>
                        <li>SEPA-Überweisung - 20.00</li>
                        <li>SWIFT-Überweisung - 75.00</li>
                    </ul>
                    <li>Verspätete oder fehlende Rückerstattungen (falls zutreffend):</li>
                    <ul>
                        <li>Wenn Sie Ihre Rückerstattung noch nicht erhalten haben, überprüfen Sie bitte zuerst Ihr
                            Bankkonto erneut.
                        </li>
                        <li>Dann kontaktieren Sie Ihre Kreditkartengesellschaft, es kann einige Zeit dauern, bis Ihre
                            Rückerstattung offiziell verbucht wird.
                        </li>
                        <li>Wenn Sie all dies getan haben und Ihre Rückerstattung immer noch nicht erhalten haben,
                            kontaktieren Sie uns bitte unter:
                        </li>
                    </ul>
                </ul>
            </>]

        },
        {
            title: "PREISE & GEWINNE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. Die persönlichen Informationen des Spielers, die zur Registrierung im Casino verwendet
                        wurden, werden für folgende Zwecke verwendet:
                    </li>
                    <ul>
                        <li>Zur Bestimmung der Gültigkeit der Identität des Spielers und zur Überprüfung der Aktivität
                            unter diesem Namen.
                        </li>
                        <li>Bei der Bereitstellung von Informationen an Dritte zur Einziehung von Geldbeträgen, die der
                            Spieler dem Casino schuldet.
                        </li>
                        <li>Wenn das Gesetz es erfordert, die Meldung an die zuständigen Behörden zur Aufdeckung und
                            Verhinderung von Straftaten.
                        </li>
                    </ul>

                </ul>
            </>]

        },
        {
            title: "MISSBRAUCH DES KONTOS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. Das Casino behält sich das Recht vor, Ihr Konto während einer Untersuchung zu sperren,
                        wenn das Casino begründeten Verdacht hat, dass Sie an Formen der Kollusion, Syndikation oder
                        Betrug während der Nutzung der Casino-Plattform, Website und/oder Systeme beteiligt waren. Wir
                        behalten uns auch das Recht vor, das gesamte oder einen Teil Ihres Kontostands zurückzuhalten,
                        Ihr Konto dauerhaft zu schließen und den Betrag von Ihrem Konto zurückzufordern, der durch eine
                        der folgenden Aktivitäten beeinträchtigt wurde:
                    </li>

                    <li>11.2. Kollusion: Die gemeinsame Anstrengung von zwei oder mehr Personen, um einen unfairen
                        Vorteil gegenüber der Software und anderen Spielern zu erlangen, indem Geldmittel zusammengelegt
                        werden, um eine unnatürliche Erhöhung des verfügbaren Bankrolls durch mehrere
                        Bonusauszeichnungen zu erreichen (ähnlich dem Multi-Accounting) mit dem Ziel, von der
                        Spiel-Engine-Entwicklung zu profitieren. Dies geschieht in der Regel in einem Casino, um die
                        Gesamtanzahl der vergebenen Einzahlungsboni zu erhöhen und so die Wahrscheinlichkeit eines
                        großen Gewinns bei einem Slot-Auszahlungsvorgang (zum Beispiel: Slot-Bonus-Runde) unnatürlich zu
                        maximieren, während sichergestellt wird, dass die Auszahlung eines solchen Ereignisses einem der
                        kolludierenden Mitglieder zusteht und somit die Gewinnchancen anderer Spieler im Casino
                        beeinträchtigt.
                    </li>

                    <li>11.3. Syndikation: Wir erfahren, dass Sie Mitglied und/oder Teil eines Syndikats, Clubs oder
                        einer Gruppe sind, in der Spieler Strategien für vorteilhaftes Spielen erhalten, um positive
                        Erwartungsszenarien durch den Erwerb von Werbeangeboten zu schaffen.
                    </li>

                    <li>11.4. Ausnutzung eines Fehlers, einer Lücke oder eines Fehlers in unserer oder einer
                        Drittanbieter-Software, die für die Dienstleistungen dieses Vertrags bereitgestellt wird.
                    </li>

                    <li>11.5. Wir erfahren, dass Sie in einem anderen Online-Gaming-Unternehmen, einschließlich
                        Chargebacks, Kollusion oder krimineller Aktivitäten, wegen betrügerischen oder kollusiven
                        Verhaltens schuldig befunden wurden.
                    </li>

                    <li>11.6. Wir erfahren, dass Sie irgendwelche Überweisungen, Einzahlungen und Käufe auf Ihr Konto
                        auf betrügerische Weise zurückgebucht oder verweigert haben.
                    </li>

                    <li>11.7. Verwendung von automatisierter Spielsoftware wie "Bots" oder jeder Art von
                        Simulationswerkzeugen oder Systemen, die entwickelt wurden, um einen unfairen Vorteil gegenüber
                        dem Casino zu bieten.
                    </li>

                    <li>11.8. Als Spieler, der dieses Casino nutzt, erklären Sie sich damit einverstanden, dass Sie
                        nicht kolludieren oder versuchen zu kolludieren, noch beabsichtigen, direkt oder indirekt an
                        einem Kollusionsschema teilzunehmen, das das Teilen von Gewinnen zwischen ausgewählten Spielern,
                        das Teilen von Geldern oder das Zusammenarbeiten zum gegenseitigen Nutzen durch mehrere
                        Akquisitionsincentive-Angebote beinhaltet, zum Nachteil anderer Spieler, die nicht an der
                        kolludierenden Partei beteiligt sind. Sie erklären sich auch damit einverstanden, das Casino zu
                        informieren, wenn Sie vermuten, dass ein Spieler mit anderen Spielern kolludiert oder dies
                        plant. Sie erklären sich auch damit einverstanden, dass Sie kein Teil eines Syndikats oder einer
                        Gruppe sind, die Strategien für vorteilhaftes Spielen einsetzt.
                    </li>

                    <li>11.9. Wir werden jeden Fall gründlich untersuchen, bei dem wir glauben, dass es Anzeichen für
                        betrügerische Aktivitäten, Kollusion, Syndikation oder illegale Aktivitäten gibt. Wir behalten
                        uns auch das Recht vor, unsere Geschäftseinheiten, Verarbeitungsunternehmen, elektronischen
                        Zahlungsanbieter oder andere Finanzinstitute, die wir verwenden, über Ihre Identität und über
                        jede vermutete rechtswidrige, betrügerische oder unangemessene Aktivität zu informieren.
                    </li>

                    <li>11.10. Die folgenden Handlungen des Spielers werden als Verstoß gegen die Allgemeinen
                        Geschäftsbedingungen des Casinos betrachtet, sind jedoch nicht darauf beschränkt. Die
                        Konsequenzen können die Kündigung des Casino-Kontos, die Einziehung von Gewinnen und bestehenden
                        Salden, die Verweigerung von Dienstleistungen, Promotionen und jeglichen anderen Angeboten durch
                        das Casino umfassen.
                    </li>
                    <ul>
                        <li>Mehr als ein Konto bei {casino.name} Online Casino und/oder mehr als ein Konto pro Haushalt
                            und/oder mehr als ein Konto pro Gerät, und/oder mehr als ein Konto von der gleichen
                            IP-Adresse, und/ oder mehr als ein Konto in einem verbundenen Schwesterkasino unter der
                            gleichen Verwaltung wie {casino.name} Online Casino.
                        </li>
                        <li>Sollte das Kasino zu irgendeinem Zeitpunkt eine Verbindung oder Beziehung zu einem
                            betrügerischen, gesperrten oder erstatteten oder zuvor geschlossenen Konto auf irgendeiner
                            Marke innerhalb des Rival-Netzwerks oder einem anderen Online-Kasino entdecken.
                        </li>
                        <li>Sollte das Kasino zu irgendeinem Zeitpunkt eine aktive oder vergangene
                            Beschwerde/Streit/Kritik auf einem öffentlichen Forum oder Blog oder einer anderen Art von
                            Website entdecken.
                        </li>
                        <li>Sollte der Spieler zu irgendeinem Zeitpunkt das Casinopersonal missbrauchen, belästigen oder
                            unangemessen behandeln, einschließlich mündlicher und schriftlicher Drohungen oder
                            Einschüchterungen.
                        </li>
                        <li>Eine Nichtübereinstimmung zwischen dem Namen auf dem Casino-Konto des Spielers und dem Namen
                            auf der/den zum Aufladen des Kontos verwendeten Kreditkarte(n) oder Zahlungsmethode(n).
                        </li>
                        <li>Unzutreffende oder falsche Registrierungsdaten.</li>
                        <li>Der Spieler spielt aus einem Gebiet oder einer Region heraus, die nicht vollständig dazu
                            berechtigt ist, Online-Glücksspiele zu erlauben.
                        </li>
                        <li>Der Spieler ist nicht das erforderliche Mindestalter gemäß den Geschäftsbedingungen des
                            Casinos.
                        </li>
                        <li>Wenn der Spieler einem anderen Spieler erlaubt oder zugelassen hat (absichtlich oder
                            unbeabsichtigt), auf seinem eigenen Casino-Konto zu spielen.
                        </li>
                        <li>Wenn eine Person im Casino nicht rein zur Unterhaltung gespielt hat und nur in beruflicher
                            Kapazität oder in Verbindung mit einem anderen Spieler aus einem anderen Club oder einer
                            Gruppe oder einem Teil davon teilgenommen hat.
                        </li>
                        <li>Wenn der Spieler absichtlich einen 'Chargeback' oder 'Dispute' der von seiner Kreditkarte
                            oder einer anderen Zahlungsmethode eingezahlten Gelder auf sein Casino-Konto vorgenommen hat
                            oder festgestellt wurde, dass er einen 'Chargeback' gemacht oder damit gedroht hat, dies in
                            irgendeiner anderen Eigenschaft in einem Schwesterkasino auf der Rival-Plattform oder einem
                            anderen Online-Kasino oder einer Website zu tun. Dies schließt mündliche und schriftliche
                            Drohungen ein, Transaktionen anzufechten, zurückzubuchen oder zu stoppen.
                        </li>
                        <li>Wenn der Spieler beim Betrügen erwischt wird oder wenn festgestellt wird, dass er
                            unregelmäßige Wettmuster macht, oder verdächtige oder irreguläre Wettstrategien verwendet
                            und oder der Spieler ein System eingeleitet hat (d.h. Martingale, Maschinen, Computer,
                            Software oder jede andere Form der Automatisierung) um einen Betrug zu verbreiten, der dafür
                            ausgelegt ist, dass das Casino durch diese Operation Einnahmen verliert.
                        </li>
                        <li>Der Spieler ist mit einem Mitarbeiter, ehemaligen Mitarbeiter, Unternehmen, Dritten oder
                            Agentur verbunden, die momentan oder in der Vergangenheit mit dem Casino verbunden ist.
                        </li>
                        <li>Der Spieler hat versucht, die Software oder Website des Casinos zu 'hacken', oder hat
                            irgendwelche Änderungen daran vorgenommen, die vom Casino nicht speziell erlaubt wurden.
                        </li>
                    </ul>
                    <li>11.11 Spieler, die sich entscheiden, eine Einzahlung mit Kryptowährung zu tätigen, müssen
                        sicherstellen, dass der Token/Coin erfolgreich eingetroffen ist und auf der Blockchain zur
                        angegebenen Krypto-Geldbörsenadresse bestätigt wurde. Wenn eine Einzahlung an die Brieftasche
                        gemacht wird, aber später 'annulliert' oder umgekehrt wird, wird dies als betrügerische
                        Aktivität angesehen. Infolgedessen werden auch alle bisherigen, laufenden oder zukünftigen
                        Auszahlungsanforderungen des Spielers als ungültig erklärt.
                    </li>
                </ul>
            </>]

        },
        {
            title: "ALLGEMEINE GESCHÄFTSBEDINGUNGEN FÜR DIE FREUNDEMPFEHLUNG",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. Die Allgemeinen Geschäftsbedingungen gelten für das Freunde-werben-Programm und alle
                        anderen Programme, die vom {casino.name} Online Casino angeboten werden. Durch die Eingabe aller
                        erforderlichen Informationen in das Freunde-werben-Programm stimmen sowohl Sie als auch die
                        empfohlene Partei (die empfohlene Person) allen Bedingungen dieses Programms zu und die
                        empfohlene Partei hat zugestimmt, dass ihre Daten von Ihnen an die werbende Partei weitergegeben
                        werden. Durch die Übermittlung dieser Informationen stimmt die empfohlene Partei zu, dass ihr
                        eine E-Mail vom {casino.name} Online Casino gesendet wird, in der sie aufgefordert wird, sich
                        für ein echtes Spielerkonto anzumelden. Mit der Eingabe von Informationen in das
                        Freunde-werben-Programm ist sich die empfohlene Partei vollkommen bewusst, dass die werbende
                        Partei ihre Daten in gutem Glauben eingereicht hat.
                    </li>
                    <li>12.2. Personen, die denselben Computer nutzen (Registrierung von derselben IP-Adresse), dürfen
                        aus Sicherheitsgründen nicht am Freunde-werben-Bonus teilnehmen. Empfohlene Spieler dürfen keine
                        bestehenden Mitglieder des {casino.name} Online Casinos sein. Die von der werbenden Partei
                        angegebene E-Mail-Adresse darf nicht mit einem aktuellen Spieler des {casino.name} Online
                        Casinos verbunden sein und sie darf auch nicht dieselbe E-Mail wie das werbende Mitglied sein.
                    </li>
                    <li>12.3. Das werbende Mitglied muss spezifische Informationen über seinen/seinen empfohlenen Freund
                        ausfüllen; dazu gehören unter anderem Vorname, Nachname und E-Mail-Adresse. Nachdem alle
                        erforderlichen Informationen bereitgestellt, eingereicht und dann von {casino.name} Online
                        Casino überprüft wurden, wird der Bonus sowohl an das werbende als auch an das empfohlene
                        Mitglied ausgegeben.
                    </li>
                    <li>12.4. Um sich für den Freunde-werben-Bonus zu qualifizieren, muss die empfohlene Partei zunächst
                        ein Echtgeldkonto eröffnen und mindestens 25 $,€, £ auf dieses Konto einzahlen. Sowohl der
                        werbende Freund als auch der empfohlene Freund erhalten ihren Bonus erst nachdem der empfohlene
                        Freund 25 $,€, £ auf sein echtes Spielerkonto eingezahlt hat und müssen 48 Stunden warten, bevor
                        sie den Bonus auf ihren echten Spielerkonten erhalten.
                    </li>
                </ul>
            </>]

        },]
}
const untilWeDontHaveOtherES = {
    title: "INTRODUCCIÓN",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Jugar en el casino, ya sea por "diversión" o por "juego real", constituye el acuerdo del miembro
                (jugador) de estar sujeto a los siguientes términos y condiciones aquí contenidos. El jugador acepta que
                este es un acuerdo vinculante entre el jugador y el casino, y es el único acuerdo que rige la relación
                del jugador con el casino, excluyendo todos los demás acuerdos, declaraciones y representaciones.
            </li>

            <li>1.2. Cada cláusula contenida en estos términos y condiciones será separada y divisible de las demás. Si
                alguna cláusula se considera nula, inválida o inexigible por cualquier motivo, los términos y
                condiciones restantes permanecerán en pleno vigor y efecto.
            </li>

            <li>1.3. La empresa se reserva el derecho de decisión final sobre la interpretación de estos Términos y
                Condiciones. Los servicios de correo electrónico y chat pueden proporcionar información y
                actualizaciones sobre nuestro servicio. La comunicación por correo electrónico o chat no puede modificar
                ni revocar los Términos y Condiciones. La omisión en el chat o correo electrónico de cubrir nuestros
                Términos y Condiciones o mencionar una parte de nuestros Términos y Condiciones no constituye motivos
                para violar los Términos y Condiciones. En caso de disputa, los Términos y Condiciones tienen autoridad
                final y se aplicarán sobre cualquier información proporcionada por chat o correo electrónico. Se
                requiere que todos los jugadores lean los Términos y Condiciones antes de registrarse.
            </li>

            <li>1.4. Los siguientes Términos y Condiciones rigen el uso del sitio web y casino en línea
                de {casino.name}:
            </li>

            <li>1.5. IMPORTANTE: Todos los depósitos son finales. No se puede completar ningún reembolso una vez que los
                depósitos se han jugado en nuestros juegos.
            </li>
        </ul>
    </>],

    sections: [{
        title: "CONTENIDO DEL SITIO WEB Y DEL CASINO",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. Todo el software y contenido del sitio web que se encuentra en el sitio web
                    de {casino.name} Casino está protegido por derechos de autor del Casino (los "Materiales
                    Licenciados"). Todos los Materiales Licenciados que se presentan visual o auditivamente al jugador
                    son solo para uso personal y siguen siendo propiedad intelectual del Casino y sus licenciatarios.
                    Ningún jugador adquirirá ningún derecho sobre todo o parte de los Materiales Licenciados, excepto el
                    derecho a usar los Materiales Licenciados de acuerdo con este Acuerdo.
                </li>
            </ul>
        </>]

    },
        {
            title: "OBLIGACIÓN DEL JUGADOR",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. Al convertirse en miembro (jugador) del casino, comprende y acepta cumplir todas las
                        reglas, términos y condiciones establecidos por {casino.name} de vez en cuando. Es
                        responsabilidad del jugador mantenerse al día con estas reglas, términos y condiciones ya que
                        están sujetos a cambios periódicos.
                    </li>
                    <li>3.2. Jugar en el {casino.name} Casino Online está restringido solo para adultos. Los jugadores
                        deben tener 25 años o más para jugar. Las restricciones de edad pueden cambiar según la ley
                        nacional local, y es responsabilidad del jugador verificar la edad mínima permitida en su país.
                        El Casino se reserva el derecho de requerir la verificación de la edad del jugador en cualquier
                        momento.
                    </li>
                    <li>3.3. Es posible que no se permita a los jugadores de los siguientes países abrir una cuenta en
                        nuestro casino: Afganistán, Armenia, Azerbaiyán, Bangladesh, Bielorrusia, Bhután, Bulgaria,
                        Islas Caimán, Costa Rica, Dinamarca, Estonia, Hungría, Kazajstán, Kirguistán, Países Bajos,
                        Antillas Neerlandesas, Polonia, Rumanía, Singapur, Siria, Turkmenistán e Islas Vírgenes. Las
                        cuentas serán cerradas y todos los ganancias o pérdidas de juego anuladas.
                    </li>
                    <li>3.4. Al crear una cuenta y convertirse en jugador del casino, ya sea para "juego divertido" o
                        "juego real", garantiza la autenticidad y precisión de toda la información que envía.
                        Si {casino.name} determina que cualquier información que le dio el jugador es
                        falsa, {casino.name} se reserva el derecho de suspender la cuenta de un jugador junto con todos
                        los ingresos relacionados. La cuenta permanecerá suspendida hasta que {casino.name} haya
                        determinado a su entera discreción que la información del jugador era auténtica y precisa.
                    </li>
                    <li>3.5. {casino.name} se reserva el derecho de rechazar la membresía en el casino a cualquier
                        solicitante o de suspender los privilegios de juego de un miembro existente sin causa y a
                        discreción exclusiva de {casino.name}.
                    </li>

                    <li>3.6. Al realizar apuestas en el casino, el jugador comprende que esta es su participación
                        voluntaria y es equivalente a apostar. La participación conlleva el riesgo de perder dinero
                        y {casino.name} bajo ninguna circunstancia será responsable de cualquier pérdida o daño que el
                        Jugador pueda sufrir en relación con el uso del casino por parte del jugador.
                    </li>
                    <li>3.7. Cualquier depósito realizado en el casino debe ser apostado al menos una vez antes de poder
                        solicitar un retiro. Si el depósito no se apuesta al menos una vez antes del retiro, el casino
                        se reserva el derecho de cobrar al jugador cualquier tarifa de procesamiento.
                    </li>
                    <li>3.8. El uso de dispositivos electrónicos, mecánicos, robóticos u otros para tomar decisiones de
                        juego automáticamente está estrictamente prohibido. Si {casino.name} detecta que un jugador ha
                        violado este término, se puede tomar acciones, como prohibir al jugador participar en futuras
                        promociones y/o juegos, confiscar las ganancias netas y ofrecer dicha información a una base de
                        datos de la industria de casinos de "jugadores restringidos".
                    </li>
                    <li>3.9. Al jugar cualquier juego en el casino, el jugador garantiza que está familiarizado con las
                        reglas de dicho juego y reconoce que las reglas de cada juego están disponibles mediante el uso
                        de la función "ayuda" en el casino.
                    </li>
                    <li>3.10. Al hacer un depósito con tarjeta de débito/crédito, pueden aplicarse costos adicionales de
                        terceros por la compañía de la tarjeta de crédito del Jugador y/o las asociaciones de tarjetas
                        y, como tal, están más allá del control de la Compañía. Tenga en cuenta que se le puede cobrar
                        una tarifa adicional de transacción extranjera por transacción dependiendo del monto depositado
                        por los bancos procesadores de tarjetas de crédito. Por lo general, las tarifas de transacción
                        extranjeras son un porcentaje del monto de cada compra de moneda extranjera, sin mínimo ni
                        máximo, llegando incluso al 6-8% en ocasiones. Estas son tarifas cobradas por los procesadores
                        de transacciones como VISA y MC. La mayoría de los bancos emisores optan por pasar esta tarifa a
                        su cliente.
                    </li>
                    <li>3.11. El casino se reserva el derecho de solicitar un Formulario de Verificación de Seguridad en
                        cualquier momento. Si una persona no devuelve un formulario de faxback y la identificación
                        personal requerida dentro de los 30 días posteriores a dicha solicitud, corre el riesgo de
                        suspensión y posible pérdida de su cuenta. En tal caso, el casino puede determinar
                        unilateralmente la resolución de cualquier fondo de cuenta.
                    </li>
                    <li>3.12. Al hacer un depósito en Bitcoin (BTC), el jugador es el único responsable de transferir
                        correctamente los bitcoins a la dirección proporcionada por la cajera. Para asegurarse de que
                        cada transferencia de Bitcoin se acredite automáticamente, el jugador debe iniciar el proceso de
                        depósito de Bitcoin en la caja para CADA depósito de Bitcoin que se vaya a realizar. No
                        procesamos ni aceptamos ninguna otra criptomoneda y no podemos acreditar cuentas de jugadores
                        por fondos enviados a través de otras criptomoneda; por ejemplo, si un jugador transfiere
                        Bitcoin Cash (BCH), no podemos acreditar la cuenta del jugador por esa criptomoneda.
                    </li>

                </ul>
            </>]

        },
        {
            title: "CUENTAS Y CONTRASEÑAS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} permite solo una cuenta de casino por persona, hogar, dirección IP, dirección
                        de correo electrónico o computadora en cada uno de sus casinos. La existencia de una cuenta
                        vinculada en el casino puede llevar al cierre de su cuenta y a la renuncia de todas las
                        ganancias.
                    </li>

                    <li>4.2. Debido a una abrumadora historia de abuso promocional, a los jugadores de ciertos países se
                        les obliga a cumplir con requisitos de apuesta separados para las promociones ofrecidas. Estos
                        se pueden encontrar en nuestros "Términos y condiciones de promociones".
                    </li>

                    <li>4.3. Es responsabilidad del jugador mantener confidenciales su número de cuenta y contraseña. Se
                        entiende que la participación de cualquier tercero en la cuenta del casino en línea de un
                        jugador viola este acuerdo. El jugador asume toda la responsabilidad y acepta no responsabilizar
                        a {casino.name} por ninguna pérdida o daño resultante del fallo del jugador en mantener
                        confidencial su número de cuenta y contraseña.
                    </li>

                    <li>4.4. Al proporcionar su dirección de correo electrónico al registrarse, el jugador acepta
                        recibir correspondencia ocasional en esa dirección. Si el jugador ya no está interesado en
                        recibir estos correos electrónicos, puede darse de baja enviando un correo electrónico al
                        soporte.
                    </li>

                    <li>4.5. Al proporcionar su dirección postal, el jugador acepta recibir material impreso ocasional
                        del casino. Si el jugador ya no está interesado en recibir correo, puede darse de baja enviando
                        un correo electrónico al soporte.
                    </li>
                </ul>
            </>]

        },
        {
            title: "TÉRMINOS Y CONDICIONES GENERALES",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. El Casino está comprometido a ofrecer juegos justos y honestos y confirma que el software y
                        todos los juegos ofrecidos por {casino.name} Online Casino dependen de un Generador de Números
                        Aleatorios (RNG) auditado y validado por un tercero asegurando que todos los juegos sean justos
                        y honestos.
                    </li>
                    <li>5.2. El Casino se reserva el derecho de modificar el saldo en la cuenta de un usuario para
                        redimir las sumas adeudadas al Casino.
                    </li>
                    <li>5.3. El usuario acepta pagar todas las sumas debidas a {casino.name} Online Casino y nunca
                        intentará re-atribuir, negar, revocar, disputar, realizar un contracargo, bloquear, retener o
                        cancelar ninguno de estos pagos.
                    </li>
                    <li>5.4. Si el usuario ha depositado o retirado fondos utilizando una E-Wallet como MoneyBookers,
                        Neteller o EcoCard, el Casino puede solicitar copias de otras transacciones que muestren ese
                        E-Wallet específico en uso.
                    </li>
                    <li>5.5. Cualquier actividad ilegal o criminal realizada por el jugador resultará en la terminación
                        inmediata de la cuenta del Casino y el Casino se reserva el derecho de informar a las
                        autoridades correspondientes y otros casinos en línea y comerciantes de estas acciones con total
                        divulgación.
                    </li>
                    <li>5.6. El Casino se reserva el derecho de cambiar cualquiera de sus servicios ofrecidos en
                        cualquier momento, incluyendo una indisponibilidad temporal de atención al cliente, soporte
                        técnico o cualquier otro servicio dado al jugador.
                    </li>
                    <li>5.7. En caso de fallo técnico o fallo de la computadora, todas las apuestas en juego pueden
                        considerarse nulas y sin efecto. El Casino no puede ser responsable por pérdidas, ganancias u
                        otros problemas generados por un fallo técnico o fallo en cualquier forma o modo.
                    </li>
                    <li>5.8. Si un jugador se abstiene de acceder a su cuenta del Casino durante un período de 90 días o
                        más o el jugador pasa 90 días sin hacer una sola apuesta o apuesta, el Casino tendrá derecho a
                        cualquier saldo en la cuenta de ese jugador y la suma será confiscada. Si el jugador desea
                        retirar este monto después de este período, examinaremos cada situación caso por caso, pero nos
                        reservamos el derecho de invocar la regla anterior de 3 meses.
                    </li>
                </ul>
            </>]

        },
        {
            title: "TÉRMINOS Y CONDICIONES DE PROMOCIONES Y BONIFICACIONES",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. Todos y cada uno de los bonos gratuitos y bonos de depósito no pueden ser retirados del
                        Casino, y están destinados a mejorar el tiempo de juego solamente. Al retirarse, los montos de
                        los bonos serán deducidos por el Casino antes de que se envíe la retirada.
                    </li>
                    <li>6.2. Todo el dinero del bono será eliminado en el momento de retirar el efectivo, el dinero del
                        bono es dinero que puede ser usado para ayudar a generar ganancias pero no puede ser retirado
                        como parte de las ganancias. El bono será tratado como 'No Retirable' y no será reemitido a su
                        cuenta sino deducido de las ganancias por el gestor de retiros del casino durante el proceso de
                        pago. El dinero de cashback también es 'No Retirable' y el casino se reserva el derecho de
                        deducir el dinero de cashback de una retirada pendiente de un jugador.
                    </li>
                    <li>6.3. Los bonos, a menos que el Casino especifique lo contrario de antemano, llevan un requisito
                        de apuesta que debe completarse antes de que un jugador pueda retirar.
                    </li>
                    <li>6.4. Todas las promociones de cashback llevan un requisito de apuesta de 1X. Los jugadores no
                        pueden retirar un bono de cashback hasta que la suma del cashback sea apostada en su totalidad.
                        Todo el dinero de cashback está restringido a un límite máximo de retiro de 10X el monto del
                        cashback. Esto incluye cashback otorgado como 'regalos', 'comps' o 'fichas gratis'. El Casino se
                        reserva el derecho de deducir dinero de Cashback de una retirada pendiente.
                    </li>
                    <li>6.5. Todo el dinero de cashback ganado de un premio de Torneo, premio de Rifa o Promoción
                        Especial tiene un límite máximo de retiro de 10X el bono de cashback y lleva un requisito de
                        apuesta de 1X. Todos los bonos del 200% o más, seguro de cashback del 75% o más serán
                        considerados Promociones Especiales. El Casino se reserva el derecho de deducir dinero de
                        Cashback de una retirada pendiente. Los jugadores que tengan una retirada pendiente o saldo de
                        más de 100 $/€/£ no califican para recibir premios de Torneos o para recibir efectivo de una
                        Rifa o cashback de cualquier promoción de Cashback en curso o cualquier otra promoción especial
                        ofrecida por el Equipo de Promociones.
                    </li>
                    <li>6.5.1 Bono Especial - todos los bonos del 200% o más, bonos de cashback del 100% o más serán
                        considerados Bonos Especiales
                    </li>
                    <li>6.6. Es responsabilidad del jugador estar al tanto de los detalles del bono que solicitan o se
                        les da, incluyendo el requisito de apuesta, juegos restringidos y cualquier otro detalle,
                        términos y requisitos asociados con dicho bono.
                    </li>
                    <li>6.7. Los jugadores que elijan no jugar con bonos, deben indicarlo antes de empezar a jugar con
                        un bono. Si un jugador no quiere el bono que ha sido depositado en su cuenta por el casino,
                        entonces tiene derecho a solicitar a un representante de atención al cliente del casino que
                        revierta el bono de su cuenta de casino, por medio de correo electrónico, llamada telefónica o
                        Chat en vivo. Si se recibe un bono y se hace una apuesta (de cualquier cantidad), el bono tendrá
                        que ser jugado todo el camino hasta que el saldo sea cero o el requisito de apuesta esté
                        completo. Si el jugador no ha empezado a jugar, el soporte eliminará el bono si lo solicitan.
                    </li>
                    <li>6.8. Los bonos gratuitos (Comps, Fichas Gratis, Puntos Comp convertidos u otros bonos sin
                        depósito) son bonos jugables otorgados a los jugadores para que puedan jugar de forma gratuita.
                        Los bonos gratuitos tienen un requisito de apuesta diferente a otros bonos, y tienen un límite
                        de retiro fijo de no más de 50 créditos (50 $,€,£,AUD, o 500 ZAR). Cualquier saldo restante o
                        extra es 'No Retirable' y se debe descontar del saldo de un jugador. Cada jugador tiene
                        permitido retirar ganancias de un bono gratuito sólo una vez durante su vida de juego a menos
                        que sea específicamente aprobado por el gerente del casino.
                    </li>
                    <li>6.9. Un jugador que nunca ha hecho un depósito y desea retirar una ganancia de ficha gratis,
                        debe hacer primero un depósito mínimo exitoso de 15.00 ($,€,£,AUD, o 150 ZAR). Esta cantidad de
                        primer depósito no está permitida para ser retirada o añadida a una retirada pendiente
                        existente. Si se añade a una retirada existente, la cantidad será deducida del monto total de la
                        retirada. Un jugador que ya ha hecho un depósito exitoso y ahora desea retirar una ganancia de
                        ficha gratis, debe haber depositado dentro de los últimos 30 días para que la retirada sea
                        aprobada y el depósito no puede ser añadido a una retirada pendiente existente. Si se añade a
                        una retirada existente, la cantidad será deducida del monto total de la retirada. Los Bonos
                        Gratuitos (Fichas Gratis) tienen un requisito de apuesta de 35X a 100X a menos que se
                        especifique lo contrario
                    </li>
                    <li>6.10. Todas las promociones ofrecidas por el Casino son válidas sólo para el período
                        especificado en la oferta promocional misma que se publica en el sitio web del Casino y/o
                        cualquier otra comunicación proporcionada por el propio Casino.
                    </li>
                    <li>6.11. Un bono igual o mayor al 200% que ha sido reclamado con cualquier depósito, estará sujeto
                        a un retiro máximo de 10x la cantidad del depósito.
                    </li>
                    <li>6.12. Un jugador de bono gratuito (un jugador que juega solo bonos gratuitos, y aún no ha hecho
                        un depósito) puede retirar ganancias cuando juega sus primeros tres bonos gratuitos en su
                        cuenta, durante los primeros 30 días después de la fecha de registro de la cuenta. Después de
                        tres bonos jugados o después de 30 días desde la fecha de registro, los fondos recibidos de un
                        bono gratuito se considerarán no cobrables y serán deducidos del saldo o de la retirada.
                    </li>
                    <li>6.13. En caso de que un jugador haya terminado/jugado su requisito de apuesta, el casino tiene
                        el derecho de ajustar el saldo al monto máximo de retirada permitido y borrar cualquier balance
                        extra. En casos donde un jugador no es elegible para hacer una retirada, el casino tiene el
                        derecho de eliminar cualquier ganancia y saldo una vez que se cumpla la apuesta.
                    </li>

                </ul>
            </>]

        },
        {
            title: "TÉRMINOS Y CONDICIONES DE RETIRO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Ten en cuenta que si haces un depósito de 249 USD/EURO/GBP/AUD o menos, sólo puedes retirar
                        10 veces la cantidad de tu depósito para esa sesión de juego (todas las demás monedas están
                        vinculadas al USD). El casino aplicará esta regla a la solicitud de retiro y ajustará el monto
                        del retiro en consecuencia. Cualquier fondo no utilizado será clasificado como "No Retirable" y
                        será restado de la cuenta del jugador. Además, si se obtiene una ganancia utilizando un bono de
                        depósito durante una sesión de juego y se hace una solicitud de retiro, esta ganancia también
                        será considerada como un monto "No Retirable" y será restado de la retirada pendiente en
                        particular.
                    </li>
                    <li>7.2. Los clientes pueden presentar su solicitud de retiro en cualquier momento. El tiempo de
                        aprobación puede tardar entre uno - catorce (1 - 14) días laborables a partir de la fecha en que
                        se completó con éxito el proceso de autorización. Procesar una retirada pendiente requiere
                        también la autorización del departamento de Fraude.
                    </li>
                    <li>7.3. Todos y cada uno de los bonos canjeados por el jugador no pueden ser retirados o redimidos
                        del Casino, y serán eliminados de las ganancias del jugador al procesar el retiro.
                    </li>
                    <li>7.4. Todas las retiradas están sujetas a una tarifa de transferencia de 10-40 $,€,£. Las tarifas
                        se basan en la ubicación y son determinadas por nuestro procesador de pagos y es responsabilidad
                        del jugador individual asumirlas.
                    </li>
                    <li>7.5. Para que una retirada sea aprobada, se pedirá al jugador que presente cierta documentación
                        e información para verificar la cuenta. Si no cumple o no presenta dichos documentos e
                        información en un plazo de 30 días desde que se solicitó la retirada, ésta será cancelada. Es
                        responsabilidad del jugador individual enviar la documentación requerida. La aprobación de la
                        retirada depende de todas las condiciones descritas y de la verificación de todos los documentos
                        requeridos.
                    </li>
                    <li>7.6. Un jugador no puede retirar ganancias mientras todavía haya un requisito de apuesta adjunto
                        a su saldo. Deben cumplir con los requisitos de apuesta antes de hacer una solicitud de retiro,
                        o correr el riesgo de que la retirada sea negada.
                    </li>
                    <li>7.7. El casino se reserva el derecho de rechazar o retener un bono o ganancias en el caso de que
                        un depósito sea cancelado, devuelto o rechazado por cualquier servicio de pago o procesador en
                        cualquier momento.
                    </li>
                    <li>7.8. Si el jugador ha apostado en juegos restringidos con un bono que no permite dichos juegos,
                        el Casino se reserva el derecho de cancelar cualquier retiro y confiscar cualquier ganancia.
                        Antes de empezar a jugar con un bono de depósito o una ficha gratuita, es responsabilidad del
                        jugador verificar y entender cuáles son las restricciones de ese bono o ficha en particular.
                    </li>
                    <li>7.9. El monto mínimo para una retirada es de 50 €/$/£/AUD o 500 ZAR.</li>
                    <li>7.10. Todos y cada uno de los depósitos deben ser apostados al menos una vez (1X) la suma del
                        depósito para calificar para un retiro. Un retiro no puede ser aprobado a menos que primero se
                        cumpla con una apuesta mínima de 1X el depósito. Los depósitos realizados como parte de
                        promociones están sujetos a los Términos y Condiciones y al requisito de apuesta de esa
                        promoción específica.
                    </li>
                    <li>7.11. Al depositar fondos en tu cuenta, nos indicas y nosotros acordamos retenerlos, junto con
                        cualquier ganancia, con el único y específico propósito de usarlos (i) para colocar cualquier
                        apuesta de juego; y (ii) para cubrir cualquier tarifa o cargo que puedas incurrir en conexión
                        con el uso de nuestros servicios. Nos reservamos el derecho de cobrar una tarifa del 3% - 6.5%
                        por el procesamiento de los depósitos con Tarjeta de Crédito.
                    </li>
                    <li>7.12. Un jugador que no agregó fondos a su cuenta durante un mes o más (30 días), será
                        considerado un jugador de bono gratuito y no será elegible para retirar ganancias de bonos
                        gratuitos o efectivo otorgado por el casino. La posibilidad de retirar ganancias de bonos
                        gratuitos está reservada solo para nuestros jugadores activos.
                    </li>
                    <li>7.13. No se permite mezclar un saldo de juego libre con un saldo de juego real y es
                        responsabilidad del jugador. Si se hizo un depósito encima de un saldo de una ficha gratuita,
                        cualquier saldo jugable que el jugador tuviera antes de depositar para jugar por dinero real, se
                        considerará juego libre, por lo tanto, bajo las reglas del juego libre.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PREMIOS & GANANCIAS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. Los jugadores que tienen su cuenta verificada, pueden retirar hasta €/£/AU$/$1000 (R10,000)
                        por semana. En caso de que no se hayan enviado documentos y la cuenta aún no esté verificada, se
                        permitirá a los jugadores retirar hasta €/£/AU$/$500 (R5000) por semana. Nuestros mejores
                        jugadores VIP pueden retirar un máximo de €/£/AU$/$10,000 (R100,000) de sus ganancias cada 12
                        días a discreción de la administración.
                    </li>
                    <li>8.2. Nuestro objetivo es pagar retiros dentro de 1 y 12 días hábiles. Este plazo puede variar
                        debido a la disponibilidad del procesador, pero siempre haremos todo lo posible para pagar a
                        nuestros jugadores lo más rápido posible para que puedas obtener tus ganancias de manera rápida
                        y fácil. Durante períodos de altos niveles de retiro, el casino se reserva el derecho de reducir
                        los límites de retiro semanal a €/£/AU$/$500. Tu retiro entra en la cola de retiro el primer día
                        hábil después de que se han aceptado tus documentos de verificación.
                    </li>
                    <li>8.3. El casino se reserva el derecho de solicitar documentación con el fin de verificar la
                        identidad en cualquier momento; antes de otorgar cualquier depósito o retiro, o ante la sospecha
                        de actividad fraudulenta por parte del jugador. Las cuentas bajo investigación de fraude pueden
                        tener sus cuentas suspendidas y las ganancias congeladas indefinidamente hasta que termine el
                        período de investigación. En el caso de sospecha de fraude, las ganancias derivadas de depósitos
                        con tarjeta de crédito/débito pueden ser congeladas y retenidas por hasta 12 meses.
                    </li>
                    <li>8.4. El casino se reserva el derecho de revertir cualquier retiro mayor a 30 días, debido a la
                        falta de documentos de seguridad o la verificación incompleta de la cuenta del jugador.
                    </li>
                    <li>8.5. Los jugadores que ganen una cantidad considerable que el casino considere digna de
                        publicidad acuerdan estar disponibles para cualquier evento organizado por {casino.name} en
                        relación a dicho premio. {casino.name} respetará en todo momento la privacidad del jugador en
                        este evento.
                    </li>
                    <li>8.6. El casino se reserva el derecho de usar tu primer nombre y la primera inicial de tu
                        apellido (por ejemplo: John S.) en cualquier anuncio del casino sobre los resultados ganadores.
                        Cualquier ganancia acumulada a partir de un mal funcionamiento técnico del software o del
                        casino, será anulada y eliminada de la cuenta del jugador. Cualquier depósito(s) involucrado
                        será reembolsado a la cuenta del jugador y podrá ser retirado por el monto original del
                        depósito(s).
                    </li>
                    <li>8.7. El casino se reserva el derecho de negar ganancias y cerrar cuentas si has sido designado
                        como abusador de bonos, jugando estrategias de no riesgo o bajo riesgo para retirar dinero; si
                        no has jugado en el Casino solo por entretenimiento personal (es decir, si empleas
                        sistemáticamente sistemas de apuestas para vencer al casino, por ejemplo: Martingale); o si tu
                        información personal se encuentra en la lista negra en nuestra base de datos de seguridad de la
                        industria del casino.
                    </li>
                    <li>8.8. Ten en cuenta que solo los residentes en el Reino Unido pueden optar por la moneda Libra
                        Esterlina (GBP). Las ganancias que ocurran en Libras Esterlinas solo se pagarán en la misma
                        moneda a residentes legales dentro del Reino Unido.
                    </li>
                    <li>8.9. Las ganancias serán anuladas si la cuenta del jugador ha sufrido contracargos o reembolsos
                        no autorizados o está de alguna manera vinculada a otras cuentas donde se han colocado
                        contracargos dentro de la red de seguridad inter-casino.
                    </li>
                    <li>8.10. Si un jugador deposita con una tarjeta de crédito, se le requerirá tener documentos de
                        formulario de seguridad para procesar un retiro, y puede haber un período de espera de hasta 7
                        días antes de que se procese el retiro.
                    </li>
                    <li>8.11. Ten en cuenta que los jugadores en Estados Unidos, Australia y Reino Unido pueden retirar
                        sus ganancias actualmente solo a través de Bitcoin.
                    </li>
                    <li>8.12. Al solicitar un retiro en Bitcoin, el jugador debe ingresar su dirección de cartera BTC en
                        la caja. Solo pagaremos a la cartera BTC registrada en el momento de solicitar el retiro. El
                        casino no se hará responsable si un jugador ingresa una dirección de Bitcoin incorrecta al
                        solicitar un retiro en BTC. Si completamos un pago en BTC a una dirección BTC que no pertenece
                        al jugador (debido a un error del jugador al solicitar el retiro), no reemitiremos el pago.
                    </li>
                </ul>
            </>]

        },
        {
            title: "POLITICA DE REEMBOLSO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Gracias por abrir una cuenta con nosotros y depositar fondos en su cuenta en nuestros
                        sitios web.
                    </li>
                    <li>Ofrecemos la opción de reembolso para todos los depósitos realizados en nuestros sitios web. Sin
                        embargo, deben cumplirse ciertas condiciones. Le invitamos a leer las condiciones explicadas con
                        más detalle a continuación.
                    </li>
                    <li>Nuestra política de devoluciones es simple y directa, y se procesará de inmediato.</li>
                    <li>Sin embargo, hay una ventana de tiempo limitada, según se indica:</li>
                    <ul>
                        <li>Dentro de las 24 horas del depósito original.</li>
                        <li>Según lo requerido en el momento de abrir la cuenta, el cumplimiento debe completarse y ser
                            aprobado por nuestro departamento de cumplimiento.
                        </li>
                        <li>Mal funcionamiento de los juegos: se requiere captura de pantalla.</li>
                        <li>No se pueden jugar créditos: saldo no jugado.</li>
                        <li>Cargos incorrectos: se requiere extracto.</li>
                        <li>Tarifa de procesamiento.</li>
                    </ul>
                    <li>Hay una tarifa involucrada en el procesamiento y emisión de su reembolso. Las tarifas diferentes
                        (en su moneda) son las siguientes:
                    </li>
                    <ul>
                        <li>Cheque en papel - 55.00</li>
                        <li>Transferencia SEPA - 20.00</li>
                        <li>Transferencia SWIFT - 75.00</li>
                    </ul>
                    <li>Reembolsos tardíos o faltantes (si corresponde):</li>
                    <ul>
                        <li>Si aún no ha recibido su reembolso, primero verifique nuevamente su cuenta bancaria.</li>
                        <li>Luego, contacte a su compañía de tarjeta de crédito, puede pasar algún tiempo antes de que
                            su reembolso se publique oficialmente.
                        </li>
                        <li>Si ha hecho todo esto y aún no ha recibido su reembolso, contáctenos en</li>
                    </ul>
                </ul>
            </>]

        },
        {
            title: "PREMIOS & GANANCIAS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. La información personal del jugador que se utilizó para registrarse en el casino se
                        utilizará para los siguientes fines:
                    </li>
                    <ul>
                        <li>Para determinar la validez de la identidad del jugador y verificar la actividad bajo ese
                            nombre.
                        </li>
                        <li>Al proporcionar información a un tercero para cobrar cualquier suma de dinero adeudada al
                            casino por el jugador.
                        </li>
                        <li>Cuando la ley lo requiere, reportar a las autoridades competentes para la detección y
                            prevención de delitos.
                        </li>
                    </ul>
                </ul>
            </>]

        },
        {
            title: "MAL USO DE CUENTA",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. El casino se reserva el derecho de suspender su cuenta en espera de investigación en caso
                        de que el casino tenga motivos razonables para sospechar que usted ha estado involucrado en
                        cualquier forma de colusión, sindicación o fraude durante su uso de la plataforma del casino,
                        sitio web y/o sistemas. También nos reservamos el derecho de retener todo o parte del saldo de
                        su cuenta, cerrar su cuenta permanentemente, así como recuperar de su cuenta la cantidad de
                        ganancias, pagos o bonos que se hayan visto afectados por cualquiera de las siguientes
                        actividades:
                    </li>
                    <li>11.2 Colusión: El esfuerzo conjunto de dos o más individuos para obtener una ventaja injusta
                        sobre el software y otros jugadores a través de la agrupación de fondos para lograr un aumento
                        no natural del bankroll disponible a través de múltiples premios de bonificación (similarmente a
                        la multi-contabilidad) para beneficiarse/aprovecharse del diseño del motor del juego. Esto
                        generalmente se hace en un casino con el fin de aumentar el número total de bonos de depósito
                        otorgados, maximizando de manera no natural la probabilidad de lograr un gran pago de ganancia
                        de slot (por ejemplo: ronda de bono de slot) mientras se asegura de que el pago de tal evento se
                        otorgue a uno de los miembros en colusión, desviando efectivamente el potencial de ganancia de
                        cualquier otro jugador que utilice el casino.
                    </li>
                    <li>11.3. Sindicación: Nos damos cuenta de que usted es miembro y/o forma parte de un sindicato,
                        club o grupo donde los jugadores reciben estrategias de juego ventajosas con el fin de crear
                        escenarios de expectativa positiva a través de la adquisición de ofertas promocionales.
                    </li>
                    <li>11.4. Explotación de una falla, laguna o error en nuestro software o en cualquier software de
                        terceros proporcionado para los servicios de este acuerdo.
                    </li>
                    <li>11.5. Nos damos cuenta de que se le ha encontrado culpable de comportamiento fraudulento o
                        colusivo en otro negocio de juegos en línea, incluyendo contracargos, colusión o actividades
                        criminales.
                    </li>
                    <li>11.6. Nos damos cuenta de que usted ha revertido o negado cualquiera de las transferencias,
                        depósitos y compras a su cuenta de una manera fraudulenta.
                    </li>
                    <li>11.7. Hacer uso de cualquier software de juego automatizado como "bots", o cualquier tipo de
                        herramientas de simulación o sistemas diseñados para proporcionar una ventaja injusta sobre el
                        casino.
                    </li>
                    <li>11.8. Como jugador que hace uso de este casino, usted acepta que no está coludiendo ni
                        intentando coludir, ni tiene intención de participar directa o indirectamente en un esquema de
                        colusión que implique el reparto de ganancias entre jugadores seleccionados, compartiendo
                        fondos, o trabajando juntos hacia un objetivo final común mientras se beneficia de múltiples
                        ofertas de incentivo de adquisición, en perjuicio de otros jugadores que no están involucrados
                        en la parte coludida. También acepta que informará al casino por uno de los medios necesarios si
                        sospecha que un jugador está coludiendo, o planea coludir con otros jugadores. También acepta
                        que no forma parte de un sindicato o grupo que se dedica a estrategias de juego ventajoso.
                    </li>
                    <li>11.9. Investigaremos a fondo cualquier caso en el que creemos que puede haber alguna existencia
                        de un elemento de actividad fraudulenta, colusión, sindicación o actividad ilegal. También nos
                        reservamos el derecho de informar a nuestras unidades de negocio, empresas procesadoras,
                        proveedores de pagos electrónicos u otras instituciones financieras que utilizamos, de su
                        identidad y de cualquier sospecha de actividad ilícita, fraudulenta o indebida.
                    </li>
                    <li>11.10. Las siguientes acciones del jugador se considerarán una violación de los términos y
                        condiciones del Casino, pero no limitadas a esta lista. Las consecuencias pueden incluir la
                        terminación de la cuenta de casino del jugador, confiscación de ganancias y saldos existentes,
                        negación de servicios, promociones y cualquier otra oferta por parte del Casino.
                    </li>
                    <ul>
                        <li>Más de una cuenta en {casino.name} Casino en línea y/o más de una cuenta por hogar y/o más
                            de una cuenta por dispositivo, y/o más de una cuenta desde la misma dirección IP, y/ o más
                            de una cuenta en un casino hermano afiliado bajo la misma administración
                            que {casino.name} Casino en línea.
                        </li>
                        <li>Si en cualquier momento, el casino descubre una vinculación o conexión con una cuenta
                            fraudulenta, suspendida o reembolsada o previamente cerrada en cualquier marca dentro de la
                            red Rival o cualquier otro casino en línea.
                        </li>
                        <li>Si en cualquier momento el casino descubre una queja/desacuerdo/crítica activa o pasada en
                            un foro público o blog o cualquier otro tipo de sitio web.
                        </li>
                        <li>Si en cualquier momento el jugador abusa, acosa o habla inapropiadamente a cualquiera del
                            personal del Casino, incluyendo amenazas verbales y escritas o intimidación.
                        </li>
                        <li>Una discrepancia en la cuenta del Casino del jugador que no coincide con el nombre en la(s)
                            tarjeta(s) de crédito o método(s) de pago utilizados para hacer depósitos en la cuenta.
                        </li>
                        <li>Información de registro incorrecta o falsa.</li>
                        <li>El jugador está jugando desde un territorio o región que no está completamente autorizado
                            para permitir juegos en línea.
                        </li>
                        <li>El jugador no tiene la edad legal requerida según los Términos y Condiciones del Casino.
                        </li>
                        <li>Si el Jugador ha permitido o permitido (intencionalmente o sin intención) a otro jugador
                            jugar en su propia cuenta de Casino.
                        </li>
                        <li>Si un individuo no ha jugado en el Casino puramente como entretenimiento y solo ha
                            participado en una capacidad profesional o en asociación con otro jugador de otro club o
                            grupo o parte del mismo.
                        </li>
                        <li>Si se descubre que el Jugador ha realizado deliberadamente un 'contracargo' o 'disputa' de
                            fondos depositados desde su tarjeta de crédito, o cualquier otro método de pago, en su
                            cuenta del Casino; o se ha descubierto que ha hecho un 'contracargo' o amenazado con hacerlo
                            en cualquier otra capacidad en un casino hermano en la plataforma Rival o cualquier otro
                            casino en línea o sitio web. Esto incluye amenazas verbales y escritas para disputar,
                            contracargar o detener transacciones.
                        </li>
                        <li>Si se descubre que el Jugador está haciendo trampa o se le ha encontrado haciendo patrones
                            de apuestas irregulares, o estrategias de apuestas sospechosas o irregulares y/o el Jugador
                            ha instigado un sistema (es decir, Martingala, máquinas, computadoras, software o cualquier
                            otra forma de automatización) para proliferar un engaño diseñado para que el Casino pierda
                            ingresos debido a esta operación.
                        </li>
                        <li>El jugador está vinculado a cualquier empleado, ex empleado, empresa, tercero o agencia
                            conectada al Casino actualmente o en el pasado.
                        </li>
                        <li>El jugador ha intentado 'hackear' el software o el sitio web del Casino, o ha hecho cambios
                            en él de cualquier manera no específicamente permitida por el Casino.
                        </li>
                    </ul>
                    <li>11.11 Los jugadores que elijan hacer un depósito utilizando criptomonedas deben asegurarse de
                        que el token/moneda haya llegado con éxito y haya sido confirmado en la cadena de bloques a la
                        dirección de la billetera criptográfica designada proporcionada durante el proceso de depósito.
                        Si se realiza un depósito en la billetera pero posteriormente se 'anula' o revierte, se
                        considerará actividad fraudulenta. En consecuencia, cualquier solicitud de retiro anterior, en
                        curso o futura realizada por el jugador también se considerará nula.
                    </li>
                </ul>
            </>]

        },
        {
            title: "TÉRMINOS Y CONDICIONES DE RECOMENDAR A UN AMIGO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. Los términos y condiciones generales se aplican al programa de referir a un amigo y a
                        todos los demás programas ofrecidos por {casino.name} Casino en línea. Al enviar toda la
                        información requerida al programa de referir a un amigo, tanto usted como la parte referida
                        (persona a quien se refiere) consienten todos los términos de este programa y la parte referida
                        ha consentido que sus datos sean suministrados por usted a la parte referente. Al enviar esta
                        información, la parte referida consiente que se le envíe un correo electrónico por parte
                        de {casino.name} Casino en línea solicitándole que se registre para una cuenta de jugador real.
                        Al ingresar información en el programa de referir a un amigo, la parte referida está plenamente
                        consciente de que la parte referente ha enviado sus detalles de buena fe.
                    </li>
                    <li>12.2. Las personas que utilicen la misma computadora (que se registren desde la misma dirección
                        IP) no podrán participar en el bono de referir a un amigo por razones de seguridad. Los
                        jugadores referidos no deben ser miembros existentes de {casino.name} Casino en línea. La
                        dirección de correo electrónico proporcionada por la parte referente no debe estar asociada con
                        ningún jugador actual de {casino.name} Casino en línea ni debe ser la misma dirección de correo
                        electrónico que la del miembro referente.
                    </li>
                    <li>12.3. Se requerirá que el miembro referente proporcione información específica sobre su amigo
                        referido; esta información incluye, pero no se limita a su nombre dado, apellido y dirección de
                        correo electrónico. Después de que toda la información requerida haya sido proporcionada,
                        enviada y luego verificada por {casino.name} Casino en línea, entonces y sólo entonces se
                        suministrará el bono tanto a los miembros referentes como referidos.
                    </li>
                    <li>12.4. Para calificar para el bono de referir a un amigo, la parte referida debe primero
                        registrarse para una cuenta de dinero real y depositar un mínimo de 25 $,€,£ en esa cuenta.
                        Tanto el amigo referente como el amigo referido recibirán su bono sólo después de que el amigo
                        referido haya depositado 25 $,€,£ en su cuenta de jugador real y deben permitir 48 horas antes
                        de recibir el bono en sus cuentas de jugador real.
                    </li>
                </ul>
            </>]

        },]
}
const untilWeDontHaveOtherIT = {
    title: "INTRODUZIONE",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Giocare al casinò, sia per "gioco divertente" che per "gioco reale", costituisce l'accordo del
                membro (giocatore) a essere vincolato dai seguenti termini e condizioni contenuti nel presente
                documento. Il giocatore accetta che questo è un accordo vincolante tra il giocatore e il casinò ed è
                l'unico accordo che disciplina il rapporto del giocatore con il casinò, con esclusione di tutti gli
                altri accordi, dichiarazioni e rappresentazioni.
            </li>

            <li>1.2. Ogni clausola contenuta in questi termini e condizioni sarà separata e divisibile dalle altre. Se
                una clausola viene ritenuta nulla, invalida o non applicabile per qualsiasi motivo, le restanti
                condizioni rimarranno in pieno vigore ed effetto.
            </li>

            <li>1.3. La Società si riserva il diritto di decisione finale sull'interpretazione di questi Termini e
                Condizioni. I servizi di posta elettronica e chat possono fornire informazioni e aggiornamenti sul
                nostro servizio. La comunicazione via e-mail o chat non può modificare o revocare i Termini e
                Condizioni. La mancata menzione dei nostri Termini e Condizioni in chat o e-mail non costituisce motivo
                di violazione dei Termini e Condizioni. In caso di controversia, i Termini e Condizioni hanno autorità
                finale e saranno applicati su qualsiasi informazione fornita tramite chat o e-mail. È obbligatorio per
                tutti i giocatori leggere i Termini e Condizioni prima di registrarsi.
            </li>

            <li>1.4. I seguenti Termini e Condizioni regolano l'uso del sito web e del casinò online di {casino.name}:
            </li>

            <li>1.5. IMPORTANTE: Tutti i depositi sono definitivi. Nessun rimborso può essere effettuato una volta che i
                depositi sono stati utilizzati nei nostri giochi.
            </li>
        </ul>
    </>],

    sections: [{
        title: "CONTENUTI DEL SITO WEB E DEL CASINÒ",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. Tutti i software e i contenuti del sito web trovati sul sito del Casinò {casino.name} sono
                    protetti da copyright dal Casinò (i "Materiali Concessi in Licenza"). Tutti i Materiali Concessi in
                    Licenza presentati visivamente o auditivamente al giocatore sono solo per uso personale e rimangono
                    proprietà intellettuale del Casinò e dei suoi concedenti in licenza. Nessun giocatore acquisirà
                    alcun diritto, in nessun caso, a tutto o parte dei Materiali Concessi in Licenza, tranne il diritto
                    di utilizzare i Materiali Concessi in Licenza in conformità con il presente Accordo.
                </li>
            </ul>
        </>]

    },
        {
            title: "OBBLIGHI DEL GIOCATORE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. Diventando un membro (giocatore) del casinò, comprendi pienamente e accetti di rispettare
                        tutte le
                        regole, termini e condizioni stabilite da {casino.name} di volta in volta. È responsabilità del
                        giocatore
                        mantenersi aggiornato su queste regole, termini e condizioni poiché sono soggetti a
                        cambiamenti periodici.
                    </li>
                    <li>3.2. Giocare su {casino.name} Casino Online è limitato solo agli adulti. I giocatori devono
                        avere 25 anni
                        o più per giocare. Le restrizioni di età possono cambiare secondo la legge nazionale locale, ed
                        è
                        responsabilità del giocatore verificare l'età minima permessa nel loro paese. Il
                        Casinò si riserva il diritto di richiedere in qualsiasi momento la verifica dell'età al
                        giocatore.
                    </li>
                    <li>3.3. Potrebbe non essere consentito ai giocatori dei seguenti Paesi aprire un account nel nostro
                        casinò:
                        Afghanistan, Armenia, Azerbaijan, Bangladesh, Bielorussia, Bhutan, Bulgaria, Isole Cayman, Costa
                        Rica, Danimarca, Estonia, Ungheria, Kazakistan, Kirghizistan, Paesi Bassi, Antille Olandesi,
                        Polonia, Romania, Singapore, Siria, Turkmenistan e Isole Vergini. Gli account saranno chiusi e
                        tutti i vincite o perdite di gioco annullate.
                    </li>
                    <li>3.4. Creando un account e diventando un giocatore del casinò, sia per il "gioco divertente" o
                        "gioco reale",
                        garantisci l'autenticità e l'accuratezza di tutte le informazioni che invii. Se viene
                        determinato da {casino.name} che qualsiasi informazione data dal giocatore è
                        falsa, {casino.name}
                        si riserva il diritto di sospendere l'account del giocatore insieme a tutti i correlati incassi.
                        L'account
                        rimarrà sospeso fino a quando {casino.name} non avrà determinato a sua esclusiva discrezione che
                        le informazioni del giocatore erano autentiche e accurate.
                    </li>
                    <li>3.5. {casino.name} si riserva il diritto di rifiutare la membership nel casinò a qualsiasi
                        richiedente o di
                        sospendere i privilegi di gioco di un membro esistente senza motivo e a sola
                        discrezione di {casino.name}.
                    </li>
                    <li>3.6. Effettuando scommesse al casinò, il giocatore realizza che questo è il suo coinvolgimento
                        volontario
                        ed è equivalente al gioco d'azzardo. La partecipazione comporta il rischio di perdere denaro e
                        {casino.name} non sarà in alcun caso responsabile per eventuali perdite o danni subiti
                        dal Giocatore in connessione con l'uso del casinò da parte del giocatore.
                    </li>
                    <li>3.7. Ogni deposito effettuato al casinò deve essere scommesso una volta prima che possa essere
                        richiesto un prelievo.
                        Se il deposito non viene scommesso almeno una volta prima del prelievo, il casinò si riserva il
                        diritto di
                        addebitare al giocatore eventuali spese di elaborazione.
                    </li>

                    <li>3.8. L'uso di dispositivi elettronici, meccanici, robotizzati o altri dispositivi per prendere
                        decisioni di gioco automaticamente è assolutamente vietato. Se viene rilevato
                        da {casino.name} che un giocatore ha violato questo termine, possono essere prese misure incluso
                        il divieto al giocatore di future promozioni e/o giochi, la confisca delle vincite nette, e
                        l'offerta di tali informazioni a un database del settore dei casinò di "giocatori limitati".
                    </li>
                    <li>3.9. Giocando a qualsiasi gioco nel casinò, il giocatore garantisce che conosce le regole di
                        tale gioco e riconosce che le regole di ogni gioco sono disponibili utilizzando la funzione
                        "aiuto" nel casinò.
                    </li>
                    <li>3.10. Quando si effettua un deposito con carta di debito/credito, possono essere applicati costi
                        aggiuntivi di terze parti dalla società di carte di credito del giocatore e/o dalle associazioni
                        di carte e come tali sono al di fuori del controllo della Società. Si prega di notare che
                        potrebbe essere addebitata una tariffa aggiuntiva per transazione estera per transazione a
                        seconda dell'importo depositato dalle banche che elaborano carte di credito. Di solito, le
                        tariffe sulle transazioni estere sono una percentuale dell'importo di ciascun acquisto in valuta
                        estera, senza minimo o massimo, raggiungendo anche il 6-8% a volte. Queste sono tariffe
                        addebitate dai processori di transazioni come VISA e MC. La maggior parte delle banche emittenti
                        sceglie di passare questa tariffa ai loro clienti.
                    </li>
                    <li>3.11. Il casinò si riserva il diritto di richiedere un modulo di verifica della sicurezza in
                        qualsiasi momento. Se una persona non restituisce un modulo di ritorno via fax e
                        l'identificazione personale richiesta entro 30 giorni da tale richiesta, rischia la sospensione
                        e possibile perdita del suo account. In tal caso, il casinò può determinare unilateralmente la
                        risoluzione di eventuali fondi dell'account.
                    </li>
                    <li>3.12. Quando effettua un deposito Bitcoin (BTC), il giocatore è l'unico responsabile per
                        trasferire correttamente i bitcoin all'indirizzo fornito dal cassiere. Per garantire che ogni
                        trasferimento di Bitcoin sia accreditato automaticamente, il giocatore deve avviare il processo
                        di deposito Bitcoin nel cassiere per OGNI deposito Bitcoin da effettuare. Non elaboriamo o
                        accettiamo alcuna altra criptovaluta e non possiamo accreditare gli account dei giocatori per i
                        fondi inviati tramite altre criptovalute; ad esempio, se un giocatore trasferisce Bitcoin Cash
                        (BCH), non possiamo accreditare l'account del giocatore per quella criptovaluta.
                    </li>
                </ul>
            </>]

        },
        {
            title: "CONTO & PASSWORD",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} consente un solo account casinò per persona, famiglia, indirizzo IP,
                        indirizzo email o computer in ciascuno dei suoi casinò. Avere un account collegato al casinò può
                        causare la chiusura del tuo account e la perdita di eventuali vincite.
                    </li>
                    <li>4.2. A causa di una storia travagliata di abusi promozionali, i giocatori di alcuni paesi sono
                        costretti a rispettare requisiti di scommessa separati per le promozioni offerte. Questi possono
                        essere trovati nelle nostre "Condizioni delle promozioni".
                    </li>
                    <li>4.3. È responsabilità del giocatore mantenere riservati il numero e la password del suo account.
                        È inteso che coinvolgere terze parti nell'account del casinò online di un giocatore viola questo
                        accordo. Il giocatore assume la piena responsabilità e accetta di non
                        ritenere {casino.name} responsabile per eventuali perdite o danni derivanti dal fallimento del
                        giocatore nel mantenere riservato il numero dell'account e la password.
                    </li>
                    <li>4.4. Fornendo il proprio indirizzo email durante la registrazione, il giocatore accetta di
                        ricevere occasionalmente corrispondenza via email a quell'indirizzo. Se il giocatore non è più
                        interessato a ricevere queste email, può annullare l'iscrizione inviando una email al supporto.
                    </li>
                    <li>4.5. Fornendo il proprio indirizzo postale, il giocatore accetta di ricevere occasionalmente
                        materiale cartaceo dal casinò. Se il giocatore non è più interessato a ricevere la posta, può
                        annullare l'iscrizione inviando una email al supporto.
                    </li>
                </ul>
            </>]

        },
        {
            title: "TERMINI E CONDIZIONI GENERALI",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. Il Casinò si impegna a offrire giochi equi e onesti e conferma che il software e tutti i
                        giochi offerti da {casino.name} Online Casino si basano su un Generatore di Numeri Casuali (RNG)
                        auditato e validato da un terzo garantendo che tutti i giochi siano equi e onesti.
                    </li>
                    <li>5.2. Il Casinò si riserva il diritto di modificare il saldo sul conto di un utente per
                        riscattare somme dovute al Casinò.
                    </li>
                    <li>5.3. L'utente accetta di pagare tutte le somme dovute a {casino.name} Online Casino e di non
                        cercare mai di re-attribuire, negare, revocare, contestare, effettuare chargeback, bloccare,
                        trattenere o annullare nessuno di questi pagamenti.
                    </li>
                    <li>5.4. Se l'utente ha depositato o prelevato fondi utilizzando un E-Wallet come MoneyBookers,
                        Neteller o EcoCard, il Casinò può richiedere copie di altre transazioni che mostrano quel
                        specifico E-Wallet in uso.
                    </li>
                    <li>5.5. Qualsiasi attività illegale o criminale fatta dal giocatore risulterà nella cessazione
                        immediata del conto del Casinò e il Casinò si riserva il diritto di informare le autorità
                        competenti e altri casinò online e commercianti di queste azioni con piena divulgazione.
                    </li>
                    <li>5.6. Il Casinò si riserva il diritto di modificare qualsiasi dei suoi servizi offerti in
                        qualsiasi momento, compresa una indisponibilità temporanea del supporto clienti, del supporto
                        tecnico o di qualsiasi altro servizio dato al giocatore.
                    </li>
                    <li>5.7. In caso di guasto tecnico o malfunzionamento del computer, tutte le scommesse in gioco
                        possono essere considerate nulle e non valide. Il Casinò non può essere ritenuto responsabile
                        per perdite, vincite o altri problemi generati da un guasto tecnico o malfunzionamento in
                        qualsiasi modo o forma.
                    </li>
                    <li>5.8. Se un giocatore si astiene dal accedere al suo account del Casinò per un periodo di 90
                        giorni o più o il giocatore passa 90 giorni senza fare una singola scommessa o puntata, il
                        Casinò avrà diritto a qualsiasi saldo nell'account di quel giocatore e la somma sarà confiscata.
                        Se il giocatore desidera prelevare questo importo dopo questo periodo, esamineremo ogni
                        situazione caso per caso, ma ci riserviamo il diritto di invocare la suddetta regola dei 3 mesi.
                    </li>
                </ul>
            </>]

        },
        {
            title: "TERMINI E CONDIZIONI DELLE PROMOZIONI E DEI BONUS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. Tutti i bonus gratuiti e i bonus sul deposito non possono essere ritirati dal Casinò, e
                        sono destinati solo a migliorare il tempo di gioco. Al momento del prelievo, l'importo dei bonus
                        sarà detratto dal Casinò prima che il prelievo venga inviato.
                    </li>
                    <li>6.2. Tutto il denaro bonus sarà rimosso al momento del prelievo in contanti, il denaro bonus è
                        denaro che può essere utilizzato per aiutare a generare vincite ma non può essere ritirato come
                        parte delle vincite. Il bonus sarà trattato come "Non ritirabile" e non verrà reemesso sul tuo
                        conto, ma detratto dalle vincite dal gestore dei prelievi del casinò durante il processo di
                        pagamento. Anche il denaro cashback è "Non ritirabile" e il casinò si riserva il diritto di
                        detrarre il denaro cashback da un prelievo pendente dei giocatori.
                    </li>
                    <li>6.3. I bonus, a meno che non specificato in anticipo dal Casinò, comportano un requisito di
                        scommessa che deve essere completato prima che un giocatore possa prelevare.
                    </li>
                    <li>6.4. Tutte le promozioni di cashback comportano un requisito di scommessa di 1X. I giocatori non
                        possono prelevare un bonus cashback fino a quando la somma del cashback non è scommessa per
                        intero. Tutti i soldi del cashback sono soggetti a un limite massimo di prelievo di 10X
                        l'importo del cashback. Questo include cashback assegnati come "regali", "comps" o "fiches
                        gratis". Il Casinò si riserva il diritto di detrarre denaro Cashback da un prelievo pendente
                    </li>
                    <li>6.5. Tutti i soldi del cashback vinti da un premio di un torneo, un premio di una lotteria o una
                        promozione speciale hanno un limite massimo di prelievo di 10X il bonus cashback e comportano un
                        requisito di scommessa di 1X. Tutti i bonus del 200% o più, l'assicurazione cashback del 75% o
                        più saranno considerate Promozioni Speciali. Il Casinò si riserva il diritto di detrarre denaro
                        Cashback da un prelievo pendente. I giocatori che hanno un prelievo pendente o un saldo
                        superiore a 100 $/€/£ non sono idonei a ricevere premi di tornei o a ricevere denaro da una
                        lotteria o cashback da qualsiasi promozione di Cashback in corso o qualsiasi altra promozione
                        speciale offerta dal Team delle Promozioni.
                    </li>
                    <li>6.5.1 Bonus Speciale - tutti i bonus del 200% o più, i bonus cashback del 100% o più saranno
                        considerati Bonus Speciali
                    </li>
                    <li>6.6. È responsabilità del giocatore essere a conoscenza dei dettagli del bonus che richiede o
                        che gli viene dato, compreso il requisito di scommessa, i giochi limitati e qualsiasi altro
                        dettaglio, termini e requisiti associati a detto bonus.
                    </li>
                    <li>6.7. I giocatori che scelgono di non giocare con i bonus, devono dichiararlo prima di iniziare a
                        giocare con un bonus. Se un giocatore non vuole il bonus che è stato depositato nel suo conto
                        dal casinò, ha diritto di richiedere a un rappresentante del servizio clienti del casinò di
                        invertire il bonus fuori dal suo conto del casinò, tramite email, chiamata telefonica o chat dal
                        vivo. Se viene ricevuto un bonus e viene fatta una scommessa (di qualsiasi importo), il bonus
                        dovrà essere giocato fino alla fine, fino a quando il saldo non è zero o il requisito di
                        scommessa è completo. Se il giocatore non ha iniziato a giocare, il supporto rimuoverà il bonus
                        se lo richiede.
                    </li>

                    <li>6.8. I bonus gratuiti (Comps, Free Chips, punti comp convertiti o qualsiasi altro bonus senza
                        deposito) sono bonus giocabili assegnati ai giocatori in modo che possano giocare gratuitamente.
                        I bonus gratuiti hanno un requisito di scommessa diverso rispetto ad altri bonus e hanno un
                        limite di prelievo fisso di non più di 50 Crediti (50 $,€,£,AUD, o 500 ZAR). Qualsiasi saldo
                        rimanente o extra è 'Non ritirabile' e deve essere cancellato dal saldo del giocatore. Ogni
                        giocatore ha il diritto di prelevare le vincite da un bonus gratuito solo una volta durante la
                        sua vita di gioco, a meno che non sia specificatamente approvato dal manager del casinò.
                    </li>
                    <li>6.9. Un giocatore che non ha mai effettuato un deposito e desidera prelevare una vincita da un
                        chip gratuito, è tenuto a effettuare prima un deposito minimo di successo di 15.00 ($,€,£,AUD, o
                        150 ZAR). Questo primo importo del deposito non può essere ritirato o aggiunto a un prelievo
                        pendente esistente. Se aggiunto a un prelievo esistente, l'importo sarà detratto dall'importo
                        totale del prelievo. Un giocatore che ha precedentemente effettuato un deposito di successo e
                        ora desidera prelevare una vincita da un chip gratuito, è tenuto ad aver depositato negli ultimi
                        30 giorni affinché il prelievo venga approvato e il deposito non può essere aggiunto a un
                        prelievo pendente esistente. Se aggiunto a un prelievo esistente, l'importo sarà detratto
                        dall'importo totale del prelievo. I bonus gratuiti (Free Chips) hanno un requisito di scommessa
                        da 35X a 100X a meno che non sia diversamente specificato
                    </li>
                    <li>6.10. Tutte le promozioni offerte dal Casinò sono valide solo per il periodo specificato
                        nell'offerta promozionale stessa, che è postata sul sito web del Casinò e/o in qualsiasi altra
                        comunicazione fornita dal Casinò stesso.
                    </li>
                    <li>6.11. Un bonus pari o superiore al 200% che è stato richiesto con qualsiasi deposito, sarà
                        soggetto a un massimo di cashout di 10 volte l'importo del deposito.
                    </li>
                    <li>6.12. Un giocatore di bonus gratuiti (un giocatore che gioca solo bonus gratuiti, e non ha
                        ancora effettuato un deposito) può prelevare le vincite quando gioca i suoi primi tre bonus
                        gratuiti sul suo conto, durante i primi 30 giorni dopo la registrazione dell'account Dopo tre
                        bonus giocati o dopo 30 giorni dalla data di registrazione, i fondi ricevuti da un bonus
                        gratuito saranno considerati non ritirabili e saranno detratti dal saldo o dal prelievo.
                    </li>
                    <li>6.13. Nel caso in cui un giocatore abbia terminato \ giocato verso il basso, il suo requisito di
                        scommessa, il casinò si riserva il diritto di adeguare il saldo all'importo massimo di prelievo
                        consentito e cancellare qualsiasi saldo extra. Nei casi in cui un giocatore non è idoneo a
                        effettuare un prelievo, il casinò si riserva il diritto di cancellare eventuali vincite e saldo
                        una volta che la scommessa è soddisfatta.
                    </li>

                </ul>
            </>]

        },
        {
            title: "TERMINI E CONDIZIONI DI RECESSO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Ricorda che se fai un deposito di 249 USD/EURO/GBP/AUD o meno, puoi ritirare solo 10 volte
                        l'importo del tuo deposito per quella sessione di gioco (tutte le altre valute sono legate
                        all'USD). Il casino applicherà questa regola alla richiesta di prelievo e adeguerà l'importo del
                        prelievo di conseguenza. Qualsiasi fondo inutilizzato sarà classificato come "Non prelevabile" e
                        sottratto dal conto del giocatore. Inoltre, se una vittoria è ottenuta utilizzando un bonus di
                        deposito durante una sessione di gioco e viene fatta una richiesta di prelievo, questa vittoria
                        sarà considerata anch'essa un importo "Non prelevabile" e sarà sottratta dal prelievo pendente
                        in questione.
                    </li>
                    <li>7.2. I clienti possono presentare la loro richiesta di prelievo in qualsiasi momento. Il tempo
                        di approvazione può variare tra uno - quattordici (1 - 14) giorni lavorativi a partire dalla
                        data in cui il processo di autorizzazione è stato completato con successo. La gestione di un
                        prelievo pendente richiede l'autorizzazione anche dal dipartimento antifrode.
                    </li>
                    <li>7.3. Tutti i bonus riscattati dal giocatore non possono essere prelevati o riscattati dal
                        Casinò, e saranno rimossi dalle vincite del giocatore al momento dell'elaborazione del prelievo.
                    </li>
                    <li>7.4. Tutti i prelievi sono soggetti a una commissione di trasferimento da 10-40 $,€,£. Le
                        commissioni sono basate sulla posizione e sono determinate dal nostro processore di pagamento e
                        sono responsabilità del singolo giocatore assorbire.
                    </li>
                    <li>7.5. Per l'approvazione di un prelievo, al giocatore verrà chiesto di presentare alcuni
                        documenti e informazioni per verificare l'account. Il mancato rispetto o il mancato invio dei
                        documenti e delle informazioni entro 30 giorni dal momento in cui è stato richiesto il prelievo,
                        comporterà l'annullamento del prelievo. È responsabilità del singolo giocatore inviare la
                        documentazione richiesta. L'approvazione del prelievo dipende da tutte le condizioni descritte e
                        dalla verifica di tutti i documenti richiesti.
                    </li>
                    <li>7.6. Un giocatore non può prelevare le vincite finché c'è ancora un requisito di scommessa
                        legato al loro saldo. Devono completare i requisiti di scommessa prima di fare una richiesta di
                        prelievo, o rischiano di vedersi negare il prelievo.
                    </li>
                    <li>7.7. Il casinò ha il diritto di rifiutare o trattenere un bonus o delle vincite nel caso in cui
                        un deposito venga cancellato, restituito o negato da qualsiasi servizio di pagamento o
                        processore in qualsiasi momento.
                    </li>
                    <li>7.8. Se il giocatore ha scommesso su giochi limitati con un bonus che non permette detti giochi,
                        il Casinò si riserva il diritto di cancellare qualsiasi prelievo e confiscare qualsiasi vincita.
                        Prima di iniziare a giocare con un bonus di deposito o un chip gratuito, è responsabilità del
                        giocatore controllare e capire quali sono le restrizioni di quel particolare bonus o chip.
                    </li>
                    <li>7.9. L'importo minimo per un prelievo è 50 €/$/£/AUD o 500 ZAR.</li>
                    <li>7.10. Tutti i depositi devono essere scommessi almeno una volta (1X) la somma del deposito per
                        qualificarsi per un prelievo. Un prelievo non può essere approvato a meno che non sia prima
                        soddisfatta una scommessa minima di 1X il deposito. I depositi effettuati come parte delle
                        promozioni sono soggetti ai Termini e Condizioni specifici di quella promozione e al requisito
                        di scommessa.
                    </li>
                    <li>7.11. Depositando fondi nel tuo account, ci indichi e noi accettiamo di tenerli, insieme a
                        qualsiasi vincita, allo scopo specifico e solitario di utilizzarli (i) per piazzare scommesse di
                        gioco; e (ii) per regolare eventuali tariffe o costi che potresti sostenere in relazione all'uso
                        dei nostri servizi. Ci riserviamo il diritto di addebitare una commissione dal 3% al 6.5% per
                        l'elaborazione dei depositi con carta di credito.
                    </li>
                    <li>7.12. Un giocatore che non ha aggiunto fondi al suo account per un mese o più (30 giorni), sarà
                        considerato un giocatore di bonus gratuito e non avrà diritto a prelevare le vincite dai bonus
                        gratuiti o dal denaro concesso dal casinò. La possibilità di ritirare le vincite dai bonus
                        gratuiti è riservata ai nostri giocatori attivi.
                    </li>
                    <li>7.13. Non è consentito mescolare un saldo di un gioco gratuito con un saldo di gioco reale ed è
                        sotto la responsabilità del giocatore. Se un deposito è stato effettuato su un saldo da un chip
                        gratuito, qualsiasi saldo giocabile che il giocatore aveva prima di depositare per giocare per
                        reali, sarà considerato gioco gratuito, quindi, secondo le regole del gioco gratuito.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PREZZI & VINCITE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. I giocatori che hanno verificato il loro account possono prelevare fino a € / £ / AU $ / $
                        1000 (R10,000) a settimana. Nel caso in cui non siano ancora stati inviati documenti e l'account
                        non sia ancora verificato, i giocatori potranno prelevare fino a € / £ / AU $ / $ 500 (R5000) a
                        settimana. I nostri giocatori VIP di livello più alto possono prelevare un massimo di € / £ / AU
                        $ / $ 10.000 (R100,000) delle loro vincite per ogni periodo di 12 giorni a discrezione della
                        direzione.
                    </li>
                    <li>8.2. Il nostro obiettivo è pagare i prelievi entro 1 e 12 giorni lavorativi. Questo intervallo
                        di tempo può variare a causa della disponibilità del processore, ma faremo sempre tutto il
                        possibile per pagare i nostri giocatori il più velocemente possibile, così da poter ottenere
                        rapidamente ed agevolmente le tue vincite. Durante i periodi di alti livelli di prelievo, il
                        casinò si riserva il diritto di abbassare i limiti di prelievo settimanali a € / £ / AU $ / $
                        500. Il tuo prelievo entra nella coda di prelievo il primo giorno lavorativo dopo l'accettazione
                        dei tuoi documenti di verifica.
                    </li>
                    <li>8.3. Il casinò si riserva il diritto di richiedere documentazione per lo scopo di verifica
                        dell'identità in qualsiasi momento; prima di concedere depositi o prelievi, o in caso di
                        sospetto di attività fraudolente da parte del giocatore. Gli account sotto indagine per frode
                        possono avere i loro account sospesi e le vincite congelate a tempo indeterminato fino a quando
                        non sarà terminato il periodo di indagine. In caso di sospetto di frode, le vincite derivanti da
                        depositi con carta di credito/debito possono essere congelate e trattenute fino a 12 mesi.
                    </li>
                    <li>8.4. Il casinò si riserva il diritto di annullare qualsiasi prelievo superiore a 30 giorni, a
                        causa della mancanza di documenti di sicurezza o della verifica incompleta dell'account del
                        giocatore.
                    </li>
                    <li>8.5. I giocatori che vincono un importo considerevole ritenuto degno di pubblicità dal casinò
                        accettano di rendersi disponibili per qualsiasi evento organizzato da {casino.name} in relazione
                        a quella vittoria. {casino.name} rispetterà sempre la privacy del giocatore in questo evento.
                    </li>
                    <li>8.6. Il casinò si riserva il diritto di utilizzare il tuo nome di battesimo e la prima lettera
                        del tuo cognome (ad esempio: John S.) in qualsiasi annuncio del casinò riguardante i risultati
                        delle vincite.
                        Qualsiasi vincita accumulata a seguito di un malfunzionamento tecnico del software o del casinò,
                        sarà annullata e rimossa dall'account del giocatore. Qualsiasi deposito(i) coinvolto sarà
                        rimborsato all'account del giocatore e potrà essere incassato per l'importo originale del/dei
                        deposito(i).
                    </li>
                    <li>8.7. Il casinò si riserva il diritto di negare le vincite e chiudere account(s) se sei stato
                        designato come un abusante di bonus, giocando strategie senza rischio o a basso rischio al fine
                        di prelevare denaro; se non hai giocato al Casinò esclusivamente per divertimento personale
                        (cioè se impieghi sistematicamente sistemi di scommessa per battere il casinò, ad esempio:
                        Martingale); o se le tue informazioni personali sono inserite in una blacklist nel nostro
                        database di sicurezza dell'intera industria dei casinò.
                    </li>

                    <li>8.8. Si prega di notare che solo i residenti nel Regno Unito possono scegliere la valuta
                        Sterlina Britannica (GBP). Le vincite che si verificano in Sterline Britanniche verranno pagate
                        solo nella stessa valuta a residenti legali all'interno del UK.
                    </li>
                    <li>8.9. Le vincite saranno annullate se l'account del giocatore ha subito chargeback(s) o rimborsi
                        non autorizzati o è in qualsiasi modo collegato ad altri account(s) dove sono stati posti
                        chargeback(s) all'interno della rete di sicurezza inter-casino.
                    </li>
                    <li>8.10. Se un giocatore deposita con una carta di credito, è obbligato ad avere un modulo di
                        sicurezza documenti per poter elaborare un incasso, e ci può essere un periodo di attesa fino a
                        7 giorni prima che il prelievo venga elaborato.
                    </li>
                    <li>8.11. Si prega di notare che i giocatori negli Stati Uniti, Australia e UK possono attualmente
                        ritirare le vincite solo attraverso Bitcoin.
                    </li>
                    <li>8.12. Quando si richiede un prelievo in Bitcoin, il giocatore deve inserire il proprio indirizzo
                        del portafoglio BTC nel cassiere. Pagheremo solo al portafoglio BTC registrato al momento della
                        richiesta di prelievo. Il casinò non sarà responsabile se un giocatore inserisce un indirizzo
                        Bitcoin errato quando richiede un prelievo in BTC. Se completiamo un pagamento BTC a un
                        indirizzo BTC che non appartiene al giocatore (a causa di un errore del giocatore quando
                        richiede il prelievo), non emetteremo di nuovo il pagamento.
                    </li>

                </ul>
            </>]

        },
        {
            title: "POLITICA DI RIMBORSO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Grazie per aver aperto un account con noi e depositato fondi nel tuo account sui nostri
                        siti web.
                    </li>
                    <li>Offriamo la possibilità di un rimborso su tutti i depositi effettuati sui nostri siti web.
                        Tuttavia, devono essere soddisfatte determinate condizioni. Ti invitiamo a leggere le condizioni
                        spiegate più dettagliatamente di seguito.
                    </li>
                    <li>La nostra politica di restituzione è semplice, diretta e sarà elaborata immediatamente.</li>
                    <li>Tuttavia, c'è una finestra temporale limitata come indicato:</li>
                    <ul>
                        <li>Entro 24 ore dal deposito originale.</li>
                        <li>Come richiesto al momento dell'apertura dell'account, la conformità deve essere completata e
                            approvata dal nostro dipartimento di conformità.
                        </li>
                        <li>Malfunzionamento dei giochi - È richiesta una schermata.</li>
                        <li>Impossibilità di utilizzare i crediti - Saldo non utilizzato.</li>
                        <li>Addebiti errati - È richiesto un estratto conto.</li>
                        <li>Commissione di elaborazione</li>
                    </ul>
                    <li>C'è una tassa per l'elaborazione e l'emissione del rimborso. Le diverse tariffe (nella tua
                        valuta) sono le seguenti
                    </li>
                    <ul>
                        <li>Assegno cartaceo - 55,00</li>
                        <li>Trasferimento SEPA - 20,00</li>
                        <li>Trasferimento SWIFT - 75,00</li>
                    </ul>
                    <li>Rimborsi in ritardo o mancanti (se applicabile):</li>
                    <ul>
                        <li>Se non hai ancora ricevuto il rimborso, controlla nuovamente il tuo conto bancario.</li>
                        <li>Quindi contatta la tua società di carte di credito, potrebbe passare del tempo prima che il
                            rimborso venga ufficialmente contabilizzato.
                        </li>
                        <li>Se hai fatto tutto questo e non hai ancora ricevuto il rimborso, contattaci a</li>
                    </ul>
                </ul>
            </>]

        },
        {
            title: "PREZZI & VINCITE",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. Le informazioni personali del giocatore utilizzate per registrarsi al casinò saranno
                        utilizzate per i seguenti scopi:
                    </li>
                    <ul>
                        <li>Per determinare la validità dell'identità del giocatore e verificare l'attività a nome di
                            quest'ultimo.
                        </li>
                        <li>Nel fornire informazioni a terzi per riscuotere eventuali somme di denaro dovute al casinò
                            da parte del giocatore.
                        </li>
                        <li>Quando la legge lo richiede, per segnalare alle autorità competenti per il rilevamento e la
                            prevenzione di crimini.
                        </li>
                    </ul>

                </ul>
            </>]

        },
        {
            title: "USO IMPROPRIO DEL CONTO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. Il casinò si riserva il diritto di sospendere il tuo account in attesa di indagine nel
                        caso in cui il casinò abbia motivi ragionevoli per sospettare che tu sia stato coinvolto in
                        qualsiasi forma di collusione, sindacazione o frode durante l'uso della piattaforma del casinò,
                        sito web e/o sistemi. Ci riserviamo inoltre il diritto di trattenere l'intero o parte del saldo
                        del tuo account, chiudere definitivamente il tuo account, nonché recuperare dal tuo account
                        l'importo di eventuali vincite, pagamenti o bonus che sono stati influenzati da una delle
                        seguenti attività:
                    </li>
                    <li>11.2. Collusione: Lo sforzo congiunto di due o più individui per ottenere un vantaggio ingiusto
                        sul software e sugli altri giocatori attraverso la raccolta di fondi per ottenere un aumento
                        innaturale del bankroll disponibile attraverso molteplici premi bonus (similmente al
                        multi-accounting) per beneficiare/trarre vantaggio dalla progettazione del motore di gioco. Di
                        solito questo viene fatto in un casinò per aumentare il numero totale di bonus sui depositi
                        assegnati, massimizzando così in modo innaturale la probabilità di ottenere un grosso pagamento
                        vincente alla slot (ad esempio: round bonus alla slot) mentre si assicura che il pagamento di
                        tale evento vada a uno dei membri della collusione, diminuendo effettivamente il potenziale di
                        vittoria di qualsiasi altro giocatore che utilizza il casinò.
                    </li>
                    <li>11.3. Sindacazione: Veniamo a conoscenza che tu sei membro e/o fai parte di una sindacazione,
                        club o gruppo dove i giocatori ricevono strategie di gioco vantaggiose al fine di creare scenari
                        di aspettativa positiva attraverso l'acquisizione di offerte promozionali.
                    </li>
                    <li>11.4. Sfruttamento di un difetto, lacuna o errore nei nostri o in qualsiasi software di terze
                        parti fornito per i servizi di questo accordo.
                    </li>
                    <li>11.5. Veniamo a conoscenza che sei stato ritenuto colpevole di comportamento fraudolento o
                        collusivo presso un altro business di gioco online tra cui charge-back, collusione, o attività
                        criminali.
                    </li>
                    <li>11.6. Veniamo a conoscenza che hai contestato qualsiasi o negato qualsiasi dei trasferimenti,
                        depositi e acquisti sul tuo account in modo fraudolento.
                    </li>
                    <li>11.7. Fai uso di qualsiasi software di gioco automatico come "bot", o qualsiasi tipo di
                        strumenti o sistemi di simulazione progettati per fornire un vantaggio ingiusto sul casinò.
                    </li>
                    <li>11.8. Come giocatore che fa uso di questo casinò, accetti di non essere in collusione o tentare
                        di colludere, né intendere partecipare direttamente o indirettamente a uno schema di collusione
                        che coinvolge la condivisione di vincite tra giocatori selezionati, condivisione di fondi, o
                        lavorare insieme verso obiettivi comuni mentre si beneficia di molteplici offerte di incentivi
                        all'acquisizione, a discapito di altri giocatori che non sono coinvolti nella parte in
                        collusione. Accetti anche di informare il casinò attraverso uno dei mezzi necessari se sospetti
                        un giocatore di essere in collusione, o di pianificare di colludere con altri giocatori. Accetti
                        anche di non far parte di una sindacato o gruppo che si impegna in strategie di gioco
                        vantaggiose.
                    </li>
                    <li>11.9. Indagheremo accuratamente ogni caso in cui crediamo ci possa essere qualche esistenza di
                        un elemento di attività fraudolenta, collusione, sindacazione o attività illegale. Ci riserviamo
                        inoltre il diritto di informare le nostre unità aziendali, le società di elaborazione, i
                        fornitori di pagamenti elettronici o altre istituzioni finanziarie che utilizziamo, della tua
                        identità e di qualsiasi sospetta attività illecita, fraudolenta o non corretta.
                    </li>

                    <li>11.10. Le seguenti azioni del giocatore saranno considerate una violazione dei termini e
                        condizioni del Casinò, ma non limitate a questo elenco. Le conseguenze possono includere la
                        chiusura dell'account del casinò del giocatore, la confisca delle vincite e dei saldi esistenti,
                        il rifiuto di servizi, promozioni e qualsiasi altra offerta da parte del Casinò.
                    </li>
                    <ul>
                        <li>Più di un account presso il {casino.name} Online Casino e/o più di un account per famiglia
                            e/o più di un account per dispositivo, e/o più di un account dallo stesso indirizzo IP, e/o
                            più di un account su un casinò gemellato affiliato sotto la stessa gestione
                            del {casino.name} Online Casino.
                        </li>
                        <li>Qualora in qualsiasi momento, il casinò scopra un collegamento o una connessione a un
                            account fraudolento, sospeso o rimborsato o precedentemente chiuso su qualsiasi brand
                            all'interno della rete Rival o su qualsiasi altro casinò online.
                        </li>
                        <li>Se in qualsiasi momento il casinò scopre un reclamo/disputa/critica attiva o passata su un
                            forum pubblico o blog o qualsiasi altro tipo di sito web.
                        </li>
                        <li>Se in qualsiasi momento il giocatore abusa, molesta o parla in modo inappropriato a
                            qualsiasi membro dello staff del Casinò, compresi minacce verbali e scritte o intimidazioni.
                        </li>
                        <li>Una non corrispondenza tra l'account del Casinò del giocatore e il nome sulla/e carta/e di
                            credito o metodo/i di pagamento utilizzati per effettuare depositi sull'account.
                        </li>
                        <li>Informazioni di registrazione incorrette o false.</li>
                        <li>Il giocatore sta giocando da un territorio o regione che non autorizza pienamente il gioco
                            online.
                        </li>
                        <li>Il giocatore non ha l'età legale richiesta secondo i Termini e Condizioni del Casinò.</li>
                        <li>Se il Giocatore ha permesso o consentito (intenzionalmente o non) ad un altro giocatore di
                            giocare sul suo proprio account del Casinò.
                        </li>
                        <li>Se un individuo non ha giocato nel Casinò puramente come divertimento e ha partecipato solo
                            in una capacità professionale o in associazione con un altro giocatore di un altro club o
                            gruppo o parte di esso.
                        </li>
                        <li>Se il Giocatore è stato scoperto a fare volontariamente un 'chargeback' o 'disputa' di fondi
                            depositati dalla sua carta di credito, o qualsiasi altro metodo di pagamento, nel suo
                            account del Casinò; o è stato scoperto a fare un 'chargeback' o a minacciare di farlo in
                            qualsiasi altra capacità su un casinò gemellato sulla piattaforma Rival o qualsiasi altro
                            casinò online o sito web. Questo include minacce verbali e scritte di contestare, fare
                            chargeback o interrompere transazioni.
                        </li>
                        <li>Se il Giocatore viene scoperto a barare o è stato trovato a fare schemi di scommesse
                            irregolari, o strategie di scommesse sospette o irregolari e/o il Giocatore ha instaurato un
                            sistema (cioè Martingala, macchine, computer, software o qualsiasi altra forma di
                            automazione) per proliferare un cheat progettato per far perdere ricavi al Casinò a causa di
                            questa operazione.
                        </li>
                        <li>Il giocatore è collegato a qualsiasi dipendente, ex dipendente, azienda, terzo o agenzia
                            collegata al Casinò attualmente o in passato.
                        </li>
                        <li>Il giocatore ha tentato di 'hackerare' il software o il sito web del Casinò, o ha fatto
                            qualsiasi modifica ad esso in qualsiasi modo non specificamente consentito dal Casinò.
                        </li>
                    </ul>
                    <li>11.11 I giocatori che scelgono di effettuare un deposito utilizzando criptovalute devono assicurarsi che il token/coin sia arrivato e sia stato confermato sulla blockchain all'indirizzo del portafoglio crittografico designato fornito durante il processo di deposito. Se un deposito viene effettuato sul portafoglio ma successivamente viene 'annullato' o annullato, sarà considerato un'attività fraudolenta. Di conseguenza, qualsiasi richiesta di prelievo precedente, in corso o futura effettuata dal giocatore sarà anch'essa considerata nulla.</li>
                </ul>
            </>]

        },
        {
            title: "TERMINI E CONDIZIONI PER IL RIFERIMENTO AD UN AMICO",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. I termini e condizioni generali si applicano al programma "porta un amico" e a tutti gli altri programmi offerti da {casino.name} Online Casino. Inviando tutte le informazioni richieste nel programma "porta un amico", sia tu che la parte referenziata (persona a cui ti riferisci) acconsentite a tutti i termini di questo programma e la parte referenziata ha acconsentito che i suoi dati siano forniti da te alla parte referente. Inviando queste informazioni, la parte referenziata acconsente a ricevere un'email da {casino.name} Online Casino che le chiede di iscriversi per un account giocatore reale. Inserendo informazioni nel programma "porta un amico", la parte referenziata è pienamente consapevole che la parte referente ha inviato i loro dettagli in buona fede.</li>
                    <li>12.2. Le persone che utilizzano lo stesso computer (che si registrano dallo stesso indirizzo IP) non potranno partecipare al bonus "porta un amico" per motivi di sicurezza. I giocatori referenziati non devono essere membri esistenti di {casino.name} Online Casino. L'indirizzo email fornito dalla parte referente non deve essere associato ad alcun giocatore attuale di {casino.name} Online Casino né deve essere lo stesso email del membro referente.</li>
                    <li>12.3. Il membro referente dovrà fornire specifiche informazioni sul suo amico referenziato; queste informazioni includono, ma non si limitano a, il loro nome, cognome e indirizzo email. Dopo che tutte le informazioni richieste sono state fornite, inviate e poi verificate da {casino.name} Online Casino, solo allora il bonus sarà fornito sia ai membri referenti che a quelli referenziati.</li>
                    <li>12.4. Per qualificarsi per il bonus "porta un amico", la parte referenziata deve prima iscriversi per un account con soldi veri e depositare un minimo di 25 $,€,£ in quell'account. Sia l'amico referente che l'amico referenziato riceveranno il loro bonus solo dopo che l'amico referenziato ha depositato 25 $,€,£ nel loro account giocatore reale e deve attendere 48 ore prima di ricevere il bonus nei loro account giocatore reale.</li>

                </ul>
            </>]

        },]
}

//rival version of terms (5.2 different)
const termsEn = {
    title: "Terms and conditions",
    content: ["Playing at a casino, whether for fun play or real play, constitutes an agreement between the player and the Online Casino and its management, in that the player be bound by the following terms and conditions contained herein. The player accepts that this is a binding agreement between the player and the casino and is the sole agreement governing the player's relationship with the casino, to the exclusion of all other agreements, statements, and representations.",
        "Each clause contained in these terms and conditions shall be separate and severable from each of the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect."],

    sections: [{
        title: "Definitions",
        content: [<>The Player: <br/> Any user of the software and / or games and / or promotions offered by the
            Casino.</>, <>The Casino:<br/> {casino.name} Online Casino, including all software and content and
            services rendered on {casino.name} and associated URLs.</>, <>Service:<br/>Services, support,
            offers and assistance offered by The Casino to its users. </>, <>Credits:<br/>Amount of money on the
            user's account balance; Amount of game chips/money available to use. 1 Credit = 1€, 1£, 1$, 1AUD, 10ZAR
            (approximately).</>, <>We/Us/Our:<br/>The operators of the Casino Website and/or its group
            companies;</>, <>Player/s:<br/>Player and/or a Guest Player and/or a Dormant Player and/or a Closed
            Account Player;</>, <>Real Player:<br/>A Player who utilizes his own money to place wagers at the
            Casino;</>, <>Wagering Requirement:<br/>The number of times a sum of credits has to be wagered, staked
            or bet before the balance is eligible for withdrawal. Also known as Rollover requirement.</>]
    }, {
        title: "Disclaimer",
        content: ["Playing at the casino, whether for fun play or real play constitutes an agreement between the player and the Casino that the player be bound by the following terms and conditions contained herein. By registering, opening an account and / or playing at " + casino.name + " Online Casino, the player hereby agrees that they have:",
            "1. Fully read and understood the terms and conditions written below and",
            "2. Have fully accepted the terms and conditions and will abide by them in all dealings with the Casino and / or the Casino software.",
            "If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect. The Terms and Conditions outlined herein supersede and overwrite any other terms offered by the Casino.",
            "The Casino is registered, licensed and regulated by the Government of Curacao for the purpose of operating an online casino."]
    }, {
        title: "General promotion terms and conditions",
        content: ["1.1. Playing at " + casino.name + " Online Casino is restricted to adults only. Players must be 25 years of age or older to play. Age restriction may change according to local national law, and it is the responsibility of the player to check for the minimum age allowed in their country. The Casino reserves the right to require age verification from the player at any time.",
            "1.2. The player accepts that the Casino may change, add or subtract from the terms and conditions at any time without previous warning, and it is the responsibility of the player to periodically check the terms and conditions written here for changes that may affect them. Lack of knowledge of said terms and conditions does not constitute a valid reason to any violation of the rules and conditions set here, from the moment of opening a new account in casino.",
            "1.3. All decisions made by the Casino are final, and will comply with the terms and conditions set out in this document. By registering in the Casino, the player agrees to accept any and all decisions made by the Casino as final and deciding.",
            "1.4. The Casino will bear no liability or responsibility, either directly or indirectly, as regarding the player's legal right to play at an online casino with real money. This includes any employees, licensees, distributors, wholesalers, affiliates, subsidiaries, advertising, promotion or other agencies, media partners, agents or retailers claim or been seen to claim authorization to make any such representations or warranties. It is the sole responsibility of the player to check the local law and abide by it responsibly. The Casino will carry no liability for any infringement of the law by the player.",
            "1.5. Any games, services or promotions offered by the Casino are purely meant for entertainment purposes, and not as a financial system. An option exists to play with fake credits that do not equal real money. Playing for real money is the sole decision of the player, and in doing so, the player fully accepts any risks involved by playing with real credits.",
            "1.6. Any fees, taxes, transfer fees or other financial responsibilities or consequences related to using the games and / or services offered by the Casino are the responsibility of the player and the player alone.",
            "1.7. The player will not hold the Casino nor any agency, employee, partner or media related to the Casino liable for any damages, costs, expenses or liabilities incurred by using and / or accepting services from the Casino nor for any activity of the player related to the Casino.",
            "1.8. The Casino will not bear any responsibility or liability for any technical errors occurring in the software which are related to the player's internet connection, personal computer software or personal computer hardware. Any interruptions in internet service are the responsibility of the player and the Casino will not compensate nor bear any liability for interruptions caused by a technical issue on the player's side, including but not limited to internet disconnections. Any other technical faults must be submitted in writing and include a screenshot so that the Casino may verify its authenticity.",
            "1.9. The player hereby agrees that the registered identity to their casino account will be the only person logging into said account and using and/or playing with the Casino software or website. Any infringement of said agreement (i.e. use of the account by a different person) will be considered a breach of terms and conditions and grounds for termination of the account and forfeiture of any winnings. The account is not transferable in any way nor can the user be changed for the account. Any new users must open a new account or be in violation of terms and conditions.",
            "1.10. The Casino reserves the right to change, add or subtract from the games and / or services it offers, at any time, without prior announcement or update. Terms and conditions may be changed or altered and the player accepts this as part of the prerogative of the website to do so when and if necessary.",
            "1.11. The player further indisputably agrees to indemnify " + casino.name + " Online Casino, its employees, officers, directors and any and all associated or affiliated persons or organizations against any and all costs, expenses, liabilities and damages arising from any legal or other action taken either by or against the Player arising from any and all action within the Casino and/or any of its employees, officers, directors and any and all associated or affiliated persons or organizations. This occurrence shall include (but not limited to) any legal implication that might arise as a result of the criminalization of online/Internet wagering within the Players jurisdiction, territory or country.",
            "1.12. The player accepts any and all game results shown by the Casino software or website as true. Any disputes regarding the outcome of games played will be decided by results from the game server, and these will determine the final decision of the Casino. If for any reason the player is not totally satisfied with the terms and conditions laid out before them, then the player is within their rights to discontinue playing and remove the software from his/her computer. Furthermore, a player who is unsatisfied with his/her personal gaming experience at the Casino and wishes to close their account, bears responsibly for manually closing or freezing said account from within the Casino software and to remove any software associated with the casino from their computer or mobile devices. Legal age restrictions and strict adherences to the policies of age as agreed by the jurisdiction of the said territories to comply with the said law of that territory are full enforced by the Casino. This is clearly defined by no player under the age of 25 years of age is permitted to play at the casino regardless of gender.",
            "1.13. In the event of a complaint or grievance or technical complaint or dispute in the Casino, the complaint must be submitted in writing within five (5) days of the event. If the complaint is received after this time, the casino reserves the right not to address the matter. However, if there are extenuating circumstances; for example: A Player is incapacitated or otherwise unavailable for comment, we will address these issues on a case by case basis but reserve the right to invoke the five (5) day rule above.",
            "1.14. The player accepts all decisions made by the Casino as final.",
            "1.15. Any funds remaining in the balance of an account at the time of closure cannot be withdrawn. This included all and any pending withdrawals."]
    }, {
        title: "Violations of terms and conditions",
        content: ["2.1. The following actions by the player will be considered a violation of the terms and conditions of the Casino, but not limited to this list. Consequences may include termination of the player's casino account, confiscation of winnings and existing balances, denial of services, promotions and any other offers by the Casino.",
            <ul>
                <li>More than one account at {casino.name} Online Casino and/or more than one account per
                    household and/or more than one account per device, and/or more than one account from the same IP
                    address, and/ or more than one account on an affiliated sister casino under the same management
                    as {casino.name} Online Casino.
                </li>
                <li>Should at any time, the casino discover a linkage or connection to a fraudulent, suspended or
                    refunded or previously closed account on any brand within the Rival network or any other online
                    casino.
                </li>
                <li>Should at any time the casino discover an active or past complaint/dispute/criticism on a public
                    forum or blog or any other type of website.
                </li>
                <li>Should at any time the player abuse, harass or speak inappropriately to any of the Casino staff,
                    including verbal and written threats or intimidation.
                </li>
                <li>A mismatch on the Player's Casino account not matching the name on the credit card(s) or payment
                    method(s) used to make deposits on the account.
                </li>
                <li>Incorrect or false registration information.</li>
                <li>The player is playing from a territory or region not fully authorized to allow online gaming.
                </li>
                <li>The player is not of the required legal age according to the Casino Terms and Conditions.</li>
                <li>If the Player has allowed or permitted (intentionally or unintentionally) another player to play
                    on their own Casino account.
                </li>
                <li>If an individual has not played in the Casino purely as an entertainment and has only
                    participated in a professional capacity or in association with another player from another club
                    or group or part thereof.
                </li>
                <li>If the Player has been found deliberately making a 'chargeback' or 'dispute' of funds deposited
                    from their credit card, or any other payment method, into their Casino account; or have been
                    found to have made a 'chargeback' or threatened to do so in any other capacity on a sister
                    casino on the Rival platform or any other online casino or website. This includes verbal and
                    written threats to dispute, chargeback or stop transaction.
                </li>
                <li>If the Player is discovered to be cheating or has been found making irregular betting patterns,
                    or suspicious or irregular betting strategies and or the Player has instigated a system (i.e.
                    Martingale, machines, computers, software or any other form of automation) to proliferate a
                    cheat designed for the Casino to lose revenue due to this operation.
                </li>
                <li>The player is linked to any employee, ex-employee, company, third party or agency connected to
                    the Casino currently or in the past.
                </li>
                <li>The player has made any attempts to 'hack' the Casino software or website, or made any changes
                    to it in any way not specifically allowed by the Casino.
                </li>
            </ul>,
            "2.2. Should the casino learn any of the above rulings listed be affected by the player at any other web-based casino, " + casino.name + " Online Casino will act accordingly.",
            "2.3. In acceptance of said rulings and any other conditions outlined herein, the player agrees to any promotional or advertising events upon acceptance of any prize and/or winnings that have been accrued from " + casino.name + " Online Casino and thereby endorses the use of that players username, country or real first name for the said practice without any compensation or payment to the player. Example: Should a Player win a large Jackpot and the Casino wishes to advertise the win, the Casino withholds the right to use the player's username, country and/or real first name and post the win on the Casino site, a magazine, another website or any other media of the Casino's choosing.",
            "2.4. Players who choose to make a deposit using cryptocurrency must ensure that the token/coin has successfully arrived and been confirmed on the blockchain to the designated crypto wallet address provided during the deposit process. If a deposit is made to the wallet but subsequently gets 'voided' or reversed, it will be regarded as fraudulent activity. Consequently, any previous, ongoing, or future withdrawal requests made by the player will also be deemed void"]
    }, {
        title: "General terms and conditions",
        content: ["3.1. The Casino is committed to offering fair and honest games and confirms the software and all games offered by " + casino.name + " Online Casino rely on an audited and validated Random Number Generator (RNG) by a third party ensuring that all games are fair and honest.",
            "3.2. The Casino reserves the right to modify the balance on a user's account to redeem sums owed to the Casino.",
            "3.3. The user agrees to pay all sums due to " + casino.name + " Online Casino and to never try to re-attribute, deny, revoke, dispute, chargeback, block, hold or cancel any of these payments.",
            "3.4. If the user has deposited or withdrawn funds using an E-Wallet such as MoneyBookers, Neteller or EcoCard, the Casino may request copies of other transactions showing that specific E-Wallet in use.",
            "3.5. Any illegal or criminal activity made by the player will result in immediate termination of the Casino account and the Casino reserves the right to inform the proper authorities and other online casinos and merchants of these actions with full disclosure.",
            "3.6. The Casino reserves the right to change any of its offered services at any time, including a temporary unavailability of customer support, technical support or any other services given to the player.",
            "3.7. In the event of technical failure or computer glitch, all bets in play may be considered null and void. The Casino cannot be held responsible for losses, wins or other issues generated by a technical failure or glitch in any way or form.",
            "3.8. Should a player abstain from logging into their Casino account for a period of 90 days or more or the player 90 days without making a single bet or wager, the Casino will be entitled to any balance in that player's account and the sum forfeit. Should the player wish to withdraw this amount after this period, we will examine each situation on a case by case basis but reserve the right to invoke the above 3 month rule."]
    }, {
        title: "Promotions and bonus terms and conditions",
        content: [
            "4.1. All and any free bonuses and deposit bonuses cannot be withdrawn from the Casino, and are meant to enhance game time only. Upon withdrawal, the bonus amounts will be deducted by the Casino before the withdrawal is sent.",
            "4.2. All bonus money will be removed at the point of cash out, bonus money is money that can be used to help generate winnings but cannot be withdrawn as part of the winnings. The bonus will be treated as 'Non-Withdrawable' and will not be reissued to your account but deducted from the winnings by the casinos withdrawal manager during the payout process. Cashback money is also 'Non-Withdrawable' and the casino reserves the right to deduct cashback money from a players' pending withdrawal.",
            "4.3. Bonuses, unless specifically specified by the Casino beforehand, carry a wagering requirement that must be completed before a player can withdraw.",
            "4.4. All cashback promotions carry a wagering requirement of 1X. Players cannot withdraw a cashback bonus until the sum of the cashback is wagered in full. All cashback money is restricted to a maximum withdrawal limit of 10X the cashback amount. This includes cashback awarded as 'gifts', 'comps' or 'free chips'. The Casino reserves the right to deduct Cashback money from a pending withdrawal",
            "4.5. All cashback money won from a Tournament prize, Raffle prize or Special Promotion have a maximum withdrawal limit of 2X the cashback bonus and carry a wagering requirement of 1X. All bonuses of 200% or more, cashback insurance of 75% or more will be considered Special Promotions. The Casino reserves the right to deduct Cashback money from a pending withdrawal. Players who have a pending withdrawal or balance of over 100 $/€/£/ do not qualify to receive Tournament prizes or to receive cash from a Raffle or cashback from any ongoing Cashback promotion or any other special promotion offered by the Promotions Team.",
            "4.5.1 Special Bonus - all bonuses of 200% or more, cashback bonuses of 100% or more will be considered Special Bonuses",
            "4.6. It is the responsibility of the player to be aware of the details of the bonus they request or is given to them, including the wagering requirement, restricted games and any other details, terms and requirements associated with said bonus.",
            "4.7. Players choosing not to play with bonuses, must state so before starting to play with a bonus. If a player does not want the bonus that has been deposited into their account by the casino, then they are entitled to request a casino customer support representative reverse the bonus out of their casino account, by way of email, telephone call or live Chat. If a bonus is received and a wager is made (of any amount), the bonus will have to be played all the way through, until the balance is zero or the wagering requirement is complete. If the player has not begun to play, support will remove the bonus should they ask for it.",
            "4.8. Free bonuses (Comps, Free Chips, converted Comp Points or any other non-deposit bonuses) are playable bonuses awarded to players so they may play free of charge. Free bonuses have a different wagering requirement than other bonuses, and have a fixed withdrawal limit of no more than 50 Credits (50 $,€,£,AUD, or 500 ZAR). Any remaining or extra balance is 'Non-Withdrawable' and is to be cleared from a player's balance. Each player is allowed to withdraw winnings from a free bonus only once during his/her gaming lifetime unless specifically approved by the casino manager.",
            "4.9. A player who has never made a deposit and wishes to withdraw a free chip win, is required to first make one successful minimum deposit of 15.00 ($,€,£,AUD, or 150 ZAR). This first deposit amount is not allowed to be withdrawn or added to an existing pending withdrawal. If added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. A players who has previously made a successful deposit and is now wishing to withdraw a free chip win, is required to have deposited within the last 30 days in order for the withdrawal to be approved and the deposit cannot be added to an existing pending withdrawal. If added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. Free Bonuses (Free Chips) have a wagering requirement of 20X to 60X unless otherwise specified",
            "4.10. All promotions offered by the Casino are only valid for the period specified in the promotional offer itself which is posted on the Casino website and/or any other communication provided by the Casino itself.",
            "4.11. A bonus equal to or greater than 200% that has been claimed with any deposit, will be subject to a max cashout of 10x the deposit amount.",
            "4.12. A free bonus player (a player who plays only free bonuses, and didn't make a deposit yet) can withdraw winnings when playing his first three free bonuses on his account, during the first 30 days after account registration After three bonuses played or after 30 days since registration date, the funds received from a free bonus will be considered not cashable and will be deducted from the balance or from the withdrawal.",
            "4.13. In case a player has finished / played down,  his wager requirement , the casino holds the right to adjust balance to the maximum withdrawal amount allowed and clear any extra balance. In cases where a player is not eligible to make a withdrawal , the casino holds the right to clear any winnings and balance once wager is met."
        ]

    }, {
        title: "Withdrawal terms and conditions",
        content: [
            "5.1. All Deposits of 249 USD/EURO/GBP/AUS or less have a max withdrawal of 10X the deposit for that specific play session (all other currencies pegged to USD). Once a withdrawal is approved, the casino applies the 10X rule and corrects the withdrawal to accurately reflect the 10X rule. The remaining funds are deemed 'Non-Withdrawable' and are cleared from the player's balance. Deposit bonuses used during a play session that results in a pending withdrawal request, are also 'Non-Withdrawable' and can be deducted from that particular pending withdrawal.",
            "5.2. Customers can submit their withdrawal request at anytime. The approval time may take one - fourteen (1 - 14) working days starting the date authorization process was completed successfully. Processing a pending withdrawal requires authorization from the Fraud department as well.",
            "5.3. Any and all bonuses redeemed by the player cannot be withdrawn or redeemed from the Casino, and will be removed from the player's winnings upon processing of the withdrawal.",
            "5.4. All withdrawals (Wire, Netteller, Direct-to-Card etc) are subject to a 10-40 $,€,£ transfer fee. Fees are based on location and are determined by our payment processor and are the responsibility of the individual player to absorb.",
            "5.5. For a withdrawal to be approved, the player will be asked to submit certain documentation and information in order to verify the account. Failure to comply or failure to submit said documents and information 30 days from the time the withdrawal was requested, will result in the withdrawal being cancelled. It is the responsibility of the individual player to send the required documentation. Withdrawal approval depends on all conditions outlined and the verification of all required documents.",
            "5.6. A player may not withdraw winnings as long as there is still a wagering requirement attached to their balance. They must complete the wagering requirements before making a withdrawal request, or risk having the withdrawal denied.",
            "5.7. The casino has the right to refuse or withhold a bonus or winnings in the event of a deposit being cancelled, returned or denied by any payment service or processor at anytime.",
            "5.8. If the player has wagered on restricted games with a bonus that does not allow said games, the Casino reserves the right to cancel any withdrawal and confiscate any winnings. Before starting to play with a deposit bonus or free chip, it is the responsibility of the player to check and understand what are the restrictions of that particular bonus or chip.",
            "5.9. The minimum amount for a withdrawal is 50 €/$/£/AUD or 500 ZAR.",
            "5.10. Any and all deposits must be wagered at least once (1X) the sum of the deposit in order to qualify for a withdrawal. A withdrawal cannot be approved unless a minimum wager of 1X the deposit is first met. Deposits made as part of promotions are subject to that specific promotion's Terms and Conditions and wagering requirement.",
            "5.11. By depositing funds into your account, you direct us and we agree to hold them, along with any winnings, for the sole and specific purpose of using them (i) to place any gaming wagers; and (ii) to settle any fees or charges that you might incur in connection with the use of our services. We reserve the right to charge a fee of 3% - 6.5% for the processing of Credit Card deposits.",
            "5.12. A player that didn't add funds to his account for one month-long or more (30 days), will be considered a free bonus player and will not be eligible to withdraw winnings from free bonuses or cash granted to him by the casino. The possibility of withdrawing winnings from free bonuses is reserved for our active players only.",
            "5.13. Mixing a balance of a free-play with a balance of real-play is not allowed and is under the player's responsibility. If a deposit was made on top of a balance from a free chip, any playable balance the player had prior to depositing to play for real, will be considered free play, therefore, under the rules of free play."
        ]
    }, {
        title: "Privacy agreement",
        content: [<>Disclaimer:<br/>6.1. The Casino is obligated to keep all personal information given by the
            player safe and secure. Any details submitted by the player to the Casino at any point, will remain
            solely with the Casino and will not be given out to any third party or
            agency.</>, "6.2. It is the sole responsibility of the player to keep all user names and passwords related to their casino account safe and secure. The Casino will not carry liability for any use of the account details by a person who is not the originally registered player.",
            "6.3. The Casino retains the right to use the player's name as part of promotional events (e.g. publish a big win by said player), without any compensation or payment made to said player and will not be required to seek preapproval by the player.",
            "6.4. The Terms and Conditions outlined define a final and exclusive understanding and agreement between You (the Player) and the Casino and complete all previous agreements, representations and understandings between You and " + casino.name + " Online Casino with regards to your participation in the Casino.",
            "6.5. The Casino reserves the right to withhold depositing or withdraw any credits or bonuses at our discretion. The Casino reserves the right to withdraw or amend the terms of this program at any given time or without prior notice",
            "6.6. This offer is not applicable for employees, partners, suppliers or any other party with a business and/or professional relation to " + casino.name + " Online Casino. Intellectual property",
            "6.7. Via this contract, The Casino grants the user a revocable, nonexclusive and non transferable right, to use its website, its software and/or its service as well as all linked services. This right also extends to the royalties and all the rights of intellectual ownership being attached to it.",
            "6.8. The user recognizes and accepts that the copyright, the mark and all other rights of intellectual ownership being exerted on any material and/or provided contents and belonging to the website and/or the software of casino, remain our strict property. The user can use the above mentioned material only in agreement with these terms and conditions.",
            "6.9. " + casino.name + " Online Casino is the trade name of the Casino, and the Player has no rights to use or employ such a term, or any other terms, graphics, text, concepts or methodologies, by participating in the Web Site and the material contained therein.",
            "6.10. The user recognizes and accepts the fact that the material and the contents reproduced on the website of the Casino are placed at their disposal only as non commercial titles. This material and these contents should be downloaded on the hard disk of a computer only to serve this goal. If any of the materials of the Casino games are interfered with mutilated, forged or damaged they become void and unusable. Regardless of how they are obtained whether physically or electronically. Material liability of the Casino games that contains any error is only limited to a replacement of the same material.",
            "6.11. Any other use is strictly prohibited as well as the copy, falsification, the reproduction, the publication, the transmission and the distribution of work derived from this material and these contents.",
            "6.12. Possible criminal or suspicious actions may be reported to the appropriate authority. When called upon to do so Players may need to produce certain verification documents when transacting with the casino in withdrawals or deposits. Proof of such could be clear photo identification, proof of address, credit card copies and purchase declaration forms. This requirement is to validate the security of our players and in compliance with Gaming regulations.",
            "6.13. This contract is regarded as duly signed and accepted by the two parties: The Casino and the User.",
            "6.14. The Terms and Conditions outlined define a final and exclusive understanding and agreement between You (the Player) and " + casino.name + " Online Casino and complete all previous agreements, representations and understandings between You and " + casino.name + " Online Casino with regards to your participation in the Casino."]
    }, {
        title: "Refer-a-friend terms and conditions",
        content: ["7.1. The general terms and conditions apply to the refer-a-friend program and all other programs offered by " + casino.name + " Online Casino. By submitting all required information into the refer-a-friend program, both you and the referred party (person being referred) consent to all terms of this program and the referred party has consented to their data being supplied by you the referring party. By submitting this information, the referred party consents to having an email sent to him/her by " + casino.name + " Online Casino requesting him/her to sign up for a real player account. By submitting information into the refer-a-friend program, the referred party is fully aware that the referring party has submitted their details in good faith.",
            "7.2. Persons utilizing the same computer (registering from the same IP address) will not be permitted to partake in the refer-a-friend bonus for security purposes. Referred players must not be existing members of " + casino.name + " Online Casino. The email address provided by the referring party must not be associated with any current player of " + casino.name + " Online Casino nor shall it be the same email as the referring member.",
            "7.3. The referring member will be required to fill out specific information about his/her referred friend; this information includes, but is not limited to their given name, surname and email address. After all required information has been provided, submitted and then verified by " + casino.name + " Online Casino, then and only then shall the bonus be supplied to both the referring and referred members.",
            "7.4. In order to qualify for the refer-a-friend bonus, the referred party must first sign up for a real money account and deposit a minimum of 25 $,€,£ into that account. Both the referring friend and the referred friend will receive their bonus only after the referred friend has deposited 25 $,€,£ into their real player account and must allow for 48 hours before receiving the bonus in their real player accounts."]
    }, {
        title: "Refund policy",
        content: ["Thanks for opening an account with us and depositing funds into your account at our websites.",
            "We offer the option of a refund on all l deposits made on our websites. However, certain conditions have to be met. We invite you to read the conditions explained in more detail below.",
            "Our returns policy is simple, straight forward, and we will be processed immediately.",
            "However, there is a limited window of time as indicated:",
            <ul>
                <li>Within 24 hours of the original deposit.</li>
                <li>As required at the time of opening the account, the compliance must be completed and approved by
                    our compliance department.
                </li>
                <li>Games malfunction - Screenshot required.</li>
                <li>Unable to play credits - Balance unplayed.</li>
                <li>Wrong charges - statement required.</li>
            </ul>,
            <>Processing Fee<br/>There is a fee involved in processing and issuing your refund. The different fees
                (at your currency) are as follows:</>,
            <ul>
                <li>Paper Check - 55.00</li>
                <li>SEPA wire - 20.00</li>
                <li>SWIFT wire - 75.00</li>
            </ul>,
            "Late or missing refunds (if applicable):",
            <ul>
                <li>If you haven’t received your refund yet, first check your bank account again.</li>
                <li>Then contact your credit card company, it may take some time before your refund is officially
                    posted.
                </li>
                <li>If you’ve done all this and you still have not received your refund yet, please contact us
                    at <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a></li>
            </ul>]
    }]
}
const termsFr = {
    title: "Termes et conditions",
    content: ["Jouer dans un casino, que ce soit pour vous amuser ou pour le jeu réel, constitue un accord entre le joueur et le casino en ligne, le joueur est lié par les termes et conditions suivants le contenu dans les termes et conditions. Le joueur accepte qu'il s'agit d'un accord entre le joueur et le casino et que c'est le seul accord régissant la relation du joueur avec le casino, à l'exclusion de tous les autres accords, déclarations et représentations.",
        "Chaque clause contenue dans ces termes et conditions sera séparée et séparable de chacune des autres. Si une clause est jugée nulle, invalide ou inapplicable pour quelque raison que ce soit, les autres termes et conditions resteront pleinement en vigueur."],
    sections: [{
        title: "Définitions",
        content: [
            <>Joueur:<br/>Tout utilisateur du logiciel et / ou des jeux et / ou des promotions offertes par le
                Casino.</>,
            <>Casino:<br/>Casino{casino.name}, inclus tous les logiciels, le contenu et les services rendus
                sur www.{window.location.host.replace("www.", "")} et les URL associés</>,
            <>Services:<br/>Services, support, offres et assistance offerte par le casino pour ses utilisateurs.</>,
            <>Crédits:<br/>Montant de l'argent sur le solde du compte de l'utilisateur; Montant des jetons / argent
                disponible à utiliser. 1 crédit = 1 €, 1 £, 1 $, 1AUD, 10ZAR (environ).</>,
            <>Nous:<br/>Les opérateurs du site Web du Casino et / ou des sociétés de son groupe;</>,
            <>Joueurs:<br/>Un Joueur et / ou un joueur invité et / ou un Joueur inactif et / ou un joueur dont le
                Compte est fermé;</>,
            <>Joueur réel:<br/>Un joueur qui utilise son propre argent pour placer des paris au Casino;</>,
            <>Exigences de Mises:<br/>Le nombre de fois qu'une somme de crédits doit être misée, jalonnée ou pariée
                avant que le solde soit admissible pour le retrait. Aussi connu comme exigence de roulement.</>]
    }, {
        title: "Décharge",
        content: ["Jouer au casino, que ce soit en mode fun ou en mode réel, constitue un accord entre le joueur et le casino que le joueur sera lié par les termes et conditions suivants contenus dans ce document. En s'inscrivant, en ouvrant un compte et / ou en jouant sur Casino " + casino.name + ", le joueur accepte qu'il a:",
            <ol>
                <li>Entièrement lu et compris les termes et conditions écrits ci-dessous;</li>
                <li>Pleinement accepté les termes et conditions et qu'il s'engage à les respecter dans toutes les
                    relations avec le Casino et / ou le logiciel du Casino.
                </li>
            </ol>,
            "Si une clause est jugée nulle, invalide ou inapplicable pour une raison quelconque, les modalités et les conditions restantes demeureront de plein effet. Les termes et conditions décrits annulent et remplacent toutes les autres conditions offertes par le Casino.",
            "Le Casino est inscrit, autorisé et réglementé par le gouvernement de Curaçao à des fins d'exploitation d'un casino en ligne."]
    }, {
        title: "Termes & conditions généraux - promotions",
        content: [
            "1.1. Jouer sur Casino " + casino.name + " est limité aux adultes seulement. Les joueurs doivent avoir 25 ans ou plus pour jouer. La restriction d'âge peut changer selon le droit national local, et il relève de la responsabilité du joueur de vérifier l'âge minimum autorisé dans son pays. Le Casino se réserve le droit d'exiger la vérification de l'âge du joueur à tout moment.",
            "1.2. Le joueur accepte que le Casino puisse modifier, ajouter ou soustraire des termes et conditions à tout moment et sans avertissement préalable, et il est de la responsabilité du joueur de vérifier périodiquement les conditions écrites concernant des changements susceptibles de les affecter. Le manque de connaissance desdits termes et conditions ne constitue pas une raison valable pour toute violation des règles et conditions énoncées ici, dès le moment de l'ouverture d'un nouveau compte dans le casino.",
            "1.3. Toutes les décisions prises par le Casino sont définitives et seront conformes aux termes et conditions énoncées dans le présent document. En s'inscrivant sur le Casino, le joueur s'engage à accepter toutes les décisions prises par le Casino comme définitives et décisives.",
            "1.4. Le Casino ne portera aucune responsabilité, qu'elle soit directe ou indirecte, en matière du droit du joueur à jouer sur un casino en ligne avec de l'argent réel. Ceci inclut tout les employés, licenciés, distributeurs, grossistes, sociétés affiliées, filiales, publicité, promotion ou autres agences, partenaires médias, agents ou détaillants prétendant ou ayant prétention à avoir l'autorisation de faire une quelconque déclaration ou garantie. Il est de la seule responsabilité du joueur de vérifier la législation locale et de se conformer de façon responsable. Le Casino ne portera aucune responsabilité pour toute violation de la loi commise par le joueur.",
            "1.5. Les jeux, les services ou les promotions offertes par le Casino sont purement destinés à des fins de divertissement, et non pas comme un système financier. Il existe une option pour jouer avec de faux crédits n'ayant aucune équivalence avec de l'argent réel. Jouer avec de l'argent réel relève de la seule décision du joueur, et, ce faisant, le joueur accepte pleinement les risques encourus en jouant de vrais crédits.",
            "1.6. Les impôts, taxes, frais de transfert ou autres responsabilités financières ou conséquences liées à l'utilisation des jeux et / ou services offerts par le Casino sont à la charge du joueur et du joueur seul.",
            "1.7. Chaque dépôt effectué en Carte Bancaire est soumis à des frais de traitement de 5%. Les frais seront ajoutés au montant du dépôt facturé.",
            "1.8. Le joueur ne tiendra pas le Casino, ni aucun organisme, employé, partenaire ou médias liés au Casino pour responsables des dommages, coûts, dépenses ou passifs encourus par l'utilisation et / ou l'acceptation des services du Casino ni pour toute activité du joueur liés au Casino.",
            "1.9. Le Casino n'assume aucune responsabilité pour les erreurs techniques survenues dans le logiciel liées à la connexion Internet du joueur, le logiciel de l'ordinateur personnel ou le matériel de l'ordinateur personnel. Les interruptions de service Internet sont la responsabilité du joueur et le Casino ne compensera pas, ni ne supportera aucune responsabilité pour les interruptions causées par un problème technique du côté du joueur, comprises mais non limitées à des déconnexions Internet. Les autres défauts techniques doivent être soumis par écrit et comprennent une capture d'écran de sorte que le Casino peut vérifier son authenticité.",
            "1.10. Le joueur accepte que l'identité enregistrée sur son compte de casino sera la seule personne qui se connecte sur ledit compte et l'utilise et / ou joue avec le logiciel ou site web Casino. Toute violation de ladite convention (c'est à dire l'utilisation du compte par une autre personne) sera considérée comme une violation des termes et conditions et donc comme motif de résiliation du compte et de la confiscation de tous les gains. Le compte n'est transférable en aucune façon, et le nom d'utilisateur ne peut être changé pour le compte. Les nouveaux utilisateurs doivent ouvrir un nouveau compte ou seront considérés comme violant les termes et conditions.",
            "1.11. Le Casino se réserve le droit de modifier, ajouter ou soustraire des jeux et / ou services qu'elle offre, à tout moment, sans préavis ou mise à jour antérieure. Les termes et conditions peuvent être modifiés et le joueur accepte cela comme faisant partie de la prérogative du site de le faire quand et si nécessaire.",
            "1.12. Le joueur s'engage en outre à indemniser sans contestation Casino " + casino.name + ", ses employés, dirigeants, administrateurs et tout associé ou personnes ou organisations contre tous les coûts, dépenses, dettes et dommages résultant de toute action juridique ou autre prises par ou contre le Joueur découlant de toute action sur le Casino et / ou un de ses employés, dirigeants, administrateurs et toute personne ou organisation associée ou affiliée. Cet événement doit inclure (sans s'y limiter) les conséquences juridiques qui pourraient survenir à la suite de la criminalisation des paris en ligne / sur Internet de la juridiction du pays ou territoire dont le joueur est issu.",
            "1.13. Le joueur accepte tout résultat de jeux présenté par le logiciel du Casino ou site web comme vrai. Tout litige concernant la résultante des jeux joués sera décidé par les résultats à partir du serveur de jeu, et ceux-ci détermineront la décision finale du Casino. Si pour une raison quelconque le joueur n'est pas totalement satisfait des termes et conditions inscrits, alors le joueur est en droit de cesser de jouer et supprimer le logiciel de son ordinateur. En outre, un joueur n'étant pas satisfait par son expérience de jeu personnel sur le Casino et souhaitant fermer son compte, sera porté responsable pour la fermeture ou le gel manuel dudit compte à partir du logiciel du Casino ainsi que de la suppression de tout logiciel associé au casino à partir de son ordinateur ou des appareils mobiles. Les restrictions juridiques d'âge et les règles strictes d'adhésions relatives a l'âge, comme convenu par la juridiction de ces dits territoires de se conformer à ladite loi de ce territoire sont pleinement appliquées par le Casino. Il est clairement défini qu'aucun joueur de moins de 25 ans n'est autorisé à jouer sur le casino sans distinction de sexe.",
            "1.14. Dans le cas d'une plainte, d'un grief, d'une réclamation ou d'un différend technique sur le Casino, la plainte doit être soumise par écrit dans les cinq (5) jours suivants l'événement. Si la plainte est reçue après ce moment, le casino se réserve le droit de ne pas aborder la question. Cependant, s'il existe des circonstances atténuantes; Par exemple: un joueur se retrouve dans l'incapacité ou indisponible pour commenter, les questions seront abordées au cas par cas, mais la direction se réserve le droit d'invoquer les cinq (5) jours de la règle ci-dessus.",
            "1.15. Le joueur accepte toutes les décisions prises par le Casino comme définitives.",
            "1.16. Tous les fonds restants dans le solde d'un compte au moment de la fermeture ne pourront être retirés. Cela comprend également les demandes de retraits en attente."]
    }, {
        title: "Violations des conditions générales d'utilisation",
        content: ["2.1. Les actions suivantes commises par le joueur seront considérées comme des violations des termes et conditions du Casino, (mais les violations ne se limitent pas à cette liste). Les conséquences peuvent inclure la résiliation du compte casino du joueur, la confiscation des gains et des équilibres existants, le déni de services, promotions et autres offres par le casino.",
            <ul>
                <li>Plus d'un compte sur {casino.name} Online Casino et / ou plus d'un compte par ménage et /
                    ou plus d'un compte par dispositif et / ou plus d'un compte sur la même adresse IP et / ou plus
                    d'un compte sur un Casino étant sous la même gestion que {casino.name} Online Casino.
                </li>
                <li>Si à tout moment, le casino découvre un lien ou une connexion a un compte frauduleux, suspendu,
                    remboursé ou précédemment fermé sur n'importe quel casino relatif au réseau Rival ou tout autre
                    casino en ligne.
                </li>
                <li>Si à tout moment le casino découvre une plainte / litige / critique active ou passée sur un
                    forum public ou blog ou tout autre type de site Web.
                </li>
                <li>Si à tout moment le joueur abuse, harcèle ou parle de façon inappropriée à un membre du
                    personnel du Casino, incluant les menaces ou l'intimidation verbale et/ou écrite.
                </li>
                <li>Une discordance entre le nom inscrit sur le compte Casino du joueur et celui inscrit sur la/es
                    carte (s) de crédit ou méthodes de paiement utilisées pour effectuer des dépôts sur le compte.
                </li>
                <li>Des informations d'enregistrement incorrectes ou fausses.</li>
                <li>Si le joueur joue d'un territoire ou d'une région n'étant pas entièrement autorisée pour le jeu
                    en ligne.
                </li>
                <li>Si le joueur n'a pas l'âge légal requis selon les Termes et Conditions du Casino.</li>
                <li>Si le joueur a autorisé ou permis (intentionnellement ou non) a un autre joueur de jouer pour
                    son propre compte de Casino.
                </li>
                <li>Si une personne n'a pas joué sur le Casino dans un but de pur divertissement et a seulement
                    participé à titre professionnel ou en association avec un autre joueur d'un autre club ou d'un
                    groupe ou d'une partie de celui-ci.
                </li>
                <li>Si le joueur est accusé d'avoir délibérément fait un «chargeback» ou une «contestation» des
                    fonds déposés de leur carte de crédit, ou toute autre méthode de paiement, sur leur compte
                    Casino; ou accusé d'avoir fait un «chargeback» ou menacé de le faire à un autre titre sur un
                    autre casino de la plateforme Rival ou tout autre casino en ligne ou site web. Cela inclut les
                    menaces verbales et écrites pour contester, refacturation ou arrêt d'une transaction.
                </li>
                <li>Si le joueur est découvert en train de tricher ou accusé de paris irréguliers, ou de stratégies
                    de paris suspects ou irréguliers et ou si le joueur a été l'instigateur d'un système (telle que
                    la Martingale, l'utilisation de machines, ordinateurs, logiciels ou toute autre forme
                    d'automatisation) ou s'il est accusé de proliférer des méthodes de triches conçues pour que le
                    Casino perde des revenus en raison de cette opération.
                </li>
                <li>Si le joueur est lié à tout employé, ex-employé, entreprise, tiers ou organisme relié au casino
                    actuellement ou dans le passé.
                </li>
                <li>Si le joueur a fait toutes tentatives de hacking du logiciel de Casino ou site web, ou apporté
                    des modifications en aucune façon expressément autorisées par le Casino.
                </li>
            </ul>,
            "2.2. Si le casino devait apprendre qu'une des décisions ci-dessus énumérées ait été affectée par le joueur à n'importe quel autre casino en ligne, Casino " + casino.name + " agira en conséquence.",
            "2.3. Dans l'acceptation desdites décisions et toutes autres conditions décrites ici, le joueur accepte tous les événements promotionnels ou publicitaires lors de l'acceptation d'un prix et / ou des gains mis en place sur Casino " + casino.name + " et ainsi souscrit à l'utilisation de l'identifiant du joueur, pays ou prénom réel pour ladite pratique sans aucune compensation ou de paiement pour le joueur. Exemple: Si un joueur gagne un grand Jackpot et si le Casino souhaite annoncer la victoire, le Casino se réserve le droit d'utiliser et de poster le nom d'utilisateur, le pays du joueur et / ou vrai prénom sur le site de Casino, magazine, autre site ou tout autre moyen choisi par le Casino."]
    }, {
        title: "Conditions et termes généraux",
        content: ["Nous pouvons imposer des limites ou des conditions que nous, dans notre seule discrétion, estimons appropriées à n'importe quelle personne qui ouvre ou essaye d'ouvrir un compte au Casino où les comptes en question proviennent d'environnements où les ordinateurs sont partagés.",
            "3.1. Le Casino s'engage à offrir des jeux équitables et honnêtes et confirme que le logiciel et tous les jeux proposés par Casino " + casino.name + " s'appuye sur un générateur de nombre aléatoire audité et validé (RNG) par un tiers veillant à ce que tous les jeux soient justes et honnêtes.",
            "3.2. Le Casino se réserve le droit de modifier et de réajuster la balance sur le compte d'un utilisateur des sommes dues au Casino.",
            "3.3. L'utilisateur s'engage à payer toutes les sommes dues au Casino " + casino.name + " et à ne jamais essayer de réattribuer, refuser, révoquer, contester, rétrofacturer, bloquer, maintenir ou annuler aucun de ces paiements.",
            "3.4. Si l'utilisateur a déposé ou retiré des fonds au moyen d'un porte-monnaie électronique tels que Moneybookers, Neteller ou EcoCard, le Casino peut demander des copies des autres transactions montrant que le porte-feuilles en ligne spécifique est en cours d'utilisation.",
            "3.5. Toute activité illégale ou criminelle faite par le joueur entraînera la résiliation immédiate du compte Casino et le Casino se réserve le droit d'informer les autorités compétentes et d'autres casinos en ligne ainsi que les commerçants de ces actions avec la divulgation complète.",
            "3.6. Le Casino se réserve le droit de modifier l'un de ses services offerts à tout moment, y compris une indisponibilité temporaire du support clientèle, du support technique ou d'autres services offerts au joueur.",
            "3.7. En cas de défaillance technique ou problème informatique, tous les paris en jeu peuvent être considérés comme nuls et non avenus. Le Casino ne peut être tenu responsable des pertes, victoires ou autres problèmes générés par une défaillance technique ou d'une panne de quelque manière que ce soit.",
            "3.8. Si un joueur s'abstient de se connecter à son compte Casino sur une période de 90 jours ou plus ou si le joueur se connecte sans faire un seul pari ou mise sur une période de 90 jours ou plus, le Casino aura droit à tout solde sur le compte dudit joueur et la somme forfaitaire. Si le joueur souhaite retirer cette somme après cette période, le Casino examinera chaque situation au cas par cas, mais se réserve le droit d'invoquer la règle des 3 mois ci-dessus."]
    }, {
        title: "Conditions des promotions & bonus",
        content: [
            "4.1. Aucun bonus gratuit et bonus sur dépôt ne peut être retiré du Casino, et sont destinés à améliorer le temps de jeu seulement. En cas de retrait, les montants de bonus seront déduits par le Casino avant l'effectuation et l'envoi du retrait.",
            "4.2. Tout l'argent des bonus devra être retiré au moment de l'encaissement, l'argent des bonus est de l'argent qui peut être utilisé afin d' aider à générer des gains, mais ne peut pas être retiré dans le cadre des gains. Le bonus sera considéré comme «non-retirable» et ne sera pas réédité à votre compte, mais déduit des gains par le gestionnaire des retraits au cours du processus de paiement. L'argent des Cashback est également «non retirable» et le casino se réserve le droit de déduire les cashback du/es retrait/s en attente du joueur.",
            "4.3. Les bonus, sauf spécifiquement indiqué par le Casino au préalable, sont contraints par une exigence de mises devant être remplie avant qu'un joueur ne puisse retirer.",
            "4.4. Toutes les promotions cashback sont contraintes par une exigence de mise d'1 fois. Les joueurs ne peuvent pas retirer un bonus cashback jusqu'à ce que la somme du cashback soit misée en entier. Les cashback ont une limite de retrait maximum de 10 fois la quantité du montant de cashback crédité. Cela comprend les cashback récompensés comme «cadeaux», les «points comps» ou les «jetons gratuits». Le Casino se réserve le droit de déduire le Cashback d'un retrait en attente.",
            "4.5. Tous les prix de tournoi / loterie / jeu ont une exigence de mise d'une fois (1x). Le Casino se réserve le droit de déduire le montant du bonus / cashback spécial d'un retrait en attente. Les joueurs ayant un retrait en attente ou ayant une balance supérieure ou égale a 100 $ / € / £ / 1000 ZAR ne sont pas éligibles aux prix d'un tournoi / loterie / jeu.",
            "4.5.1 Bonus spécial – tout bonus de 200% ou plus, tout bonus cashback de 100% ou plus sera considéré comme bonus spécial et sera limité à un maximum de retrait de 3 fois le montant du dépôt.",
            "4.6. Il est de la responsabilité du joueur de connaître les détails du bonus qu'il demande ou qui lui est donné, y compris l'exigence de mises, les jeux restreintes et d'autres détails, modalités et exigences associées avec ledit bonus.",
            "4.7. Les joueurs choisissant de jouer sans bonus, devront l'indiquer avant de commencer à jouer avec un bonus. Si un joueur ne veut pas du bonus qui a été déposé sur son compte par le casino, sera alors en droit de demander a un représentant du support clientèle du casino de supprimer le bonus de son compte de casino, via courriel, appel téléphonique ou chat en direct. Si un bonus est reçu et qu'un pari est fait (de tout montant), le bonus devra alors être joué dans son intégralité, jusqu'à ce que le solde soit nul ou que l'exigence de pari soit complète. Si le joueur n'a pas commencé à jouer, le support pourra alors supprimer le dit bonus.",
            "4.8. Les bonus gratuits (Points comps, jetons gratuits) sont des bonus jouables attribués a un joueur afin qu'il puisse jouer gratuitement. Les bonus gratuits ont une exigence de mises différentes des autres bonus, et ont une limite de retrait fixe de pas plus de 50 Crédits (50 $, €, £, AUD ou 5 00 ZAR). Tout solde restant ou extra est considéré comme «non retirable »et sera effacé de la balance du joueur. Chaque joueur est autorisé à retirer les gains d'un bonus gratuit qu'une seule fois au cours de son / sa vie de jeu sauf si spécifiquement approuvé par le directeur du casino.",
            "4.9. Un joueur qui n'a jamais fait un dépôt et souhaite retirer un gain issue d'un bonus gratuit, doit tout d'abord faire un dépôt minimum de 15,00 ($, €, £, AUD) ou de 150 ZAR. Ce premier dépôt n'est pas autorisé à être retiré ou ajouté à un existant retrait en attente. S'il est ajouté à un prélèvement existant, le montant sera déduit du montant de retrait total. Un joueur ayant déjà effectué un dépôt et maintenant désireux de retirer Un gain issu d'un bonus gratuit, doit au préalable avoir déposé dans les 30 derniers jours pour que le retrait soit approuvé et le dépôt ne peut pas être ajouté à un existant retrait en attente. S'il est ajouté à un retrait en attente, le montant sera déduit du montant de retrait total. Les bonus gratuits (jetons gratuits) ont une exigence de mise de 20X à 60X, sauf indication contraire.",
            "4.10. Toutes les promotions offertes par le Casino sont valables uniquement durant la période indiquée sur l'offre promotionnelle elle-même affichée sur le site web du Casino et / ou toute autre communication prévue par le Casino lui-même.",
            "4.11. Un joueur de bonus gratuit (un joueur qui joue uniquement des bonus gratuits et n'a pas encore effectué de dépôt) peut retirer ses gains en jouant ses trois premiers bonus gratuits sur son compte, pendant les 30 premiers jours après la date d'enregistrement du compte. Après trois bonus joués ou après 30 jours depuis la date d'inscription, les fonds reçus d'un bonus gratuit seront considérés comme non encaissables et seront déduits du solde ou du retrait.",
            "4.12. Dans le cas où un joueur a terminé ses exigences de mise, le casino se réserve le droit d'ajuster le solde au montant du retrait maximum autorisé et d'effacer tout solde supplémentaire. Dans les cas où un joueur n'est pas éligible pour effectuer un retrait, le casino se réserve le droit d'effacer tous les gains et le solde une fois le pari atteint."
        ]
    }, {
        title: "Termes & conditions - retraits",
        content: [
            "5.1. Tous les dépôts de 249 USD / EURO / GBP / AUS ou moins ont un retrait maximum de 10X le dépôt de cette session spécifique de jeu. Une fois un retrait approuvé, le casino applique la règle du 10X et corrige le retrait pour refléter fidèlement la règle du 10X. Les fonds restants sont considérés comme «non-retirables» et sont effacés de la balance du joueur. Les bonus sur dépôt utilisés au cours d'une session de jeu et se traduisant par une demande de retrait, sont également «non retirables» et pourront être déduits de ce retrait en attente.",
            "5.2. Les joueurs peuvent soumettre leur demande de retrait à tout moment. Le délai d'approbation peut prendre de un à quatorze (1-14) jours ouvrables à compter de la date d'autorisation correspondant au moment où le processus a été achevé avec succès. Le traitement d'un retrait en attente nécessite une autorisation du département des fraudes.",
            "5.3. Aucun bonus activé par le joueur ne peut être retiré du Casino ou échangé, et sera donc annulé des gains du joueur lors du traitement du retrait.",
            "5.4. Tous les retraits (Wire, Neteller, Direct-to-Card, etc.) sont soumis à des frais de transfert allant de 10 a 40 $, €, £. Les frais sont basés en fonction de l'emplacement et sont déterminés par notre processeur de paiement et a la charge du joueur.",
            "5.5. Afin qu'un retrait soit approuvé, le joueur aura à soumettre certains documents et informations afin de vérifier son compte. Le non-respect ou omission de fournir lesdits documents et les informations requises sous 30 jours après que retrait ait été demandé, se traduira par l'annulation du retrait en cours. Il est de la responsabilité du joueur d'envoyer la documentation requise. L'approbation du retrait dépend ainsi de toutes les conditions décrites et la vérification de tous les documents requis.",
            "5.6. Un joueur ne peut pas retirer les gains tant qu'il est encore soumis à une exigence de mises attaché à sa balance. Il doit remplir les conditions de mises avant de pouvoir effectuer une demande de retrait, au risque de voir la demande de retrait refusée.",
            "5.7. Le casino se réserve le droit de refuser ou d'annuler un bonus ou des gains dans le cas où un dépôt est annulé, retourné ou refusé par un service de paiement ou un processeur à tout moment.",
            "5.8. Si le joueur a misé sur des jeux restreints avec un bonus n'autorisant un de ces jeux, le Casino se réserve le droit d'annuler tout retrait et de confisquer tous les gains. Avant de commencer à jouer avec un bonus sur dépôt ou un bonus gratuit, il est de la responsabilité du joueur de vérifier et de comprendre quelles sont les restrictions de ce bonus.",
            "5.9. Sauf indication contraire, et même s'il n'y a pas de limites supérieures aux gains encaissables, le casino opère une limite de retrait maximum de 10 000 crédits par mois et 1 500 crédits par retrait. Pour les retraits au-dessus du seuil mensuel, les paiements se font par tranches jusqu'à ce que le montant total soit versé. Ce seuil ne s'applique pas à un jackpot progressif. Les joueurs sont autorisés à retirer jusqu'à 1500 crédits (équivalant à 1500 USD) par demande. Si le montant que le joueur souhaite retirer est supérieur à 1500, alors les fonds restants sont laissés sur le compte. Une fois le premier retrait approuvé, un joueur pourra effectuer une nouvelle demande de retrait avec l'argent resté sur son compte. Un joueur a le droit d'annuler la demande de retrait à tout moment tant qu'elle est encore en cours. Cette règle s'applique également aux retraits de jackpots progressifs.",
            "5.10. Le montant minimum pour un retrait est de 50 €/$/£/AUD ou 500 ZAR.",
            "5.11. Tous les dépôts doivent être misés au moins une fois (1X) le montant du dépôt afin de se qualifier pour un retrait. Un retrait ne peut être approuvé que si le dépôt a été misé au moins 1 fois au préalable. Les dépôts effectués dans le cadre de promotions sont soumis aux Termes et Conditions de cette promotion spécifique et à l'exigence de mise y correspondant.",
            "5.12. Points de fidélité (comp points): ont une limite de retrait de 10 fois le montant converti avec une limite maximale de 300 € / $ / £ / AUD. Les fonds restants sont considérés comme «non-retirables» et sont effacés de la balance du joueur.",
            "5.13. Prix de tournoi / loterie / jeu: ont une limite de retrait de 2 fois maximum du montant reçu. Les fonds restants sont considérés comme «non-retirables» et seront supprimés de la balance du joueur."
        ]
    }, {
        title: "Accord de confidentialité",
        content: [<>Décharge:<br/>6.1. Le Casino est tenu de garder tous les renseignements personnels donnés par le
            joueur de façon sûre et sécurisée. Tous les détails soumis par le joueur au Casino à tout moment, sont
            confidentiels et ne seront remis à aucun tiers ou organisme.</>,
            "6.2. Il est de la responsabilité du joueur seul d'assurer la sécurité de tous les noms d'utilisateur et mots de passe liés à leur compte casino. Le Casino ne portera aucune responsabilité pour toute utilisation des informations de compte envoyées par une personne n'étant pas le joueur à l'origine enregistré.",
            "6.3. Le Casino se réserve le droit d'utiliser le nom du joueur dans le cadre d'événements promotionnels (par exemple, la publication d'un gros gain par le joueur), sans aucune compensation ou paiement effectué au dit joueur et ne sera pas tenu de demander une approbation préalable par le joueur.",
            "6.4. Les termes et conditions stipulés sur ce document définissent un accord final et exclusif entre vous (le joueur) et le Casino et complètent tous les précédents accords, déclarations et ententes entre vous et Casino " + casino.name + " en rapport avec votre participation sur le Casino.",
            "6.5. Le Casino se réserve le droit de refuser un dépôt ou de retirer des crédits ou des bonus à notre discrétion. Le Casino se réserve le droit de retirer ou de modifier les termes de ce programme à un moment donné ou sans préavis.",
            "6.6. Cette offre ne est pas applicable pour les employés, partenaires, fournisseurs ou toute autre partie liée a l'entreprise et / ou relation professionnelle du Casino " + casino.name + ".",
            <>Propriété intellectuelle: <br/>6.7. Via ce contrat, Le Casino accorde à l'utilisateur un droit non
                transférable, révocable et non exclusive d'utiliser son site Web, son logiciel et / ou ses services
                ainsi que tous les services liés. Ce droit s'étend également aux royalties et tous les droits de
                propriété intellectuelle étant attachés à elle.</>,
            "6.8. L'utilisateur reconnaît et accepte que le droit d'auteur, la marque et tous les autres droits de propriété intellectuelle exercés sur tout matériel et / ou contenus fournis et appartenant au site Web et / ou au logiciel du casino, reste notre propriété stricte. L'utilisateur peut utiliser le matériel mentionné ci-dessus qu'en accord avec ces termes et conditions.",
            "6.9. Casino " + casino.name + " est le nom commercial du Casino, et le joueur n'a pas le droit d'utiliser ou d'employer un tel terme, ou tout autre terme, graphique, texte, concept ou méthodologie.",
            "6.10. L'utilisateur reconnaît et accepte le fait que le matériel et le contenu reproduits sur le site du Casino sont mis à leur disposition à des titres non commerciaux uniquement. Ce matériel et ces contenus ne doivent être téléchargés sur le disque dur d'un ordinateur que dans le but de servir cet objectif. Si l'un des jeux du casino sont interférés, mutilés, contrefaits ou endommagés alors ils deviennent nuls et inutilisables. Indépendamment de la façon dont ils sont obtenus que ce soit physiquement ou par voie électronique. La responsabilité matérielle des jeux du casino comprenant des erreurs est seulement limitée à un remplacement du dit matériel.",
            "6.11. Toute autre utilisation, telle que la copie, la falsification, la reproduction, la publication, la transmission et la répartition du travail dérivé de ce matériel et de ces contenus, est strictement interdite.",
            "6.12. Des actions criminelles ou suspectes possibles peuvent être transmises aux autorités compétentes. Si le cas de figure se présente, le joueur aura alors à fournir certains documents de vérification lors des transactions faites sur le casino - retraits ou dépôts. Les documents d'identification requis pour un tel cas sont une photo d'identité claire, un justificatif de domicile, des copies des cartes de crédit utilisées sur le casino et les formulaires de déclaration d'achat. Cette exigence est nécessaire pour permettre la sécurité de nos joueurs et est conforme aux règles régies par les régulations de jeux.",
            "6.13. Ce contrat est considéré comme dûment signé et accepté par les deux parties: Le Casino et l'utilisateur.",
            "6.14. Les termes et conditions stipulés définissent une compréhension et un accord final et exclusif entre vous (le joueur) et Casino " + casino.name + " et complètent tous les précédents accords, déclarations et ententes entre vous et Casino " + casino.name + " en rapport avec votre participation au Casino."]
    }, {
        title: "Parrainer un ami termes et conditions",
        content: ["7.1. Les termes et conditions généraux sont appliqués au programme 'Parrainer un ami' et tous les autres programmes offerts par Casino " + casino.name + ". En soumettant toutes les informations requises dans le programme 'Parrainer un ami', vous et la partie parrainée (personne étant parrainée) consentent à tous les termes de ce programme et la partie parrainée consent a ce que ses données soient transmises par son 'parrain'. En soumettant ces informations, la partie parrainée consent à recevoir un courriel/mail envoyé à lui / elle par Casino " + casino.name + " lui demandant d'ouvrir un compte réel. En soumettant des informations dans le programme 'Parrainer un ami', la partie parrainée est pleinement consciente que son 'parrain' a effectivement soumis ses données en toute bonne foi.",
            "7.2. Les personnes utilisant le même ordinateur (enregistrement à partir de la même adresse IP) ne seront pas autorisées à prendre part au bonus de parrainage pour des raisons de sécurité. Les joueurs parrainés ne doivent pas être détenteur d'un compte sur Casino " + casino.name + " Casino. L'adresse e-mail fournie par la partie parrainant ne doit être associée à aucun joueur actuel sur Casino " + casino.name + " et ne doit pas être le même email que le membre parrainant.",
            "7.3. Les 'parrains' seront tenus de remplir des informations spécifiques à propos de leur filleul/e, cette information inclut mais ne se limite pas à leur nom, prénom et adresse e-mail donnée. Une fois que toutes les informations requises auront été fournies, soumises et vérifiées par Casino " + casino.name + ", alors seulement le bonus pourra être fourni à la fois au parrain et filleul.",
            "7.4. Afin de bénéficier du bonus de parrainage, le filleul doit d'abord ouvrir un compte en argent réel et effectuer un dépôt minimum de 25 $, €, £ sur ce compte. Le parrain et le filleul recevront leur bonus uniquement après que le filleul ait déposé 25 $, €, £ sur son compte joueur puis après un délai de 48 heures, chaque partie recevra le bonus sur son compte respectif.",
            "Merci de noter que la version française des conditions générales n'a aucune valeur juridique et est une simple traduction de la version anglaise officielle et originale des termes et conditions du casino produite par courtoisie pour nos joueurs francophones."]
    }, {
        title: "Politique de remboursement",
        content: ["Merci d'avoir ouvert un compte chez nous et d'y avoir fait un dépôt sur nos sites Web.",
            "Nous offrons la possibilité d'un remboursement sur tous les dépôts effectués sur nos sites Web. Cependant, certaines conditions doivent être remplies. Nous vous invitons à lire les conditions expliquées plus en détail ci-dessous, afin de voir la condition qui s'applique à votre situation, tout en suivant la bonne procédure.",
            "Notre politique de retour est simple, et sera traité immédiatement.",
            "Cependant, il y a un délai limité comme indiqué:",
            <ul>
                <li>Dans les 24 heures suivant le dépôt initial.</li>
                <li>Au besoin au moment de l'ouverture du compte, la conformité requise au moment de l'ouverture du
                    compte doit être complétée et approuvée par notre service de conformité.
                </li>
                <li>Dysfonctionnement des jeux - Capture d'écran requise.</li>
                <li>Impossible de jouer les crédits - Solde non joué.</li>
                <li>Frais bancaires ou prélèvement injustifié - relevé bancaire requis.</li>
            </ul>,
            <>Frais de traitement<br/>Il y a des frais impliqués dans le traitement et l'émission de votre
                remboursement. Les différents frais (dans votre devise) sont les suivantes:</>,
            <ul>
                <li>Chèque papier - 55,00</li>
                <li>Fil SEPA - 20,00</li>
                <li>Fil SWIFT - 75,00</li>
            </ul>,
            "Remboursements tardifs ou manquants (le cas échéant):",
            <ul>
                <li>Si vous n'avez pas encore reçu votre remboursement, vérifiez à nouveau votre compte bancaire.
                </li>
                <li>Ensuite, contactez votre société de carte de crédit, cela peut prendre un certain temps avant
                    que votre remboursement ne soit officiellement affiché.
                </li>
                <li>Si vous avez fait tout cela et que vous n'avez toujours pas reçu votre remboursement, veuillez
                    nous contacter à <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a>.
                </li>
            </ul>]
    }]
}
const termsDe = {

    title: "Allgemeine Geschäftsbedingungen",
    "content": [],
    sections: [{
        title: "Definitionen",
        content: [
            <>Der Spieler:<br/>Alle Nutzer der Software und/ oder Spiele und/ oder Promotionen, die von der
                Spielbank angeboten werden.</>,
            <>Das Spielbank:<br/>{casino.name} eingeschlossen jeglichem Softwareinhalt sowie dem Service, der
                auf www.{window.location.host.replace("www.", "")} geleistet wird und alle zugehörigen URLs.</>,
            <>Service:<br/>Service, Hilfe, Angebote und Assistenz, die die Spielbank den Nutzern anbietet.</>,
            <>Kredite:<br/>Menge an Geld im Kontostand des Nutzers; Menge an Spiel- Chips/ Geld, welches zur
                Verfügung steht. 1 Kredit = 1€,1£,1$,1AUD,10ZAR (annähernd).</>,
            <>Wir/Uns/Unser:<br/>Die Operatoren der Spielbank Website und/ oder seine Konzernunternehmen;</>,
            <>Spieler:<br/>Spieler und/ oder ein Gastspieler und/ oder Ruhende Spieler und/ oder geschlossene
                Spielerkonten;</>,
            <>Echter Spieler:<br/>Ein Spieler, der sein eigenes Geld für Wetten in der Spielbank verwendet;</>,
            <>Umsatzanforderung:<br/>Die Anzahl, die eine Summe von Krediten gewettet, eingesetzt oder umgesetzt
                werden muss, bevor der Kontostand ausgezahlt werden kann. Dieser Begriff ist auch als
                Umsatzbedingung bekannt.</>]
    }, {
        title: "Haftungsausschluss",
        content: ["Sobald Sie in der Spielbank entweder zum Spaß oder als echter Spieler spielen, besteht zwischen dem Spieler und der Spielbank eine Vereinbarung, an welche der Spieler mit folgenden AGB´s gebunden ist. Wenn Sie sich in unserer Spielbank registrieren, ein Konto eröffnen und/ oder bei " + casino.name + " spielen, stimmt der Spieler hiermit zu, dass er:",
            <ol>
                <li> Die folgenden AGB´s vollständig gelesen und verstanden hat und</li>
                <li>Die AGB´s vollkommen akzeptiert hat und diese bei allen Handlungen mit der Spielbank und/ oder
                    der Spielbank Software befolgen wird.
                </li>
            </ol>,
            "Falls sich ein Absatz als nichtig, ungültig oder nicht durchsetzbar herausstellt, bleiben die restlichen Bestimmungen unverändert und voll anwendbar. Die nachstehenden Bedingungen ersetzen und überschreiben alle anderen Bedingungen, die von der Spielbank angeboten werden.",
            "Die Spielbank ist durch die Regierung von Curacao für den Betrieb einer Online Spielbank registriert, lizensiert und geregelt."]
    }, {
        title: "Allgemeine rahmenbedingungen",
        content: ["1.1. Das Spielen in " + casino.name + " ist Erwachsenen gestattet. Spieler müssen für das Spielen 25 Jahre oder alter sein. Die Altersbegrenzung kann sich je nach der Jurisdiktion Ihres Landes unterscheiden und es liegt in Ihrer Verantwortung das erlaubte Mindestalter für Ihr Land zu überprüfen. Die Spielbank behält sich vor, zu jeder Zeit einen Altersnachweis vom Spieler zu verlangen.",
            "1.2. DDer Spieler akzeptiert, dass die Spielbank die AGB´s jederzeit ohne vorherige Warnung ändern oder entfernen kann und es ist liegt in der Verantwortung des Spielers regelmäßig die hier geschriebenen AGB´s zu lesen und auf eventuell zutreffende Änderungen zu prüfen. Fehlende Kenntnisnahme der festgelegten AGB´s stellt keinen gültigen Grund für eine Verletzung der Regeln und Bedingungen, die hier ab dem Zeitpunkt der Eröffnung eines neuen Kontos hier festgelegt wurden, dar.",
            "1.3. Alle Entscheidungen, die die Spielbank trifft, sind endgültig und entsprechen den hier festgelegten AGB´s. Durch die Registrierung in der Spielbank stimmt der Spieler zu und akzeptiert, dass jegliche Entscheidungen, die " + casino.name + " trifft, endgültig und bestimmend sind.",
            "1.4. Die Spielbank übernimmt keine Haftung oder Verantwortung für das Recht des Spielers mit echtem Geld in der Online Spielbank zu spielen, weder direkt noch indirekt. Dies beinhaltet alle Angestellten, Lizenzgeber, Vertreiber, Großhändler, Konzerngesellschaften, Tochtergesellschaften, Werbung, Aktionen oder andere Agenturen, Medienpartner, Agenten, Einzelhändler, die eine Berechtigung über solch Darstellungen und Garantien reklamieren oder angeben. Es liegt in der alleinigen Verantwortung des Spielers sich über die örtlichen Gesetze zu informieren und diesen Folge zu leisten. Die Spielbank übernimmt keine Haftung, wenn ein Spieler das Gesetz verletzt.",
            "1.5. Alle Spiele, Dienstleistungen oder Werbeaktionen, die von der Spielbank angeboten werden sind ausschließlich zum Zwecke der Unterhaltung und nicht als Finanzsystem gedacht. Es besteht die Option mit unechten Krediten zu spielen, welches echtem Geld nicht gleichgestellt ist. Das Spielen um echtes Geld ist die einzige Entscheidung des Spielers und somit akzeptiert der Spieler das volle Risiko, das durch das Spielen echten Krediten entsteht.",
            "1.6. Jegliche Abgaben, Steuern, Überweisungsgebühren oder andere finanzielle Verpflichtungen oder Auswirkungen, die durch die Verwendung der von der Spielbank angebotenen Spiele und / oder Dienstleistungen entstehen, liegen in der alleinigen Verantwortung des Spielers.",
            "1.7. Für jede Einzahlung mit Kreditkarte wird eine Bearbeitungsgebühr von 5% erhoben. Die Gebühr wird dem berechneten Einzahlungsbetrag hinzugefügt.",
            "1.8. Der Spieler wird weder die Spielbank noch eine andere Agentur, Mitarbeiter, Partner oder mit der Spielbank verbundene Medien für Schäden, Kosten, Aufwendungen oder Verbindlichkeiten, die durch die Verwendung und / oder Annahme von Dienstleistungen der Spielbank oder für jede Aktivität des Spielers in der Spielbank verantwortlich halten.",
            "1.9. Die Spielbank trägt keine Verantwortung oder Haftung für technische Fehler in der Software, die im Zusammenhang mit der Internet-Verbindung des Spielers auftreten. Jegliche Unterbrechungen der Internet- Verbindung liegen in der Verantwortung des Spielers und die Spielbank ist hierfür nicht haftbar und kommt für keine Entschädigung auf. Alle anderen technischen Mängel müssen schriftlich eingereicht werden und ein Bildschirmabbild enthalten, sodass die Spielbank dessen Echtheit überprüfen kann.",
            "1.10. Der Spieler akzeptiert hiermit, dass die in der Spielbank- Konto registrierte Identität die einzige Person ist, der das Anmelden und die Verwendung der Spielbank -Software oder der Website gestattet ist. Ein Verstoß gegen die Vereinbarung (d.h. Nutzung des Kontos von einer anderen Person) wird als Verstoß gegen die AGB´s gewertet und berechtigt zur Kündigung der Mitgliedschaft und Erlöschen aller Gewinne. Das Konto ist in keiner Weise übertragbar, noch kann der Benutzer für das Konto geändert werden. Alle neuen Benutzer müssen ein neues Konto eröffnen oder verletzen die Geschäftsbedingungen.",
            "1.11. Die Spielbank behält sich das Recht auf Änderung, Addition oder Subtraktion der Spiele und/ oder Dienstleistungen, die es bietet, jederzeit ohne vorherige Ankündigung oder Aktualisierungen. Allgemeine Geschäftsbedingungen können geändert oder erneuert werden und der Spieler akzeptiert diese Befugnis im Rahmen der Website, falls erforderlich.",
            "1.12. Der Spieler erklärt sich weiterhin bereit, " + casino.name + ", seine Mitarbeiter, leitende Angestellte, Direktoren und sämtlichen damit verbundene oder angeschlossene Personen oder Organisationen, für alle Kosten, Ausgaben, Verbindlichkeiten und Schäden, die aus rechtlichen oder anderen Maßnahmen entweder durch oder gegen den Spieler vorgenommen wurden, zu entschädigen. Dieser Fall umfasst (ist aber nicht beschränkt auf) alle rechtlichen Auswirkungen, die als Folge der Kriminalisierung von Online / Internet -Wetten innerhalb der Jurisdiktion des Rechtsstandort, Gebiet oder Land des Spielers zutreffen können.",
            "1.13. Der Spieler akzeptiert jegliche und alle Spielergebnisse, die von der Spielbank- Software oder der Website als wahr dargestellt werden. Alle Streitigkeiten über die Ergebnisse der gespielten Spiele werden von dem Spiel-Server entschieden und diese werden die endgültige Entscheidung der Spielbank bestimmen. Wenn der Spieler aus irgendeinem Grund nicht mit den hier festgelegten Bedingungen vollständig einverstanden ist, so hat der Spieler das Recht, das Spielen zu unterbrechen und die Software von seinem / ihrem Computer zu entfernen. Sollte ein Spieler weiterhin mit seinem / ihrem persönlichen Spielerlebnis in der Spielbank unzufrieden sein und wünscht das Konto zu schließen, trägt der Spieler die Verantwortung für das manuelle Schließen oder Einfrieren des Kontos innerhalb der Spielbank-Software sowie für das Entfernen aller Software, die mit der Spielbank vom eigenen Computer oder mobilen Geräten verbunden ist. Gesetzliche Altersbeschränkungen und strenge Einhaltung der Altersbegrenzung, wie in der Gerichtsbarkeit der genannten Jurisdiktion zugestimmt, werden vollständig von der Spielbank durchgesetzt. Dies wird deutlich durch die Altersbegrenzung von 25 Jahren, welche Spielern erlaubt unabhängig vom Geschlecht in der Spielbank zu spielen.",
            "1.14. Im Falle einer Beschwerde oder Klage oder technischen Beanstandung oder Streitigkeiten in der Spielbank muss die Beschwerde schriftlich innerhalb von fünf (5) Tagen nach dem Ereignis eingereicht werden. Trifft die Beschwerde nach diesem Zeitpunkt ein, behält sich die Spielbank das Recht vor, sich mit der Angelegenheit zu befassen. Können jedoch mildernde Umstände einbezogen werden; Beispiel: Ein Spieler ist arbeitsunfähig oder sonst für eine Aussage nicht verfügbar, werden wir diese Angelegenheit als einen Einzelfall behandeln, aber das Recht vorbehalten, die oben genannte fünf (5) Tage-Regel abzurufen.",
            "1.15. Der Spieler akzeptiert, dass alle von der Spielbank getroffenen Entscheidungen endgültig und wirksam sind.",
            "1.16. Alle Mittel in der Bilanz eines Kontos zum Zeitpunkt der Schließung können nicht ausgezahlt werden. Dies beinhaltet alle ausstehenden Auszahlungen."]
    }, {
        title: "Verletzung der nutzungsbedingungen",
        content: ["2.1. Folgende Aktionen des Spielers werden als Verstoß gegen die allgemeinen Geschäftsbedingungen der Spielbank betrachtet, sind aber nicht auf diese Auflistung beschränkt. Mögliche Folgen können die Auflösung des Kontos des Spielers, die Beschlagnahme von Gewinnen und vorhandenen Guthaben, Verweigerung von Service, Bonussen und anderen Angeboten von der Spielbank sein.",
            <ul>
                <li>Mehr als ein aktives Konto bei {casino.name} und/oder mehr als ein aktives Konto auf einer
                    verbundenen Schwesterseite, die von der Rival Plattform betrieben wird.
                </li>
                <li>Sollte die Spielbank an einem beliebigen Zeitpunkt eine Verbindung oder Anschluss zu einem
                    betrügerischen, gesperrten, ersetzten oder bereits geschlossenen Konto in jedem Markt innerhalb
                    des Rival- Netzwerk oder jedem anderen Online-Spielbank entdecken.
                </li>
                <li>Sollte die Spielbank zu jeder beliebigen Zeit eine aktive oder frühere Beschwerde / Streit /
                    Kritik auf einem öffentlichen Forum oder Blog oder jeder anderen Art von Website entdecken.
                </li>
                <li>Eine Nichtübereinstimmung des Kontos des Spielers mit dem Namen auf der verwendeten Kreditkarte
                    (n) oder Zahlungsmethode (n), die für eine Einzahlung verwendet wurde (n).
                </li>
                <li>Fehlerhafte oder falsche Registrierungsinformationen.</li>
                <li>Der Spieler spielt von einem Gebiet oder einer Region, in welcher das Spielen nicht gestattet
                    ist.
                </li>
                <li>Der Spieler hat das Mindestalter, welches von der Spielbank in den Nutzungsbedingungen
                    vorausgesetzt wird, noch nicht erreicht.
                </li>
                <li>Falls der Spieler (absichtlich oder nicht absichtlich) einem anderen Spieler erlaubt hat mit dem
                    eigenen Konto zu spielen.
                </li>
                <li>Wenn eine Person in der Spielbank nicht im Sinne der reinen Unterhaltung gespielt hat und das
                    Spielen ausschließlich als berufliche Tätigkeit nutzt oder der Spieler in Verbindung mit einem
                    anderen Spieler von einem anderen Verein oder Gruppe oder Teil davon steht.
                </li>
                <li>Wenn der Spieler absichtlich ein \"Chargeback\" oder \"Streit\" über Mittel aus dessen
                    Kreditkarte oder jede andere Zahlungsmethode für das Konto vollzieht; oder es hat sich
                    herausgestellt, dass ein \"Chargeback\" in Belastbarkeit einer Schwesterspielbank auf der Rival-
                    Plattform oder jeder anderen Online Spielbank Website vollzogen oder angedroht wurde. Dazu
                    gehören mündliche und schriftliche Drohungen und das Anfechten eines Chargeback oder dem
                    Chargeback oder das Abbrechen von Transaktionen.
                </li>
                <li>Wenn entdeckt wurde, dass der Spieler betrügt, oder festgestellt wurde, dass unregelmäßige
                    Wettmuster oder verdächtige oder ungewöhnliche Wettstrategien eingesetzt wurden oder der Spieler
                    nach einem System spielt, (d.h. Martingal, Maschinen, Computer, Software oder jede andere Form
                    der Automatisierung) um einen Betrug zu begehen, der für den Verlust der Einnahmen der Spielbank
                    aufgrund dieses Vorgangs entworfen wurde.
                </li>
                <li>Der Spieler mit einem Mitarbeiter, Ex-Mitarbeiter, Unternehmen, Dritten oder der verbundenen
                    Spielbank- Agentur in Beziehung steht oder stand.
                </li>
                <li>Der Spieler jeglichen Versuch eines \"Hack\" der Spielbank -Software oder einer Website
                    unternommen, oder Änderungen vorgenommen hat, die nicht ausdrücklich von der Spielbank erlaubt
                    wurden.
                </li>
            </ul>,
            "2.2. Sollte die Spielbank erfahren, dass eine der oben genannten Regelungen vom Spieler in jeder anderen Web- basierten Spielbank verletzt wurden, wird " + casino.name + " entsprechend handeln.",
            "2.3. In Übereinstimmung mit den genannten Regelungen und allen sonstigen hier beschriebenen Bedingungen erklärt sich der Spieler bereit, alle akzeptierten Werbe- oder Werbeveranstaltungen und Annahme von Preisen und/oder Gewinnen, die von " + casino.name + " übertragen worden sind zu genehmigen und stimmt dabei dem Gebrauch von Benutzername , Land und Vornamen für den benannten Nutzen ohne Entschädigung oder Zahlung an den Spieler zu. Zum Beispiel: Sollte ein Spieler einen großen Hauptgewinn gewinnen und die Spielbank möchte den Sieg bewerben, so behält sich die Spielbank das Recht vor den Benutzernamen, das Land des Spielers und/oder den Vornamen auf der Spielbank –Website, einer Zeitschrift oder einer anderen Website sowie jede andere Quelle nach Wahl der Spielbank zu verwenden."]
    }, {
        title: "Allgemeine geschäftsbedingungen",
        content: ["3.1. Die Spielbank Website gestattet den Benutzern das Herunterladen, Installieren und den Gebrauch der Spiel- Software, um online zu spielen.",
            "3.2. Die Spielbank ist dazu verpflichtet die Spiele fair und ehrlich anzubieten und bestätigt, dass die Software und alle angebotenen Spiele von " + casino.name + " auf einem geprüften und validierten Random Number Generator (RNG) basieren und von Dritten sichergestellt wird, dass alle Spiele fair und ehrlich sind.",
            "3.3. Die Spielbank behält sich das Recht vor, das Guthaben auf dem Konto eines Benutzers zu ändern, um Beträge, die an die Spielbank geschuldet werden, einzufordern.",
            "3.4. Der Nutzer verpflichtet sich, alle geschuldeten Beträge an " + casino.name + " zu zahlen und nicht zu versuchen, diese Beträge wiederzuerlangen, zu verweigern, widerrufen, abzustreiten, zurückzuziehen, zu blocken, einzuhalten oder zu kündigen.",
            "3.5. Wenn der Benutzer unter Verwendung eines E -Wallet wie Moneybookers, Neteller oder EcoCard ein- oder ausgezahlt hat, kann die Spielbank Kopien von anderen Transaktionen verlangen, welchen den Gebrauch des bestimmten E-Wallet aufzeigt.",
            "3.6. Jegliche illegale oder kriminelle Aktivitäten , die der Spieler begeht, führen zur sofortigen Kündigung des Spielbank-Kontos und die Spielbank behält sich das Recht vor, die zuständigen Behörden und andere Spielbanken und Kaufleute über diese Maßnahmen mit vollständigen zu informieren.",
            "3.7. Die Spielbank behält sich das Recht vor, jegliche angebotenen Dienstleistungen jederzeit zu ändern, einschließlich eines vorübergehenden Serverausfalls von Kundenservice, technische Unterstützung oder andere Dienstleistungen, die dem Spieler gegeben sind.",
            "3.8. Im Falle einer technischen Störung oder einem Computerfehler können alle Einsätze im Spiel als null und nichtig gewertet werden. Die Spielbank kann nicht für Verluste, Gewinne oder andere Probleme, die durch einen technischen Defekt oder Störung in irgendeiner Form erzeugt wurden, verantwortlich gemacht werden.",
            "3.9. Sollte ein Spieler sie sich nicht in sein Konto für einen Zeitraum von 90 Tagen oder mehr einloggen oder der Spieler tätigt innerhalb von 90 Tagen keine einzige Wette, ist die Spielbank berechtigt eine Verpfändung des Guthabens in der Kasse des Spielers vorzunehmen. Sollte der Spieler wünschen, diesen Betrag nach Ablauf dieser Frist auszuzahlen, werden wir diese Situation als Einzelfall überprüfen aber das Recht vorbehalten uns auf die 3 -Monats-Regel zu berufen."]
    }, {
        title: "Nutzungsbedingungen für werbeaktionen und bonusse",
        content: [
            "4.1. Alle kostenlosen Bonusse und Einzahlungsbonusse können nicht von der Spielbank ausgezahlt werden. Bei einer Auszahlung werden die Bonusbeträge von der Spielbank einbehalten bevor die Auszahlung bearbeitet wird.",
            "4.2. Jegliches Bonusgeld wird zum Zeitpunkt einer Auszahlung entfernt, denn dies dient allein zur Hilfe den Gewinn zu erhöhen, aber kann nicht als Teil des Gewinns ausgezahlt werden. Der Bonus wird als \"Nicht- Auszahlbar\" behandelt und kann während des Auszahlungsprozesses Ihrem Auszahlungsbetrag nicht wieder angerechnet werden, sondern wird von dem Auszahlungsmanager abgezogen. Cashback- Geld ist ebenso \"Nicht- Auszahlbar\" und die Spielbank behält sich das Recht vor das Cashback- Geld von einer bestehenden Auszahlung abzuziehen.",
            "4.3. Bonusse, sofern nicht ausdrücklich durch die Spielbank vorher angegeben, sind an eine Wettanforderung gebunden, welche erfüllt werden muss bevor der Spieler eine Auszahlung tätigen kann.",
            "4.4. Alle Cashback-Aktionen haben eine Umsatzbedingung von 1X. Spieler können einen Cashback- Bonus nicht auszahlen bevor sich nicht der Betrag des Cashbacks vollständig umgesetzt wurde. Jegliches Cashback- Geld ist an einen maximalen Auszahlungsbetrag von 10X dem Cashback- Betrag begrenzt. Dazu gehören Cashback als \"Geschenke\", \"Comps\" oder \"kostenlose Chips\". Die Spielbank behält sich das Recht vor, Cashback Geld von einer anstehenden Auszahlung abziehen.",
            "4.5. Alle Cashback- Gelder, die als Turnier- Preis, in einer Lotterie oder in einer anderen speziellen Aktion gewonnen wurden, haben ein maximales Auszahlungslimit von 5X dem Cashback- Bonus und sind an eine Wettanforderung von 1X gebunden. Alle Bonusse von 200% oder mehr, Cashback- Versicherung von 75% oder mehr werden als Sonderaktionen gewertet. Die Spielbank behält sich das Recht vor, Cashback- Geld von einer Auszahlung in Bearbeitung abzuziehen. Spieler, die eine anstehende Auszahlung oder ein Guthaben von über 100 $ / € / £ / haben, können keine Turnierpreise, Bargeld von einem Gewinnspiel oder Cashback von einer laufenden Cashback- Aktion, die von unserem Werbe-Team angeboten wird, erhalten.",
            "4.5.1  Sonderbonusse - alle Bonusse in Höhe von 200% oder mehr sowie Cashback-Bonusse von 100% oder mehr werden als Sonderbonus gewertet.",
            "4.6. Es liegt in der Verantwortung des Spielers, sich über die Details des Bonus, den sie anfordern oder der ihm gegeben wurde, zu informieren, sowie die unerlaubten Spiele oder jede andere Bedingung und Anforderung zu kennen.",
            "4.7. Spieler, die ohne Bonusse spielen möchten, müssen dies angeben, bevor Sie beginnen mit einem Bonus zu spielen. Wenn ein Spieler den Bonus, der durch die Spielbank in die Kasse eingezahlt wurde, nicht annehmen möchte, ist er dazu berechtigt, den Bonus per Mail, Telefon oder Live Chat von einem unserer Kundenservice- Mitarbeiter entfernen zu lassen. Wenn ein Bonus angenommen wurde und eine Wette abgeschlossen wurde (in beliebiger Höhe), muss der Bonus vollständig erfüllt werden bis entweder der Kontostand bei null ist, oder die Wettanforderung erfüllt wurde. Wenn der Spieler noch nicht begonnen hat zu spielen, kann der Kundenservice diesen auf Wunsch entfernen.",
            "4.8. Kostenlose Bonusse (Comps, kostenlose Chips) sind Bonusse, die vergeben werden, damit Sie kostenlos Spaß haben können. Kostenlose Bonusse unterscheiden sich in den Wettbedingungen von den anderen Bonussen und sind an ein festes Auszahlungslimit von nicht mehr als 50 Krediten (50 $, €, £, AUD oder 500 ZAR). Verbleibendes oder zusätzliches Guthaben ist \"Nicht- Auszahlbar\" und wird vom Kontostand eines Spielers entfernt. Jeder Spieler darf einen Gewinn, der von einem Bonus stammt, nur einmalig während seiner/ihrer Spielelebenszeit auszahlen lassen, außer ausdrücklich vom Spielbank Manager genehmigt.",
            "4.9. Ein Spieler, der nie eine Anzahlung getätigt hat und einen kostenlosen Chip auszahlen lassen möchte, muss zuerst eine erfolgreiche Einzahlung von mindestens 15,00 ($, €, £, AUD, oder 150 ZAR) tätigen. Diese erste Einzahlung kann nicht zu einer bestehenden Auszahlung hinzugefügt oder davon entfernt werden. Falls die Summe einer bestehenden Auszahlung hinzugefügt wurde, wird der Betrag vom gesamten Auszahlungsbetrag abgezogen. Ein Spieler, der bereits eine erfolgreiche Einzahlung getätigt hat und nun einen kostenlosen Chip auszahlen lassen möchte, muss in den vorigen 30 Tagen eingezahlt haben, damit die Auszahlung bestätigt werden kann. Die Einzahlung kann nicht einer bestehenden Auszahlung in Bearbeitung hinzugefügt werden. Wenn zu einer bestehenden Auszahlung hinzugefügt, wird der Betrag vom Gesamtauszahlungsbetrag abgezogen werden. Kostenlose Bonusse (Freie Chips) sind an eine Umsatzbedingung von 20x bis 60x, sofern nicht anders angegeben, gebunden.",
            "4.10. Alle von der Spielbank angebotenen Sonderangebote sind nur in dem Werbeangebot festgelegten Zeitraum gültig, welcher auf der Spielbank- Website und/oder einer anderen Veröffentlichung der Spielbank zur Verfügung gestellt wird. Ein Bonus von 200% oder höher, der mit einem beliebigen Einzahlungsbetrag aktiviert wurde, unterliegt einer maximalen Auszahlung von 10x des Einzahlungsbetrags.",
            "4.12. Ein Gratis-Bonusspieler (ein Spieler, der nur Gratis-Boni spielt und noch keine Einzahlung getätigt hat) kann Gewinne während der ersten 30 Tage nach Datum der Kontoregistrierung abheben, wenn er seine ersten drei Gratis-Boni auf seinem Konto spielt. Nach drei gespielten Boni oder nach 30 Tagen nach dem Registrierungsdatum werden die von einem kostenlosen Bonus erzielten Gewinne als nicht auszahlbar bewertet und vom Guthaben oder von der Auszahlung abgezogen..",
            "4.13. Sobald ein Spieler die Wettanforderung beendet/ durchgespielt hat, behält sich das Casino das Recht vor, das Guthaben auf den maximal zulässigen Auszahlungsbetrag anzupassen und jegliches zusätzliche Guthaben abzuziehen. In Fällen, in denen ein Spieler nicht berechtigt ist, eine Auszahlung vorzunehmen, behält das Casino das Recht, alle Gewinne und das Guthaben zu löschen, sobald die Wettbedingungen erfüllt wurden."
        ]
    }, {
        title: "Auszahlungsbedingungen",
        content: [
            "5.1. Alle Einzahlungen von 249 USD / EURO / GBP / AUS oder weniger haben eine maximale Auszahlungssumme von 10X der Einzahlung für die jeweilige Spielsitzung (alle anderen Währungen sind an den USD gekoppelt). Sobald eine Auszahlung genehmigt wird, wendet die Spielbank die 10X –Regel an und korrigiert die Auszahlung, bis die 10X Regel genau reflektiert wird. Die restlichen Mittel werden als \"Nicht - Auszahlbar\" gewertet und werden aus dem Kontostand des Spielers gelöscht. Einzahlungsbonusse, die während einer Spielsitzung verwendet wurden, sind \"Nicht - Auszahlbar\" und werden von der einzelnen ausstehenden Auszahlung abgezogen.",
            "5.2. Kunden können ihren Auszahlungsantrag jederzeit vorlegen. Die Bearbeitungszeit kann zwischen einem und vierzehn (1 – 14) Werktagen dauern, bis der Autorisationsprozess erfolgreich abgeschlossen wurde. Die Bearbeitung einer anstehenden Auszahlung erfordert ebenso die Genehmigung der Betrugsabteilung.",
            "5.3. Sämtliche Bonusse, die vom Spieler eingelöst werden, können nicht ausgezahlt oder von der Spielbank eingelöst werden und werden vom Gewinn des Spielers bei der Bearbeitung der Auszahlung entfernt.",
            "5.4. Alle Auszahlungen (Wire, Netteller, Direct-to-Card etc.) unterliegen einer 10 bis 40 $, €, £ Transfergebühr. Die Gebühren sind an Örtlichkeiten gebunden und werden von unserem Zahlungsprozessor bestimmt und es ist in der Verantwortung des einzelnen Spielers diese zu absorbieren.",
            "5.5. Für die Genehmigung einer Auszahlung wird der Spieler aufgefordert bestimmte Unterlagen und Informationen zur Überprüfung des Kontos einzureichen. Bei Nichtbeachtung oder Nichtzusenden dieser Dokumente und Informationen kann die Auszahlung ab 30 Tagen nach der Auszahlungsanfrage storniert werden. Es liegt in der Verantwortung der einzelnen Spieler die erforderlichen Dokumente einzusenden. Die Auszahlungsgenehmigung hängt von den beschriebenen Bedingungen und der Prüfung aller erforderlichen Dokumente ab.",
            "5.6. Ein Spieler kann keine Gewinne auszahlen, solange dieser noch eine Wettanforderung an den Kontostand gebunden ist. Die Wettanforderungen müssen erfüllt worden sein bevor eine Auszahlungsanfrage gestellt werden kann oder es besteht das Risiko einer Verweigerung der Auszahlung.",
            "5.7. Die Spielbank hat das Recht Gewinne oder Bonusse zu verweigern oder zurückzuhalten, wenn eine Einzahlung storniert, zurückgegeben oder von einem Zahlungsdienstleister oder Prozessor verweigert wurde.",
            "5.8. Wenn der Spieler auf ein eingeschränktes Spiel mit einem Bonus, der nicht für das Spiel erlaubt ist, gewettet hat, behält sich die Spielbank das Recht vor, jede Auszahlung zu stornieren und alle Gewinne zu konfiszieren. Bevor ein Einzahlungsbonus oder ein Chip genutzt wird, ist es in der Verantwortung des Spielers, zu überprüfen und zu verstehen, wie die Einschränkungen des jeweiligen Bonus oder Chip festgelegt sind.",
            "5.9. Außer anders angegeben, und trotz keiner Obergrenzen für Gewinne in bar, gilt in der Spielbank ein maximales Auszahlungslimit von 10.000 Krediten pro Monat und 1.500 Krediten pro Auszahlung. Für Auszahlungsbeträge, die über dem monatlichen Limit liegen, werden die Zahlungen in Raten eingehalten, bis der volle Betrag ausgezahlt wurde. Dieser Grenzwert gilt nicht für den Progressiven Hauptgewinn. Spieler dürfen 1500 Kredite (entsprechend 1.500 USD) pro Antrag auszahlen. Wenn der gewünschte Auszahlungsbetrag größer als 1500 ist, werden die verbleibenden Mittel auf dem Konto hinterlassen. Sobald die erste Auszahlung genehmigt wurde, kann der Spieler eine weitere Auszahlung von seinem Kontostand beantragen. Ein Spieler hat das Recht die Auszahlungsanfrage jederzeit abbrechen, während diese noch ausstehend ist. Dies gilt auch für Auszahlungen von Progressiven Hauptgewinnen.Der Mindestbetrag für eine Auszahlung beträgt 50 €/$/£/AUD oder 500 ZAR.",
            "5.10. Sämtliche Einzahlungen müssen mindestens einmal (1X) in der Summe des eingezahlten Betrags umgesetzt worden sein, um für eine Auszahlung qualifiziert zu sein. Eine Auszahlung kann nicht genehmigt werden wenn ein Mindesteinsatz von 1X der Einzahlung nicht erfüllt wurde. Einzahlungen, die gemeinsam mit Aktionen getätigt wurden, unterliegen den Geschäfts- und Wettbedingungen der spezifischen Werbeaktion.",
            "5.11. Ein Spieler, der auf seinem Konto mindestens einen Monat lang (30 Tage) keine Einzahlung getätigt hat, wird als Gratisbonus-Spieler betrachtet und ist nicht berechtigt, Gewinne, die aus Gratisboni oder Gratisgeld erzielt wurden, abzuheben. Die Möglichkeit, Gewinne aus kostenlosen Boni abzuheben, ist nur unseren aktiven Spielern vorbehalten.",

        ]
    }, {
        title: "Datenschutzvereinbarung",
        content: [<>Haftungsausschluss:<br/>6.1. Die Spielbank ist verpflichtet alle persönlichen Daten, die der
            Spieler angegeben hat, zu bewahren. Alle Daten, die der Spieler in der Spielbank zu jedem beliebigen
            Zeitpunkt angibt, werden allein bei der Spielbank bleiben und nicht an Dritte oder Agenturen
            weitergegeben werden.</>,
            "6.2. Es ist die alleinige Verantwortung des Spielers, alle Benutzernamen und Kennwörter, die mit dem Konto verbunden sind, zu sichern und geschützt zu halten. Die Spielbank übernimmt keine Haftung für die Verwendung der Kontodaten von einer Person, die nicht der ursprünglich eingetragene Spieler ist.",
            "6.3. Die Spielbank behält sich das Recht vor, den Namen des Spielers als Teil einer Werbeveranstaltungen (z.B. Veröffentlichung eines großen Gewinns durch den Spieler) ohne jede Entschädigung oder Zahlungen oder voriges Einverständnis des Spielers zu verwenden.",
            "6.4. Die hier dargelegten Allgemeinen Geschäftsbedingungen definieren eine endgültige und exklusive Vereinbarung und Übereinstimmung zwischen Ihnen (dem Spieler) und der Spielbank und vervollständigen alle vorherigen Vereinbarungen, Zusicherungen und Übereinkünfte zwischen Ihnen und " + casino.name + " im Hinblick auf die Teilnahme in der Spielbank.",
            "6.5. Die Spielbank behält sich das Recht vor, jegliche Kredite oder Bonusse zu jedem beliebigen Zeitpunkt ohne vorige Benachrichtigung zu verweigern, zurückzuziehen oder die Bedingungen im Programm zu ändern.",
            "6.6. Dieses Angebot gilt nicht für Mitarbeiter, Partner, Lieferanten oder andere Personen, die in einer geschäftlichen und/oder beruflichen Verbindung zu " + casino.name + " stehen.",
            "6.7. Durch diesen Vertrag räumt die Spielbank dem Benutzer ein widerrufliches, nicht ausschließliches und nicht übertragbares Recht, ein, die Website, die Software und/oder seine Dienste sowie alle verknüpften Dienstleistungen zu nutzen. Dieses Recht erstreckt sich auch auf die Lizenzgebühren und alle Rechte des damit verbundenen geistigen Eigentums.",
            "6.8. Der Benutzer erkennt an und akzeptiert, dass die Urheberrechte, die Marke und alle anderen Rechte des geistigen Eigentums, die durch beliebige Materialien dargestellt und/oder die zur Verfügung gestellten Inhalte und Eigentümer der Website und/oder Software der Spielbank in unsrem strengen Eigentum bleiben. Der Benutzer kann die oben genannten Materialien nur in Übereinstimmung mit diesen hier erwähnten Allgemeinen Geschäftsbedingungen verwenden.",
            "6.9. " + casino.name + " ist der Handelsname der Spielbank und der Spieler hat keine Rechte diesen zu nutzen oder jegliche anderen Begriff, Grafiken, Texte, Konzepte oder Methodiken die in der Website enthalten sind zu verwenden.",
            "6.10. Die Benutzer erkennt an und akzeptiert die Tatsache, dass die Materialien und die Inhalte, die auf der Website der Spielbank reproduziert werden, nur als nicht-kommerzielle Titel platziert und zur Verfügung gestellt werden. Dieses Material und Inhalte in Original sollen auf der Festplatte eines Computers heruntergeladen werden und nur zu diesem Zweck dienen. Wenn eines der Materialien der Spielbank-Spiele gestört, gefälscht oder beschädigt werden, sind sie unbrauchbar und nichtig. Unabhängig davon, ob diese physisch oder elektronisch enthalten sind. Material-Haftung der Spielbank-Spiele, die einen Fehler enthalten, ist nur auf den Ersatz des gleichen Materials beschränkt.",
            "6.11. Jede andere Verwendung ist streng verboten, auch als die Kopierung, Verfälschung, Vervielfältigung, Veröffentlichung, die Übertragung und die Verteilung der Arbeiten, die in diesem Inhalt dargestellt werden.",
            "6.12. Mögliche kriminelle oder verdächtige Handlungen werden den zuständigen Behörden gemeldet werden. Wenn aufgefordert, ist es möglich, dass Spieler gewisse Nachweisdokumente einreichen müssen, wenn Transaktionen an die Spielbank oder Auszahlungen getätigt werden. Solche Nachweise können ein klarer Bildausweis, Nachweis der Adresse, Kreditkartenkopien und Rechnungsbestätigungen bilden. Diese Anforderung ist notwendig, um die Sicherheit unserer Spieler in Übereinstimmung mit Spiel-Regelungen zu überprüfen.",
            "6.13. Dieser Vertrag gilt als ordnungsgemäß von beiden Parteien unterzeichnet und akzeptiert: die Spielbank und der Benutzer.",
            "6.14. Die dargelegten Allgemeinen Geschäftsbedingungen definieren eine endgültige und exklusive Übereinstimmung und Vereinbarung zwischen Ihnen (dem Spieler) und " + casino.name + " und vervollständigen alle vorherigen Vereinbarungen, Zusicherungen und Übereinkünfte zwischen Ihnen und " + casino.name + " im Hinblick auf die Teilnahme an der Spielbank."]
    }, {
        title: "Refer-a-friend geschäftsbedingungen",
        content: ["7.1. Die Allgemeinen Geschäftsbedingungen gelten für das Refer-a-Friend-Programm und alle anderen Programme, die von " + casino.name + " angeboten werden. Mit dem Einreichen aller erforderlichen Informationen in das Refer-a-Friend-Programm, sind Sie und die angeworbene Partei (Person, auf die verwiesen wurde) mit allen Bedingungen dieses Programms einverstanden und die angeworbene Partei ist damit einverstanden und stimmt zu, dass Ihre Daten von Ihnen an die bezugnehmende Partei übermittelten werden. Durch das Einreichen dieser Information ist die verwiesene Partei damit einverstanden E-Mail an ihn / sie von " + casino.name + " zu erhalten, welche sie/ihn auffordert sich für ein echtes Spieler-Konto anzumelden. Während Sie Informationen in das Refer-a-Friend-Programm übergeben, ist der angeworbenen Partei völlig bewusst, dass ihre Daten in gutem Glauben übermittelt werden.",
            "7.2. Personen, die den gleichen Computer verwenden (Registrierung von derselben IP-Adresse) wird nicht gestattet am Refer-a-Friend-Bonus aus Sicherheitsgründen zu profitieren. Angeworbene Spieler müssen nicht Mitglied von " + casino.name + " sein. Die E-Mail-Adresse der bezugnehmenden Partei darf nicht mit einem aktuellen Spieler von " + casino.name + " assoziiert werden noch soll sie die gleiche wie die E-Mail des Bezug-Mitglieds sein.",
            "7.3. Das empfehlende Mitglied wird verpflichtet spezifische Informationen über sein/ihr empfohlenen Freund anzugeben; diese Informationen beinhalten, ist aber nicht beschränkt auf den Vornamen, Nachname und E-Mail-Adresse. Nachdem alle erforderlichen Informationen zur Verfügung gestellt wurden und eine anschließende Überprüfung von " + casino.name + " durchgeführt wurde, kann der Bonus dem werbenden Mitglied und dem angeworbenen Mitglied gewährt werden.",
            "7.4. Um sich für den Refer-a-Friend-Bonus zu qualifizieren, muss der geworbene Teilnehmer zuerst ein Echtgeld-Konto eröffnen und eine Einzahlung von mindestens 100 $,€, £ auf dieses Konto tätigen. Sowohl der einladende Freund und der geworbene Freund werden ihren Bonus nur dann erhalten, nachdem der geworbene Freund eine Einzahlung von 100 $,€, £ getätigt hat und ein Zeitlimit von 48 Stunden nach der Einzahlung in das reale Spielerkonto erreicht worden ist."]
    }]
}
const termsEs = {
    title: "Promociónes Generales",
    content: ["Jugar en un casino, ya sea por diversión o por juego real, constituye un acuerdo entre el jugador, el casino y su administración, esto quiere decir que el jugador está sujeto a los términos y condiciones de este documento. El jugador acepta que este es un acuerdo entre él y el casino y es el único acuerdo que rige su relación, con exclusión de todos los demás acuerdos, declaraciones y representaciones.",
        "Cada cláusula contenida en estos términos y condiciones será separada de las demás. Si se determina que alguna cláusula es nula, inválida o inaplicable por cualquier motivo, los términos y condiciones restantes seguirán siendo de plena vigencia y efecto."],
    sections: [{
        title: "Definiciones",
        content: [
            <>El jugador:<br/>Cualquier usuario del sitio web o los juegos y/o promociones que ofrece el Casino.</>,
            <>El Casino:<br/>Casino {casino.name}, incluyendo todos los contenidos y servicios prestados en
                www.{window.location.host.replace("www.", "")} y URLs asociados.</>,
            <>Servicio:<br/>Servicios, apoyo, ofertas y asistencia ofrecida por el casino para sus usuarios.</>,
            <>Créditos:<br/>Cantidad de dinero en saldo de la cuenta del usuario; Cantidad de fichas de juego /
                dinero disponible para su uso. 1 crédito = 1 €, £ 1, 1 $, 1AUD, 10ZAR (aproximadamente).</>,
            <>Nosotros / Nos / Nuestro:<br/>Los operadores del sitio web del Casino y/o compañías de su grupo;</>,
            <>Jugador / es:<br/>Jugador y/o un Jugador Huésped y/o un Jugador inactivo y/o un Jugador Cuenta
                cerrada;</>,
            <>Real Player:<br/>Un jugador que utiliza su propio dinero para hacer apuestas en el Casino;</>,
            <>Requisitos de apuestas:<br/>El número de veces que una suma de créditos tiene que ser apostado,
                estacado o jugado antes de que el balance sea elegible para retirar. También conocido como requisito
                de sustitución.</>]
    }, {
        title: "Descargo de responsabilidad",
        content: ["Jugar en el casino, ya sea para el juego de la diversión o por dinero real constituye un acuerdo entre el jugador y el casino que el jugador será sometido a los siguientes términos y condiciones contenidos en el presente documento. Al registrarse, abrir una cuenta y/o jugar en el casino " + casino.name + ", el jugador acepta que tienen:",
            <ol>
                <li> Totalmente leído y comprendido los términos y condiciones escritos a continuación y</li>
                <li>Ha aceptado plenamente los términos y condiciones y se cumplen en todas las relaciones con el
                    Casino.
                </li>
            </ol>,
            "Si cualquier cláusula resulta ser nula, inválida o inaplicable por cualquier razón, los restantes términos y condiciones permanecerán de plena vigencia y efecto. Los términos y condiciones descritos en este documento sustituyen y sobrescriben cualquier otras condiciones ofrecidas por el Casino.",
            "El Casino se ha registrado, autorizado y regulado por el gobierno de Curacao con el propósito de operar un casino en línea."]
    }, {
        title: "Promociónes generales",
        content: [
            "1.1. Jugar en el casino " + casino.name + " se limita a sólo los adultos. Los jugadores deben tener 25 años de edad o más para jugar. Restricción de edad puede cambiar de acuerdo a la legislación nacional y local, y es la responsabilidad del jugador para comprobar la edad mínima permitida en su país. El Casino se reserva el derecho de exigir la verificación de edad desde el jugador en cualquier momento.",
            "1.2. El jugador acepta que el casino puede cambiar, añadir o restar de los términos y condiciones en cualquier momento sin previo aviso, y es responsabilidad del jugador de revisar periódicamente los términos y condiciones escritas por posibles cambios que puedan afectarles. La falta de conocimiento de dicho términos y condiciones no constituye una razón válida para cualquier violación de las normas y condiciones establecidas aquí, desde el momento de la apertura de una nueva cuenta en el casino.",
            "1.3. Todas las decisiones tomadas por el casino son finales, y cumplirán con los términos y condiciones establecidos en este documento. Al registrarse en el Casino, el jugador se compromete a aceptar cualquier y todas las decisiones tomadas por el casino como final y decisivo.",
            "1.4. El Casino se hará cargo de ninguna responsabilidad, ya sea directa o indirectamente, como en relación con el derecho legal del jugador para jugar en un casino online con dinero real. Esto incluye cualquier empleados, concesionarios, distribuidores, mayoristas, filiales, subsidiarias, publicidad, promoción u otras agencias, socios de los medios, agentes o distribuidores afirman ni se ha visto a reclamar la autorización para efectuar tales declaraciones o garantías. Es la única responsabilidad del jugador de revisar la legislación local y acatar de manera responsable. El Casino llevará ninguna responsabilidad por cualquier violación de la ley por parte del jugador.",
            "1.5. Cualquier juegos, servicios o promociones que ofrece el Casino pretendían meramente para fines de entretenimiento y no como un sistema financiero. Existe una opción para jugar con créditos falsos que no hacen dinero real igual. Jugar por dinero real es la única decisión del jugador, y al hacerlo, el jugador acepta plenamente los riesgos involucrados por jugar por créditos reales.",
            "1.6. Las tasas, impuestos, tasas de transferencia u otras responsabilidades financieras o consecuencias relacionadas con el uso de los juegos y/o servicios ofrecidos por el Casino son la responsabilidad del jugador y solo del jugador.",
            "1.7. Cada depósito realizado con tarjeta de crédito está sujeto a una tarifa de procesamiento del 5%. La tarifa se agregará al monto del depósito cobrado.",
            "1.8. El jugador no tendrá el Casino ni cualquier agencia, empleado, socio o medios relacionados con el Casino responsable de los daños, costos, gastos o pasivos generados por la utilización y/o aceptar servicios del Casino ni por cualquier actividad del jugador en relación con el Casino.",
            "1.9. El Casino no asumirá ninguna responsabilidad por cualquier error técnico que se producen en el software que están relacionados con la conexión a internet del jugador, software de computadora personal o hardware de computadoras personales. Cualquier interrupción en el servicio de Internet son responsabilidad del jugador y el casino no compensarán ni guardarás responsabilidad por interrupciones causadas por un problema técnico en el lado del jugador, incluyendo pero no limitado a las desconexiones de Internet. Los otros fallos técnicos deberán presentarse por escrito e incluir una captura de pantalla para que el casino puede verificar su autenticidad.",
            "1.10. El jugador acepta que la identidad registrada en su cuenta de casino será la única persona que entra en dicha cuenta y usar y/o jugar con el sitio web del casino. Cualquier infracción de dicho acuerdo (es decir, el uso de la cuenta por una persona diferente) será considerado una violación de los términos y condiciones y motivos para la terminación de la cuenta y el decomiso de las ganancias. La cuenta no es transferible de ninguna manera ni se puede cambiar el usuario de la cuenta. Todos los nuevos usuarios deben abrir una nueva cuenta o estar en violación de los términos y condiciones.",
            "1.11. El Casino se reserva el derecho de modificar, añadir o restar de los juegos y/o servicios que ofrece, en cualquier momento, sin previo aviso o actualización. Términos y condiciones pueden ser modificados o alterados y el jugador acepta esto como parte de la prerrogativa de la página web para hacerlo cuando y si es necesario.",
            "1.12. El jugador más indiscutiblemente se compromete a indemnizar Casino " + casino.name + ", sus empleados, funcionarios, directores y todas y cada una asociada o personas u organizaciones en contra de cualquier y todos los costos, gastos, daños y perjuicios derivados de cualquier acción legal o de otra índole adoptadas por o en contra de los afiliados jugador que surja de cualquier y toda acción dentro del Casino y/o cualquiera de sus empleados, funcionarios, directores y cualquier y todas las personas u organizaciones asociadas o afiliadas. Este hecho deberá incluir (pero no limitado a) cualquier implicación legal que pueda surgir como consecuencia de la criminalización de las apuestas en línea / Internet dentro de la jurisdicción jugadores, territorio o país.",
            "1.13. El jugador acepta cualquier y todos los resultados de los juegos mostrados por el sitio web como verdadero. Cualquier disputa relacionada con el resultado de los partidos disputados serán decididos por los resultados del servidor del juego, y éstos determinarán la decisión final del Casino. Si por alguna razón el jugador no está totalmente satisfecho con los términos y condiciones establecidos antes que ellos, entonces el jugador está en su derecho de suspender el juego. Legales restricciones de edad y adhesiones a las políticas estrictas de edad, según lo acordado por la jurisdicción de dichos territorios a cumplir con dicha legislación de ese territorio se completa ejecutadas por el Casino. Esto está claramente definido por ningún jugador menor de 25 años de edad se le permite jugar en el casino sin importar el género.",
            "1.14. En el caso de una queja o reclamación o queja técnica o disputa en el Casino, la queja debe ser presentada por escrito dentro de los cinco (5) días del evento. Si se recibe la queja después de este tiempo, el casino se reserva el derecho de no atender el asunto. Sin embargo, si hay circunstancias atenuantes; por ejemplo: Un jugador está incapacitado o no está disponible para hacer comentarios, vamos a abordar estos problemas caso por caso pero nos reservamos el derecho a invocar la (5) regla de los cinco días anteriores.",
            "1.15. El jugador acepta todas las decisiones tomadas por el casino como definitivas.",
            "1.16. Todos los fondos restantes en el saldo de una cuenta en el momento de cierre no se pueden retirar. Esto incluye todas y todos los retiros pendientes."]
    }, {
        title: "Violaciónes de los términos y condiciones",
        content: ["2.1. Las siguientes acciones por el jugador será considerado una violación de los términos y condiciones del casino, pero no se limitan a esta lista. Las consecuencias pueden incluir la terminación de la cuenta de casino del jugador, la confiscación de las ganancias y los saldos existentes, negación de servicios, promociones y otras ofertas por el Casino.",
            <ul>
                <li>Más de una cuenta en {casino.name} CASINO y/o más de una cuenta por hogar y/o más de una
                    cuenta por dispositivo, y/o más de una cuenta desde la misma dirección IP, y o más de una cuenta
                    en un casino hermano afiliado bajo la misma gerencia {casino.name} CASINO.
                </li>
                <li>En caso de que en cualquier momento, el casino descubrir un vínculo o conexión a una
                    fraudulenta, suspendido o reembolsado o previamente cerrados cuenta en cualquier marca dentro de
                    la red Rival o cualquier otro casino en línea.
                </li>
                <li>En caso de que en cualquier momento el casino descubrir una queja / disputa / crítica activa o
                    pasada en un foro público o blog o cualquier otro tipo de sitio web.
                </li>
                <li>En caso de que en cualquier momento el jugador abusar, acosar o hablar de manera inapropiada a
                    cualquiera de los empleados del casino, así como las amenazas o intimidaciones verbales y
                    escritas.
                </li>
                <li>Un desajuste en Casino del Jugador cuenta de que no coincida con el nombre de la tarjeta (s) de
                    crédito o método (s) de pago utilizado para hacer depósitos en la cuenta.
                </li>
                <li>Información de registro incorrecta o falsa.</li>
                <li>El jugador está jugando de un territorio o región no plenamente autorizado para permitir que los
                    juegos en línea.
                </li>
                <li>El jugador no tiene la edad legal requerida de acuerdo con los Términos y Condiciones del
                    Casino.
                </li>
                <li>Si el jugador ha permitido (intencionalmente o no) a otro jugador para jugar en su propia cuenta
                    de Casino.
                </li>
                <li>Si una persona no ha jugado en el Casino puramente como un entretenimiento y sólo ha participado
                    en una capacidad profesional o en asociación con otro jugador de otro club o un grupo o parte
                    del mismo.
                </li>
                <li>Si se ha encontrado el jugador que hace deliberadamente una 'devolución de cargo 'o' diferencia
                    'de los fondos depositados de su tarjeta de crédito, o cualquier otro método de pago, en su
                    cuenta de Casino; o se ha encontrado que han hecho una 'devolución de cargo 'o amenazado con
                    hacerlo en cualquier otra calidad en un casino hermano en la plataforma Rival o cualquier otro
                    casino o sitio web en línea. Esto incluye amenazas verbales y escritas para disputar, de cargo o
                    detener transacción.
                </li>
                <li>Si el jugador es descubierto haciendo trampa o ha encontrado lo que los patrones de apuestas
                    irregulares, o estrategias de apuestas sospechosas o irregulares y ni el jugador ha instigado un
                    sistema (es decir, Martingala, máquinas, equipos, software o cualquier otra forma de
                    automatización) a proliferar una Cheat diseñado para el Casino de perder ingresos debido a esta
                    operación.
                </li>
                <li>El jugador está vinculado a ningún empleado, ex-empleado, empresa, un tercero o la agencia
                    conectada al Casino actualmente o en el pasado.
                </li>
                <li>El jugador ha hecho algún intento para hackear la página web del casino, o ha realizado algún
                    cambio en alguna forma no específicamente permitida por el Casino.
                </li>
            </ul>,
            "2.2. En caso de que el casino aprender cualquiera de las resoluciones anteriores figuran verse afectados por el jugador en cualquier otro casino basado en la web, Casino " + casino.name + " actuará en consecuencia.",
            "2.3. En la aceptación de dichas resoluciones y demás condiciones señaladas aquí, el jugador está de acuerdo en ningún evento de promoción o publicidad a la aceptación de cualquier premio y/o ganancias que se han acumulado desde el Casino " + casino.name + " y con ello respalda el uso de que los jugadores nombre de usuario, país o reales primer nombre para dicha práctica sin ningún tipo de compensación o pago al jugador. Ejemplo: Si un jugador ganar un gran bote y el Casino desea anunciar la victoria, el Casino retiene el derecho de uso del reproductor nombre de usuario, país y/o nombre real y publicar la victoria en el sitio del casino, una revista, otro sitio web o cualquier otro medio elegido por el Casino."]
    }, {
        title: "Términos y condiciones generales",
        content: ["3.1. El Casino se compromete a ofrecer juegos justos y honestos y confirma que todos los juegos ofrecidos por Casino " + casino.name + " dependen de un generador de números aleatorios auditado y validado (RNG) por un tercero asegurar que todos los juegos son justos y honestos.",
            "3.2. El Casino se reserva el derecho de modificar el saldo de la cuenta de un usuario para redimir sumas adeudadas al Casino.",
            "3.3. El usuario se compromete a pagar todos los importes debidos a Casino " + casino.name + " y nunca tratar de re-atributo, negar, revocar, disputa, devolución de cargo, bloqueo, mantenga o cancelar cualquiera de estos pagos.",
            "3.4. Si el usuario ha depositado o retirado los fondos mediante un E-Wallet el Casino podrá solicitar copias de otras transacciones que muestran que la E-Wallet específica en uso.",
            "3.5. Cualquier actividad ilegal o criminal por el jugador que dará lugar a la terminación inmediata de la cuenta del Casino y el Casino se reserva el derecho a informar a las autoridades competentes y otros casinos en línea y mercaderes de estas acciones con la divulgación completa.",
            "3.6. El Casino se reserva el derecho de modificar cualquiera de sus servicios que se ofrecen en cualquier momento, incluyendo una indisponibilidad temporal de soporte al cliente, soporte técnico o cualquier otros servicios prestados al jugador.",
            "3.7. En caso de fallo técnico o error de computadora, todas las apuestas en juego pueden ser consideradas nulas y sin efecto. El Casino no se hace responsable de las pérdidas, ganancias u otros problemas generados por un fallo técnico o un fallo en cualquier manera o forma.",
            "3.8. Si un jugador se abstengan de iniciar sesión en su cuenta de Casino por un período de 90 días o más o el jugador 90 días sin hacer una apuesta simple o apuesta, el casino tendrá derecho a cualquier saldo en cuenta que los jugadores y el importe perdido. Si el jugador desea retirar esta cantidad después de este período, vamos a examinar cada situación caso por caso pero nos reservamos el derecho a invocar la regla de 3 meses anteriormente"]
    }, {
        title: "Promociones y bonos",
        content: [
            "4.1. Todo y cualquier bonos gratis y bonos de depósito no se puede retirar del casino, y tienen el propósito de mejorar sólo el tiempo de juego. Tras la retirada, los importes de las bonificaciones serán deducidas por el Casino antes de enviar el retiro.",
            "4.2. Todos los bonos de dinero será eliminado en el punto de dinero en efectivo, dinero de la prima es el dinero que se puede utilizar para ayudar a generar ganancias, pero no puede ser retirado como parte de las ganancias. El bono será tratado como 'no extraíble 'y no se volvió a publicar en su cuenta pero deducido de las ganancias por el gerente retirada casinos durante el proceso de pago. Dinero Cashback también es 'no extraíble 'y el casino se reserva el derecho a deducirse cashback dinero de retiro pendiente de jugadores.",
            "4.3. Bonos, salvo que se especifique expresamente por el Casino de antemano, llevan un requisito de apuesta que debe ser completado antes de que un jugador puede retirar.",
            "4.4. Todas las promociones cashback llevan un requisito de apuesta de 1X. Los jugadores no pueden retirar un bono de devolución de dinero hasta que la suma del cashback es apostado en su totalidad. Todo el dinero cashback está restringida a un límite máximo de retirada de 10 veces la cantidad de cashback. Esto incluye cashback galardonado como 'regalos', 'comps' o 'fichas gratis'. El Casino se reserva el derecho a deducir el dinero Cashback de un retiro pendiente.",
            "4.5. Todo cashback dinero ganado de un premio del torneo, el premio de la rifa o promoción especial tienen un límite máximo de retirada de 5 veces el bono de devolución de dinero y llevar a un requisito de apuesta de 1X. Todos los bonos de 200% o más, el seguro de reembolso de 75% o más se considera una Promociones Especiales. El Casino se reserva el derecho a deducir el dinero Cashback de un retiro pendiente. Los jugadores que tienen un retiro pendiente o saldo de más de 100 $ / € / £ / no califican para recibir los premios del torneo o para recibir dinero en efectivo de una rifa o retirar dinero de cualquier promoción a Cashback curso o cualquier otra promoción especial ofrecida por el equipo de Promociones.",
            "4.5.1 Bono Especial - todos los bonos de 200% o más, bonos cashback de 100% o más se considera Bonificaciones Especiales.",
            "4.6. Es la responsabilidad del jugador a tener en cuenta los detalles de la bonificación que solicitan o se mencione a ellos, incluyendo los requisitos de apuesta, juegos restringidos y otros detalles, términos y requisitos asociados con dicho bono.",
            "4.7. Los jugadores no eligen jugar con bonificaciones, debe indicar por lo que antes de comenzar a jugar con un bono. Si un jugador no quiere el bono que se ha depositado en su cuenta por el casino, entonces ellos tienen derecho a solicitar un representante de atención al cliente del casino invertir la prima de su cuenta del casino, a través de correo electrónico, llamada telefónica o chat en vivo. Si se recibe un bono y una apuesta que se haga (de cualquier cantidad), la bonificación tendrá que ser jugado hasta el final, hasta que el saldo es cero o los requisitos de apuesta es completa. Si el jugador no ha comenzado a jugar, el apoyo se eliminará el bono en caso de que pedirlo.",
            "4.8. Los bonos gratuitos (Regalos, Fichas Gratis, Puntos Comp convertidos o cualquier otro bono sin depósito) son bonos jugables que se otorgan al jugador para que pueda jugar gratis. Los bonos gratuitos tienen un requisito de apuesta diferente que otros bonos, y tienen un límite de retiro fijo de no más de 50 Créditos (50 $,€,£,AUD, or 500 ZAR). Cualquier saldo restante o extra es 'No Retirable' y será eliminado del saldo del jugador. A cada jugador se le permite retirar ganancias de un bono gratis sólo una vez durante su vida de juego a menos que sea específicamente aprobado por el gerente del casino.",
            "4.9. Un jugador que nunca ha hecho un depósito y desea retirar un triunfo viruta libre, es necesario primero hacer un depósito mínimo de éxito de 15,00 ($, €, £, AUD, o 150 ZAR). No se permite esta primera cantidad del depósito que ser retirado o añadido a un retiro pendiente existente. Si se añade a una retirada existente, la cantidad se deducirá de la cantidad total retirada. A los jugadores que ha hecho previamente un depósito con éxito y ahora está deseen retirar un triunfo ficha gratis, se requiere que hayan depositado dentro de los últimos 30 días para que la retirada sea aprobado y el depósito no se puede añadir a un retiro pendiente existente. Si se añade a una retirada existente, la cantidad se deducirá de la cantidad total retirada. Bonos Gratis (Free chips) tienen un requisito de apuesta de 20X a 60X a menos que se especifique lo contrario.",
            "4.10. Todas las promociones ofrecidas por el Casino sólo son válidos para el período especificado en la propia oferta promocional que se publica en el sitio web del Casino y/o cualquier otro tipo de comunicación proporcionado por el propio Casino.",
            "4.11. Un jugador de bonos gratis (que no realizó depósitos en el casino) puede retirar ganancias cuando juega sus tres primeros bonos gratis en su cuenta, y durante los primeros 30 días desde la fecha de registro de la cuenta. Después de tres bonos jugados o de 30 días desde la fecha de registro, los fondos recibidos de un bono gratis se considerarán no retirables y se deducirán del saldo o de un eventual retiro.",
            "4.12. En caso de que un jugador haya finalizado/jugado su requisito de apuesta, el casino tiene el derecho de ajustar el saldo al máximo de retiro permitido y borrar cualquier saldo adicional. En los casos en que un jugador no es elegible para realizar un retiro, el casino tiene el derecho a elimiinar ganancia y saldo una vez que se hayan cumplido los requisitos ."
        ]
    }, {
        title: "Retiro",
        content: [
            "5.1. Todos los depósitos de 249 USD / EURO / GBP / AUS o menos tienen un retiro máximo de 10 veces el depósito para esa sesión de juego específico (todas las demás monedas vinculadas a USD). Una vez aprobado un retiro, el casino se aplica la regla de 10X y corrige la retirada para reflejar con precisión la regla 10 veces. Los fondos restantes se consideran 'no extraíble' y se eliminan de las cuentas del jugador. Bonos de depósito utilizados durante una sesión de juego que da lugar a una solicitud de retiro pendiente, también son 'Non-extraíble'y se pueden deducir de que la retirada pendiente particular.",
            "5.2. Los clientes pueden enviar su solicitud de retiro en cualquier momento. El tiempo de aprobación puede tardar uno y catorce (1 a 14) días hábiles a partir del proceso de autorización de fecha se completó con éxito. El procesamiento de un retiro pendiente requiere la autorización del departamento de fraude también.",
            "5.3. Todas y todos los bonos canjeados por el jugador no pueden ser retirados o amortizadas del Casino, y se eliminarán de las ganancias del jugador en el procesamiento de la retirada.",
            "5.4. Todos los retiros están sujetos a un 10 a 40 $, €, £ tarifa de transferencia. Las tarifas se basan en la sede y están determinadas por nuestro procesador de pagos y son responsabilidad del jugador individual de absorber.",
            "5.5. Para un retiro de ser aprobado, se le pedirá al jugador a presentar cierta documentación e información con el fin de verificar la cuenta. El incumplimiento o la no presentación de dichos documentos y de información 30 días a partir del momento en que se solicitó la retirada, darán lugar a la retirada de ser cancelada. Es responsabilidad del jugador individual para enviar la documentación requerida. Aprobación retirada depende de todas las condiciones señaladas y la verificación de todos los documentos requeridos.",
            "5.6. Un jugador no puede retirar las ganancias, siempre y cuando todavía hay un requisito de apuesta unido a su equilibrio. Deben completar los requisitos de apuestas antes de hacer una solicitud de retiro, o arriesgarse a que la retirada negó.",
            "5.7. El casino tiene el derecho de rechazar o retener bonos o ganancias en el caso de un depósito de ser cancelada, devueltos o rechazados de algún servicio de pago o procesador en cualquier momento.",
            "5.8. Si el jugador ha apostado en los juegos restringidos con un bono que no permite que los juegos que el Casino se reserva el derecho de cancelar cualquier retiro y confiscar todas las ganancias. Antes de empezar a jugar con un bono de depósito o chip libre, es la responsabilidad del jugador de revisar y entender cuáles son las restricciones de ese bono o chip particular.",
            "5.9. A menos que se especifique lo contrario, y aunque no hay límites superiores a los premios canjeables, el casino cuenta con un límite máximo de retirada de 10.000 créditos por mes y 1.500 créditos por retiro. Para los retiros por encima del umbral mensual, los pagos serán recibidos en cuotas hasta que se paga la cantidad completa. Este umbral no se aplica al jackpot progresivo. Los jugadores están autorizados a retirar hasta 1.500 créditos (equivalente a 1.500 dólares estadounidenses) por solicitud. Si la cantidad deseaba ser retirado es superior a 1500 entonces los fondos restantes quedan en la cuenta. Una vez aprobada la primera retirada, un jugador puede solicitar una retirada además con el dinero en su cuenta. Un jugador tiene el derecho de cancelar la solicitud de retiro en cualquier momento, mientras que todavía está pendiente. Esto también se aplica a los retiros de jackpots progresivos.",
            "5.10. La cantidad mínima para retirar es de 50 €/$/£/AUD o 500 ZAR.",
            "5.11. Todas y todos los depósitos se debe apostar al menos una vez (1X) la suma del depósito con el fin de calificar para un retiro. El retiro no puede ser aprobado a menos que se reunió por primera vez una apuesta mínima de 1 vez el depósito. Los depósitos realizados como parte de las promociones están sujetas a los Términos y Condiciones de esa promoción específica y requisitos de apuesta.",
            "5.12. Un jugador que no haya realizado depósitos en su cuenta durante un meses o más (30 días o más), será considerado un jugador de bonificación gratis y no será elegible para retirar ganancias que provienen de las bonificaciones / efectivo gratis que tiene en su saldo y / o que recibe del casino luego de ese periodo.",
            "La posibilidad de retirar ganancias de bonos gratis está reservada solo para nuestros jugadores activos."
        ]
    }, {
        title: "Acuerdo de privacidad",
        content: [<>Descargo de responsabilidad:<br/> 6.1. El Casino está obligado a mantener toda la información
            personal suministrada por seguro el jugador. Cualquier detalle presentado por el jugador del casino en
            cualquier momento, permanecerán únicamente con el Casino y no serán proporcionados a terceros o
            agencia.</>,
            "6.2. Es de exclusiva responsabilidad del jugador para mantener todos los nombres de usuario y contraseñas relacionadas con su cuenta de casino seguro y protegido. El Casino no llevará responsabilidad por el uso de los datos de la cuenta por una persona que no es el jugador registrado originalmente.",
            "6.3. El Casino se reserva el derecho de utilizar el nombre del jugador como parte de eventos promocionales (por ejemplo, publicar una gran victoria por dicho jugador), sin ningún tipo de compensación o pago realizado a dicho jugador y no será necesario exigir la aprobación previa por parte del jugador.",
            "6.4. Los términos y condiciones descritos definen un entendimiento y un acuerdo final y exclusivo entre Usted (el jugador) y el Casino y completos todos los acuerdos anteriores, representaciones y entendimientos entre Usted y Casino " + casino.name + " con respecto a su participación en el Casino.",
            "6.5. El Casino se reserva el derecho de retener el depósito o retirar los créditos o bonos a nuestra discreción. El Casino se reserva el derecho a retirar o modificar los términos de este programa en cualquier momento y sin previo aviso.",
            "6.6. Esta oferta no es aplicable a los empleados, socios, proveedores o cualquier otra parte con un negocio y/o relación profesional para Casino " + casino.name + ". Propiedad intelectual",
            "6.7. A través de este contrato, el casino otorga al usuario el derecho intransferible y revocable, no exclusiva y no, a utilizar su sitio web y/o su servicio, así como todos los servicios vinculados. Este derecho se extiende también a los derechos de autor y todos los derechos de propiedad intelectual que se le atribuye.",
            "6.8. El usuario reconoce y acepta que el derecho de autor, la marca y todos los demás derechos de propiedad intelectual que se ejerce sobre cualquier material y/o condición de contenidos y de pertenencia a la página web del casino, siendo de nuestra propiedad estricta. El usuario puede utilizar el material mencionado anteriormente solamente de acuerdo con estos términos y condiciones.",
            "6.9. Casino " + casino.name + " es el nombre comercial del Casino, y el jugador no tiene derecho a usar o emplear un término tan, o cualquier otro término, gráfico, texto, concepto o metodología, al participar en el Sitio Web y los materiales contenidos en el mismo.",
            "6.10. El usuario reconoce y acepta el hecho de que el material y los contenidos que se reproducen en el sitio web del Casino se colocan a su disposición sólo como títulos no comerciales. Este material y estos contenidos deben ser descargados en el disco duro de un ordenador sólo para servir a este objetivo. Si cualquiera de los materiales de los juegos de casino se ha intervenido en mutilada, falsificada o dañado se convierten en vacío e inutilizable. Independientemente de la forma en que se obtienen ya sea física o electrónicamente. La responsabilidad material de los juegos de casino que contiene cualquier error sólo se limita a la sustitución del mismo material.",
            "6.11. Cualquier otro uso está estrictamente prohibido, así como la copia, la falsificación, la reproducción, la publicación, la transmisión y la distribución de las obras derivadas de este material y estos contenidos.",
            "6.12. Acciones criminales o sospechosos posibles pueden ser reportados a la autoridad correspondiente. Cuando deba hacerlo los jugadores pueden tener que presentar determinados documentos de verificación cuando realizan transacciones con el casino en retiros o depósitos. La prueba de este tipo podría ser una identificación con foto clara, comprobante de domicilio, copias de tarjetas de crédito y formularios de declaración de la compra. Este requisito es para validar la seguridad de nuestros jugadores y en cumplimiento de la normativa de juego.",
            "6.13. Este contrato es considerado como debidamente firmado y aceptado por las dos partes: El Casino y el Usuario.",
            "6.14. Los términos y condiciones descritos definen un entendimiento y un acuerdo final y exclusivo entre Usted (el jugador) y Casino " + casino.name + " y completos todos los acuerdos anteriores, representaciones y entendimientos entre Usted y Casino " + casino.name + " con respecto a su participación en el Casino."]
    }, {
        title: "Recomienda a un amigo",
        content: ["7.1. Los términos y condiciones generales aplicables al programa por referir a un amigo y todos los demás programas ofrecidos por Casino " + casino.name + ". Mediante la presentación de toda la información requerida en el programa por referir a un amigo, usted y el partido se hace referencia (persona que está siendo canalizado) someterse a los términos de este programa y la parte que se refiere ha consentido de sus datos suministrados por usted la parte remitente. Mediante el envío de esta información, la parte consiente que se refiere a tener un correo electrónico enviado a él / ella por Casino " + casino.name + " pidiéndole / ella para registrarse para obtener una cuenta de jugador real. Mediante la presentación de la información en el programa de referir a un amigo, el partido se hace referencia es plenamente consciente de que la parte remitente ha presentado sus datos de buena fe.",
            "7.2. Las personas que utilizan el mismo equipo (registro de la misma dirección IP) no se les permitirá participar en el bono por referir a un amigo por motivos de seguridad. Jugadores referidos no deben ser miembros de Casino " + casino.name + " existentes. La dirección de correo electrónico proporcionada por la parte remitente no debe estar asociado a cualquier jugador actual del Casino " + casino.name + " ni será el mismo correo electrónico que el miembro de referencia.",
            "7.3. El miembro de referencia se requerirá que llenar la información específica acerca de su / su amigo referido; esta información incluye, pero no se limita a su nombre, apellido y dirección de correo electrónico. Después de toda la información requerida ha sido proporcionado, presentado y luego verificada por Casino " + casino.name + ", entonces y sólo entonces vendrá el bono se suministra tanto a los miembros que se refieren y que se hace referencia.",
            "7.4. Para calificar para el bono por referir a un amigo, el partido se hace referencia primero debe inscribirse para una cuenta de dinero real y depositar un mínimo de 25 $, €, £ en esa cuenta. Tanto el amigo de referencia y el amigo referido recibirá su bono sólo después de que el amigo referido haya depositado 25 $, €, £ en su cuenta de jugador real y deben permitir durante 48 horas antes de recibir el bono en su cuenta de jugador real."]
    }, {
        title: "Política de reembolso",
        content: ["Gracias por abrir una cuenta con nosotros y depositar fondos en su cuenta en nuestros sitios web.",
            "Ofrecemos la opción de reembolso en todos los depósitos realizados en nuestros sitios web. Sin embargo, se deben cumplir ciertas condiciones. Lo invitamos a leer las condiciones explicadas más detalladamente a continuación, para que vea la condición que aplica a su situación, mientras sigue el procedimiento correcto.",
            "Nuestra política de devoluciones es simple y directa, y será procesada de manera inmediata.",
            "Sin embargo, hay un período de tiempo limitado como se indica a continuación:",
            <ul>
                <li>Dentro de las 24 horas del depósito original.</li>
                <li>Según requerido al momento de abrir la cuenta, nuestro departamento de cumplimiento debe
                    completar y aprobar el cumplimiento requerido al momento de abrir la cuenta.
                </li>
                <li>Mal funcionamiento de los juegos: se requiere captura de pantalla.</li>
                <li>No se pueden reproducir los créditos: saldo no jugado.</li>
                <li>Cargos incorrectos: se requiere declaración bancaria.</li>
            </ul>,
            <>Cuota de procesamiento <br/>Hay un cargo por procesar y emitir su reembolso. Las diferentes tarifas
                (en su moneda) son los siguientes:</>,
            <ul>
                <li>Cheque - 55.00</li>
                <li>SEPA - 20.00</li>
                <li>SWIFT - 75.00</li>
            </ul>,
            "Reembolsos tardíos o faltantes (si corresponde):",
            <ul>
                <li>Si aún no ha recibido su reembolso, primero verifique nuevamente su cuenta bancaria.</li>
                <li>Luego comuníquese con la compañía de su tarjeta de crédito, puede pasar algún tiempo antes de
                    que su reembolso se publique oficialmente.
                </li>
                <li>Si ha hecho todo esto y aún no ha recibido su reembolso, contáctenos en <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a></li>
            </ul>]
    }]
}
const termsIt = {

    title: "Termini e condizioni",
    content: ["Giocare in un Casinò, sia per divertimento che per gioco reale, costituisce un accordo tra il giocatore e il Casinò online e la sua gestione, in quanto il giocatore è vincolato dai seguenti termini e condizioni contenuti nel presente documento. Il giocatore accetta che si tratta di un accordo vincolante tra il giocatore e il casinò ed è l'unico accordo che regola le relazioni del giocatore con il casinò, ad esclusione di tutti gli altri accordi, dichiarazioni e dichiarazioni.",
        "Ogni clausola contenuta in questi termini e condizioni deve essere separata e separabile da ciascuna delle altre. Se una qualsiasi clausola viene ritenuta nulla, non valida o inapplicabile per qualsiasi motivo, i restanti termini e condizioni rimarranno pienamente validi ed efficaci."],
    sections: [{
        title: "Definizioni",
        content: [<>Il Giocatore:<br/>Ogni utente del software che utilizzi giochi o promozioni offerte dal
            Casinò.</>,
            <>Il Casinò:<br/>Casinò {casino.name}, compreso l’intero software contenuti e servizi resi su
                www.{window.location.host.replace("www.", "")} e relativi URLs.</>,
            <>Servizio:<br/>Servizi, supporto offerte e assistenza offerte dal casinò per i suoi utenti.</>,
            <>Crediti:<br/>Somma di denaro sul saldo del conto dell’utente; quantità delle monete di gioco
                disponibili da utilizzare. 1 credito= 1€,1£,1$,1AUD,10ZAR (approssimativamente).</>,
            <>Noi:<br/>Gli operatori del sito web del casinò e/o il suo gruppo di società.</>,
            <>Giocatore/i:<br/>Un Giocatore attivo o un giocatore ospite o un giocatore inattivo o un giocatore con
                conto chiuso.</>,
            <>Giocatori reali:<br/>Un giocatore che utilizza il proprio denaro per scommettere nel casinò.</>,
            <>Requisiti di gioco:<br/>Il numero di volte che una somma di crediti deve essere scommessa, puntata
                prima che sia ammesso ritirare il saldo. Conosciuto anche come requisito di giro.</>]
    }, {
        title: "Disconoscimento",
        content: ["Giocando nel casinò, sia in modalità di divertimento sia in modo reale costituisce un accordo tra il giocatore e il casinò che vincola il giocatore con i seguenti termini e condizioni qui riportati. Con la registrazione, l'apertura di un conto/ giocando nel CASINO' " + casino.name + ", il giocatore accetta di avere:",
            <ol>
                <li> Letto e compreso i termini e le condizioni scritte qui sotto</li>
                <li> Accetta pienamente i termini e le condizioni al quale si atterrà nel corso di tutti i rapporti
                    con il casinò o con il software del casinò.
                </li>
            </ol>,
            "Se una clausola risultasse nulla, non valida o inapplicabile per qualsiasi ragione, i restanti termini e condizioni rimarranno in rimarranno in vigore. I termini e le condizioni descritte nel presente documento sostituiscono e sovrascrivono tutti gli altri termini offerti dal casinò. Il casinò e' registrato, autorizzato e regolamentato dal governo di Curacao per l'esercizio di casinò online."]
    }, {
        title: "Promozioni e condizioni generali",
        content: [
            "1.1 Giocare nel Casinò " + casino.name + " e' limitato solo agli adulti. I giocatori devono avere almeno 25 anni per poter giocare. La limitazione dell'età' può cambiare in base alla legislazione nazionale locale, ed e' responsabilità del giocatore verificare l'età' minima consentita nel proprio paese. Il casinò si riserva comunque il diritto di richiedere una verifica dell'età' del giocatore in qualsiasi momento.",
            "1.2 Il giocatore accetta che il casinò possa cambiare, aggiungere o sottrarre i termini e le condizioni in qualsiasi momento senza preavviso, ed e' responsabilità del giocatore controllare periodicamente i termini e le condizioni qui scritte per modifiche che potrebbero riguardarlo. La mancanza di conoscenza di tali termini e condizioni, nel momento dell'apertura del conto nel casinò non costituisce un valido motivo per nessuna violazione delle norme e delle condizioni qui stabilite.",
            "1.3 Tutte le decisioni prese dal casinò sono definitive, e saranno conformi ai termini e alle condizioni stabilite in tale documento. Con l'iscrizione al casinò, il giocatore si impegna ad accettare eventuali e tutte le decisioni finali e decisive che verranno prese dal casinò.",
            "1.4 Il casinò non si assumerà alcuna responsabilità, diretta o indiretta, come per quanto riguarda il diritto legale del giocatore di giocare in un casinò online con soldi veri. Ciò include qualsiasi dipendente, concessionario, distributore, grossista, affiliato, filiali, pubblicità, promozione o altre agenzie, media partner, agenti o rivenditori che sostengono o sono stati visti rivendicare l'autorizzazione a effettuare tali dichiarazioni o garanzie. Questa e' esclusiva responsabilità' del giocatore verificare la legge locale da rispettare in modo responsabile. Il casinò non avrà alcuna responsabilità per eventuali violazioni della legge da parte del giocatore.",
            "1.5 Tutti i giochi, servizi o promozioni offerti dal casinò sono puramente pensati per scopi di intrattenimento, e non come sistema finanziario. Esiste la possibilità di giocare con crediti falsi che non e' come usare soldi veri. Giocare con soldi reali e' solo per decisione del giocare e cosi facendo il giocatore accetta eventuali rischi connessi per giocare con tali soldi reali.",
            "1.6 Eventuali tasse, imposte, tasse di trasferimento o di altre responsabilità finanziarie o conseguenze legate all'utilizzo dei giochi o dei servizi offerti dal casinò sono sotto responsabilità del giocatore e solo del giocatore.",
            "1.7 Ogni deposito effettuato con carta di credito è soggetto a una commissione del procedimento del 5%. La commissione verrà aggiunta all'importo del deposito addebitato.",
            "1.9 Il giocatore non terrà responsabile il casinò, ne alcuna agenzia, dipendente,socio o mezzi relativi al casinò per eventuali danni, costi, spese o passività dovute all'utilizzo per aver accettato i servizi del casinò, ne per qualsiasi attività del giocatore relativa al casinò.",
            "1.9 Il casinò non si assume alcuna responsabilità per eventuali errori tecnici verificatisi nel software collegati alla connessione internet, del software del computer o dell'hardware del computer del giocatore. Eventuali interruzioni del servizio internet sono a carico del giocatore e il casinò non compenserà ne si assumerà alcuna responsabilità' per interruzioni causate da un problema tecnico da parte del giocatore, incluso e non limitato a disconnessioni internet. Eventuali altre anomalie tecniche devono essere presentate per iscritto e dovranno comprendere uno screenshot in modo che il casinò possa verificarne l'autenticità'.",
            "1.10 Il giocatore riconosce che l'identità' registrata nel proprio conto del casinò sarà dell'unica persona che accede/utilizza/gioca con il software o con il sito del casinò. Qualsiasi violazione di tale accordo (cioè l'utilizzo del conto da una persona diversa) sarà considerata una violazione dei termini e delle condizioni motivo di chiusura del conto con confisca di tutte le vincite. Il conto non e' trasferibile in alcun modo ne potrà l'utente modificare il conto. Tutti i nuovi utenti devono aprire un nuovo conto o violerà i termini e le condizioni.",
            "1.11 Il casinò si riserva il diritto di cambiare, aggiungere, o sottrarre in qualsiasi momento giochi/servizi che offre senza alcun annuncio o aggiornamento. Termini e condizioni possono essere modificati o alterati quando sarà necessario e il giocatore deve accettare questo come parte prerogativa del sito web.",
            "1.12 Il giocatore accetta inoltre indiscutibilmente di indennizzare Casino " + casino.name + ", i suoi dipendenti, funzionari, amministratori e ogni e qualsiasi associato, persone o organizzazioni contro qualsiasi costo, spese, responsabilità e danni derivanti da qualsiasi azione legale o altra azione intrapresa da o contro un Giocatore derivante da qualsiasi azione all'interno del Casinò e / o dei suoi dipendenti, funzionari, amministratori e tutte le persone o organizzazioni associate o affiliate. Questo evento include (ma non solo) le implicazioni legali che potrebbero sorgere a seguito della criminalizzazione di scommesse online in internet da parte dei giocatori, nel giurisdizione del territorio o paese.",
            "1.13 Il giocatore accetta come vero qualsiasi dei risultati di gioco mostrati dal software del Casinò o nel sito web. Eventuali controversie riguardanti i risultati delle partite giocate saranno decisi dai risultati dal server di gioco, e di questi il casinò ne determinerà la decisione finale. Se per qualsiasi motivo il giocatore non è del tutto soddisfatto dei termini e le condizioni chiarite prima, il giocatore ha il diritto di interrompere la riproduzione e rimuovere il software dal suo computer. Inoltre, un giocatore che è insoddisfatto della propria esperienza di gioco nel Casinò e desidera chiudere il proprio conto, in modo responsabile chiede manualmente la chiusura o il blocco del conto all'interno del software del Casinò e chiede di rimuovere qualsiasi software associato con il casinò dal proprio computer o dispositivi mobili. Sono applicate dal casinò legali restrizioni di età e aderenze severe alle politiche di età, come concordato dalla giurisdizione del proprio territorio per conformarsi alla citata legge di quel territorio. Questo è chiaramente definito a nessun giocatore di età inferiore ai 25 anni di età è permesso giocare al casinò indipendentemente dal sesso.",
            "1.14 In caso di reclami, ricorsi, torti o tecnicismi o dispute nel casinò, il reclamo deve essere presentato per iscritto entro cinque giorni della manifestazione. Se il reclamo viene ricevuto dopo questo tempo, il casinò si riserva il diritto di non affrontare la questione. Tuttavia, se ci sono circostanze attenuanti; per esempio: un giocatore è impossibilitato o comunque disponibile per un commento, affronteremo questi problemi, caso per caso, ma si riserva il diritto di avvalersi della regola dei cinque giorni citata sopra.",
            "1.15 Il giocatore accetta le decisioni finali prese dal casinò.",
            "1.16 Eventuali fondi rimanenti del saldo di un conto, al momento della chiusura, non possono essere ritirate. Ciò include tutti i prelievi in sospeso."
        ]
    }, {
        title: "Violazioni dei termini & condizioni",
        content: ["2.1. Le seguenti azioni da parte del giocatore sarà considerata una violazione dei termini e condizioni del Casinò, ma non limitato a questo elenco. Le conseguenze possono includere la cessazione del conto del casinò del giocatore, la confisca delle vincite e di equilibri esistenti, la negazione dei servizi, promozioni e altre offerte da parte del Casinò.",
            <ul>
                <li>Più di un conto su {casino.name} CASINO e / o più di un conto per famiglia e / o più di un
                    conto per dispositivo e / o più di un conto dallo stesso indirizzo IP e / più di un conto su un
                    altro Casinò sotto la stessa gestione di {casino.name} CASINO.
                </li>
                <li>Qualora, in qualsiasi momento, il casinò scopre un collegamento o una connessione fraudolenta,
                    in sospeso, rimborsata o un precedentemente conto chiuso su qualsiasi sito marca all'interno
                    della rete Rival o di qualsiasi altro casino online.
                </li>
                <li>Qualora, in qualsiasi momento il casinò scopra una denuncia, una disputa, una critica attiva o
                    passata su un forum pubblico o un blog o qualsiasi altro tipo di sito web.
                </li>
                <li>Qualora, in qualsiasi momento un giocatore abusi, molesti o parli in modo inappropriato con i
                    membri del personale Casinò, tra cui minacce o intimidazioni verbali e scritte.
                </li>
                <li>Una mancata corrispondenza del Giocatore sul casinò con un account che non corrisponda al nome
                    sulla carta di credito, o al/ai metodo/i di pagamento/i utilizzato/i per effettuare depositi sul
                    conto.
                </li>
                <li>Informazioni di registrazione errata o false.</li>
                <li>Il giocatore sta giocando da un territorio o da una regione che non sono pienamente autorizzati
                    a permettere il gioco online.
                </li>
                <li>Il giocatore non ha l'età legale necessaria secondo i Termini e Condizioni del casinò.</li>
                <li>Se il giocatore ha consentito o permesso (intenzionalmente o meno) ad un altro giocatore di
                    giocare sul proprio conto del Casino.
                </li>
                <li>Se un individuo non ha giocato nel Casino puramente a scopo d’intrattenimento e ha partecipato
                    solo a titolo professionale o in associazione con un altro giocatore di un altro club, gruppo o
                    parte di esso.
                </li>
                <li>Se un giocatore ha richiesto deliberatamente di fare un 'chargeback' o 'disputa' di fondi
                    depositati con la propria carta di credito, o qualsiasi altro metodo di pagamento, nel loro
                    conto del Casinò; o sono stati scoperti di aver fatto un 'chargeback' o minacciato di farlo in
                    qualsiasi altro titolo su un casinò affiliato sulla piattaforma Rival o qualsiasi altro casinò
                    online o sito web. Questo include delle minacce verbali e scritte per contestare un chargeback o
                    interrompere un'operazione.
                </li>
                <li>Se il giocatore viene scoperto barare o è stato trovato ad applicare modelli di scommesse
                    irregolari, o strategie di scommesse sospette, irregolari, oppure ha avviato un sistema (cioè
                    Martingale, macchine, computer, software o qualsiasi altra forma di automazione) per proliferare
                    con l’imbroglio, il Casinò farà perdere i ricavi a causa di questa operazione.
                </li>
                <li>Il giocatore è legato a tutti i dipendenti, ex-dipendenti, società, terzi o agenzia collegata al
                    Casinò attualmente o in passato.
                </li>
                <li>Il giocatore ha provato ogni tentativo “hack” nel software del Casino, nel sito web, o ha
                    apportato modifiche ad esso in qualsiasi modo non specificamente consentite dal Casinò.
                </li>
            </ul>,
            "2.2 Se il casinò apprende da tutte le sentenze sopra elencate di colpire un giocatore in qualsiasi altro casinò web, Casino " + casino.name + " agirà di conseguenza.",
            "2.3 In accettazione di dette sentenze e tutte le altre condizioni descritte in questo documento, il giocatore accetta eventi promozionali e pubblicitari con l'accettazione di qualsiasi premio e/o vincita che sono stati maturati da Casino " + casino.name + " e quindi approva l'uso per i giocatori dell’username, del paese o del nome reale, per tale pratica senza alcun compenso o pagamento al giocatore. Esempio: Se un giocatore vincere un grande jackpot e il Casinò vuole pubblicizzare la vittoria, il Casinò trattiene il diritto di utilizzare l’username, il paese e/o il vero nome e postare la vittoria sul sito del casinò, una rivista, un altro sito o qualsiasi altro supporto dei casinò a scelta."]
    }, {
        title: "Termini & condizioni generali",
        content: ["3.1. Il Casinò si impegna ad offrire un gioco corretto e onesto e conferma il software e tutti i giochi offerti da Casino " + casino.name + " a contare su un generatore di numeri Random controllato e validato (RNG) da un terzo per garantire che tutti i giochi siano giusti e onesti.",
            "3.2. Il Casinò si riserva il diritto di modificare il saldo del conto di un utente di riscattare somme dovute al Casinò.",
            "3.3. L'utente si impegna a pagare tutte le somme dovute al Casino " + casino.name + " e di non provare a re-attributo, negare, revocare, disputare, chargeback, bloccare, tenere o cancellare qualsiasi di questi pagamenti.",
            "3.4. Se l'utente ha depositato o ritirato fondi usando un portafogli elettronico (E-Wallet) come Moneybookers, Neteller o EcoCard, il Casinò può richiedere copie di altre operazioni che dimostrino specifici E-Wallet in uso.",
            "3.5. Qualsiasi attività illegali o criminali fatta dal giocatore comporterà l'immediata cessazione del conto Casinò e il Casinò si riserva il diritto di informare le autorità competenti e gli altri casinò online dei mercanti di queste azioni.",
            "3.6. Il Casinò si riserva in ogni momento il diritto di modificare qualsiasi dei suoi servizi offerti, anche una temporanea indisponibilità di assistenza clienti, supporto tecnico o altri servizi forniti al giocatore.",
            "3.7. In caso di guasto tecnico o un computer glitch, tutte le scommesse in gioco possono essere considerate nulle. Il Casinò non può essere ritenuto responsabile per le perdite, le vittorie o altri problemi generati da un guasto tecnico o problema tecnico in qualsiasi modo o forma.",
            "3.8. Se un giocatore decide di astenersi dall’accesso del proprio conto nel Casino per un periodo di 90 giorni o più, il giocatore che per 90 giorni non farà una singola scommessa singola, il Casinò avrà diritto a riequilibrare il conto dei giocatori e la somma incamerata. Se il giocatore desidererà ritirare l'importo dopo questo periodo, esamineremo ogni situazione caso per caso, ma si riserva il diritto di avvalersi della regola 3 mesi citata sopra."]
    }, {
        title: "Promozioni e bonus termini & condizioni",
        content: [
            "4.1. Tutti i bonus gratuiti e bonus di deposito non possono essere ritirati dal Casinò, e hanno lo scopo di migliorare solo il tempo di gioco. Al momento di ritiro, i bonus saranno dedotti dal Casino prima che il ritiro venga inviato.",
            "4.2. Tutto il denaro bonus verrà rimosso al momento del ritiro, i soldi bonus sono denaro che può essere utilizzato per contribuire a generare le vincite, ma non può essere ritirato come parte delle vincite. Il bonus sarà trattato come 'non-estraibile' e non può essere riassegnata nel tuo conto ma detratto dalle vincite da parte del gestore casinò di ritiro durante il processo di pagamento. Cashback in denaro è anche chiamato 'non estraibile' e il casinò si riserva il diritto di detrarre soldi in cashback durante l’attesa di ritiro di un giocatore.",
            "4.3. Bonus, se non espressamente specificato dal Casinò in anticipo, portano un requisito di scommessa che deve essere completata prima che un giocatore possa ritirare.",
            "4.4. Tutte le promozioni cashback portano ad un requisito di scommessa di 1X. I giocatori non possono prelevare un bonus cashback fino a che la somma del cashback è stata scommessa totalmente. Tutto il denaro cashback è limitato ad un limite massimo di ritiro del 10X dell'importo cashback. Questo include cashback premiato come 'regalo', 'composizioni' o 'chip gratuiti'. Il Casinò si riserva il diritto di detrarre Cashback denaro da un prelievo in sospeso.",
            "4.5. Tutti cashback sono soldi vinti da un premio del torneo, premio della lotteria o Promozione Speciale hanno un limite massimo di ritiro del 5X bonus per cashback e hanno un requisito di scommessa di 1X. Tutti i bonus del 200% o più, assicurazione cashback del 75% o più sarà considerato una promozione speciale. Il Casinò si riserva il diritto di detrarre Cashback denaro da un prelievo in sospeso. I giocatori che hanno un prelievo in attesa o il saldo di oltre 100 $/€/£/ non si qualificheranno per ricevere i premi del torneo o ricevere denaro da una lotteria o di prelievo dalla promozione Cashback in corso o qualsiasi altra promozione speciale offerta dalla squadra Promozioni.",
            "4.5.1 Bonus Speciale - tutti i bonus del 200% o più, bonus con cashback del 100% o più saranno considerati bonus speciali.",
            "4.6. E' responsabilità del giocatore di essere a conoscenza della richiesta dei dettagli del bonus o i loro requisiti di scommessa, vincoli di giochi e tutti gli altri dettagli,con i termini e requisiti associati a tale bonus..",
            "4.7. I giocatori non scelgono di giocare con i bonus, lo devono richiede prima di iniziare a giocare. Se un giocatore non vuole il bonus che è stato depositato nel loro conto dal casinò, ha diritto di richiedere ad un rappresentante del servizio clienti del casinò di invertire il bonus dal loro conto del casinò, a titolo di e-mail, telefonata o live chat. Se un bonus viene ricevuto e viene fatta una scommessa (di qualsiasi importo), il bonus dovrà essere giocato fino in fondo, fino a quando il saldo è pari a zero o il requisito di scommessa è completa. Se il giocatore non ha iniziato a giocare, il supporto rimuoverà il bonus su richiesta.",
            "4.8. Bonus gratuiti (Comps, Gettoni gratis, Punti comp convertiti in denaro o qualsiasi altro bonus senza deposito) sono dei bonus accreditati ai giocatori in modo che possano giocare gratuitamente. I bonus gratuiti hanno un requisito di scommessa diverso da gli altri bonus, e hanno un limite di prelievo fisso di non più di 50 crediti (50 $, €, £, AUD o 500 ZAR). Qualsiasi saldo supplementare non è ritirabile e deve essere cancellato dal saldo del giocatore. Ogni giocatore ha la possibilità di ritirare le vincite da un bonus gratuito una volta solo durante la sua vita di gioco se non espressamente autorizzato dal gestore del casinò.",
            "4.9. Un giocatore che non ha mai effettuato un deposito e desidera ritirare un chip gratuito, è tenuto ad effettuare prima un deposito minimo di 15,00 ($, €, £, AUD, o 150 ZAR). Questo primo importo del deposito non può essere ritirato o aggiunto nel ritiro già in attesa. Se aggiunto al prelievo esistente, l'importo verrà detratto dal totale ritiro. Un giocatore che ha già effettuato un deposito ed è ora desidera ritirare un chip gratuito, è necessario che abbia depositato negli ultimi 30 giorni in modo che il ritiro sia approvato e il deposito non possa essere aggiunto ad un ritiro esistente in attesa. Se aggiunto al prelievo esistente, l'importo verrà detratto dal totale ritiro. Bonus gratuiti (Free Chips) hanno un requisito di scommessa da 20x a 60X se non diversamente specificato.",
            "4.10. Tutte le promozioni offerte dal Casinò sono valide solo per il periodo indicato nell’offerta promozionale in sé, che è pubblicato sul sito web del Casino e/o di qualsiasi altra comunicazione fornita dal Casinò stesso.",
            "4.11. Un giocatore con bonus gratis (un giocatore che gioca solo bonus gratis e non ha ancora effettuato un deposito) può prelevare le vincite quando gioca i suoi primi tre bonus gratis sul suo conto, durante i primi 30 giorni dopo la data di creazione del conto. Dopo tre bonus giocati o dopo 30 giorni dalla data della registrazione, le vincite ricevute da un bonus gratis saranno considerate non incassabili e verranno detratte dal saldo o dal prelievo.",
            "4.12. Nel caso in cui un giocatore abbia terminato/giocato il proprio requisito di scommessa, il casinò ha il diritto di adeguare il saldo al prelievo massimo consentito e remuovere qualsiasi saldo  rimanente . Nei casi in cui un giocatore non è idoneo a effettuare un prelievo, il casinò ha il diritto di remuovere le vincite e il saldo una volta soddisfatti i requisiti."
        ]
    }, {
        title: "Ritiro termini & condizioni",
        content: [
            "5.1. Tutti i depositi di 249 USD / EURO / GBP / AUS o meno hanno un prelievo massimo di 10X il deposito per la sessione di gioco specifica (tutte le altre valute ancorate a USD). Una volta che un prelievo è stato approvato, il casinò applica la regola di 10X e corregge il ritiro per riflettere accuratamente la regola 10X. I fondi rimanenti sono considerati 'non estraibile' e vengono cancellati dal saldo del giocatore. Bonus di deposito utilizzati durante una sessione di gioco che si traduce in una richiesta di prelievo in sospeso, sono essi anche 'non estraibili' e possono essere dedotti da tale particolare ritiro in attesa.",
            "5.2. I clienti possono presentare la loro richiesta di prelievo in qualsiasi momento. Il tempo di approvazione potrebbe richiedere da uno a quattordici (1 - 14) giorni lavorativi per avviare il processo di autorizzazione completato con successo. L’elaborazione di un prelievo in sospeso richiede l'autorizzazione da parte del servizio antifrode, come ok.",
            "5.3. Tutti i bonus richiesti dal giocatore non possono essere ritirate o rimborsate dal Casinò, e saranno rimossi dalle vincite del giocatore sulla lavorazione del ritiro.",
            "5.4. Tutti i prelievi (Wire, Netteller, Direct-to-Card, ecc) sono soggetti ad una tassa di 10-40 $, €, £ per il trasferimento. Le tariffe si basano dal posto, sono determinate dal nostro sistema di pagamento e sono a carico del singolo giocatore",
            "5.5. Per l’approvato di un ritiro, verrà richiesto al giocatore di presentare alcuni documenti e informazioni al fine di verificare il conto. Il mancato rispetto o mancata presentazione di tali documenti ed informazioni entro 30 giorni dal momento del ritiro che è stato richiesto, comporterà la revoca per essere cancellato. È responsabilità del singolo giocatore di inviare la documentazione richiesta. Ritiro e approvazione dipendono da tutte le condizioni descritte e dalla verifica di tutti i documenti richiesti.",
            "5.6. Un giocatore non può ritirare le vincite fino a quando non ha ancora completato il requisito di scommessa attaccati al loro accordo. Essi devono completare i requisiti di scommessa prima di effettuare una richiesta di prelievo, o rischiano di avere il ritiro negato.",
            "5.7. Il casinò ha il diritto di rifiutare, trattenere un bonus o una vincita in caso di deposito di annullamento, restituiti, negati da qualsiasi servizio di pagamento o processore in qualsiasi momento.",
            "5.8. Se il giocatore ha scommesso sui giochi vincolati dal bonus a cui non è consentito di giocare, il casinò si riserva il diritto di cancellare qualsiasi ritiro e confiscare tutte le vincite. Prima di iniziare a giocare con un bonus di deposito o chip gratuito, è responsabilità del giocatore controllare e capire quali sono le limitazioni di quel particolare bonus o chip.",
            "5.9. Se non diversamente specificato, e anche se non ci sono limiti superiori a vincite contante, il Casinò gestisce un limite massimo di ritiro di 10.000 crediti al mese e 1.500 crediti per il ritiro. Per i prelievi al di sopra della soglia mensile, i pagamenti saranno soddisfatti a rate fino a quando l'intero importo verrà pagato. Questa soglia non si applica per il jackpot progressivo. I giocatori possono ritirare fino a 1.500 crediti (pari a 1.500 dollari) per ogni richiesta. Se l'importo che si desidera ritirare è maggiore di 1500 allora i fondi rimanenti saranno lasciati nel conto. Una volta che il primo prelievo sarà approvato, il giocatore potrà richiedere un altro prelievo con i soldi sul suo conto. Il giocatore ha il diritto di annullare la richiesta di prelievo in qualsiasi momento, mentre è ancora in corso. Ciò vale anche per i ritiri di jackpot progressivi.",
            "5.10. L'importo minimo per un prelievo è di 50 €/$/£/AUD/o 500 ZAR.",
            "5.11. Tutti i depositi devono essere giocati almeno una volta (1X) la somma del deposito, al fine di beneficiare di un ritiro. Un ritiro non può essere approvato a meno che non ci sia una scommessa minima di 1X del deposito. I depositi effettuati nell'ambito delle promozioni sono soggetti a Termini e Condizioni specifiche di promozione e requisiti di scommessa.",
            "5.12. Un giocatore che non ha depositato sul suo conto per un mese o più (30+ giorni), sarà considerato un giocatore di Bonus gratuito e non sarà idoneo a ritirare le vincite da Bonus gratuiti o denaro concessogli dal casinò. La possibilità di ritirare le vincite dai Bonus gratuiti è riservata ai nostri giocatori attivi."]
    }, {
        title: "Accordo sulla privacy",
        content: ["6.1. Il Casinò è obbligato a mantenere tutte le informazioni personali fornite dal giocatore sicure e protette. Qualsiasi dettaglio presentate del giocatore nel Casinò in qualsiasi momento, rimarrà solo nel Casinò e non sarà dato al di fuori a terzi o agenzie.",
            "6.2. E' esclusiva responsabilità del giocatore mantenere tutti i nomi utente e le password relative al loro conto nel casinò al sicuro e protette. Il Casinò non porterà la responsabilità per qualsiasi utilizzo dei dati del conto da una persona che non è il giocatore originariamente registrato.",
            "6.3. Il Casinò si riserva il diritto di usare il nome del giocatore, come parte di eventi promozionali (ad esempio, pubblicando una grande vittoria del giocatore), senza alcun compenso o pagamento effettuato per giocatore e il casinò non sarà tenuto a chiedere un'approvazione preventiva da parte del giocatore.",
            "6.4. Le condizioni descritte definiscono una comprensione e un accordo definitivo ed esclusivo tra Lei (il giocatore) e il Casinò e aderiscono a tutti gli accordi precedenti, dichiarazioni e intese tra Lei e il Casino " + casino.name + " per quanto riguarda la partecipazione nel Casinò.",
            "6.5. Il Casinò si riserva il diritto di trattenere il deposito o ritirare eventuali crediti o bonus a nostra discrezione. Il Casinò si riserva il diritto di ritirare o modificare i termini di questo programma in qualsiasi momento e senza alcun preavviso.",
            "6.6. Questa offerta non è applicabile per i dipendenti, partner, fornitori o chiunque altro abbia un business e /o un rapporto professionale con il Casino " + casino.name + ". Proprietà intellettuale",
            "6.7. Con tale contratto, il Casinò concede all'utente un diritto trasferibile e revocabile, non esclusivo di non utilizzare il suo sito web, il suo software e / o il suo servizio, se non per tutti i servizi connessi. Questo diritto si estende anche a tutti i diritti di proprietà intellettuale di essere collegato ad esso.",
            "6.8. L'utente riconosce e accetta che il diritto d'autore, il marchio e tutti gli altri diritti di proprietà intellettuale esercitata su qualsiasi materiale e fornito nei contenuti è appartenente al sito web e / o al software del casinò, rimane rigorosamente di nostra proprietà. L'utente può utilizzare il materiale citato sopra solo in accordo con questi termini e condizioni.",
            "6.9. Casino " + casino.name + " è il nome commerciale del Casinò, e il giocatore non ha diritto ad utilizzare o impiegare un tale termine, o altri termini, di grafica, in testi, in concetti o in metodologie, prendendo dal Sito Web il materiale ivi contenuto.",
            "6.10. L'utente riconosce e accetta il fatto che il materiale e i contenuti riprodotti sul sito web del Casinò sono messi a loro disposizione a titolo solamente non commerciale. Questo materiale e questi contenuti devono essere scaricati sul disco fisso di un computer solo per servire a questo obiettivo. Se uno qualsiasi dei materiali dei giochi del casinò verrà disturbato, danneggiato o forgiato diventerà nullo e inutilizzabile. Indipendentemente da come è stato ottenuto se fisicamente o elettronicamente. La responsabilità del materiale dei giochi del casinò che contengono errori è limitata solo ad una sostituzione dello stesso materiale.",
            "6.11. Ogni altro utilizzo è severamente proibito, così come la copia, la falsificazione, la riproduzione, la pubblicazione, la trasmissione e la distribuzione del lavoro derivante da questo materiale e da questi contenuti.",
            "6.12. Possibili azioni criminali o sospette possono essere segnalate alle autorità competenti. Quando saranno chiamati a farlo i giocatori potranno avere bisogno di produrre alcuni documenti di verifica durante le transazioni di prelievi o depositi con il casinò. Tale prova potrebbe essere d'identità con foto chiara, la prova di indirizzo, copie delle carte di credito e di moduli di dichiarazione di acquisto. Questo requisito è per rendere valida la sicurezza dei nostri giocatori e nel rispetto delle norme di gioco.",
            "6.13. Questo contratto è considerato debitamente firmato e accettato da entrambe le parti: Il Casinò e l'Utente.",
            "6.14. Le condizioni descritte definiscono una comprensione e un accordo definitivo ed esclusivo tra Lei (il lettore) e Casino " + casino.name + " per quanto riguarda la partecipazione al Casinò nel completare tutti gli accordi precedenti, le dichiarazioni e l’intesa tra Lei e Casino " + casino.name + "."]
    }, {
        title: "Invita un amico termini & condizioni",
        content: ["7.1. I termini e le condizioni generali applicabili al programma per chi porta un amico e tutti gli altri programmi offerti da Casino " + casino.name + ". Inviando tutte le informazioni necessarie nel programma che si riferiscono a un amico, sia lei (persona a cui si fa riferimento) dia il consenso a tutti i termini di questo programma e abbia acconsentito alla concessione dei suoi dati, forniti dalla persona a cui si fa riferimento. Inviando queste informazioni, la persona a cui si fa riferimento deve inviare una e-mail inviata a lui / lei nel Casino " + casino.name + " chiedendogli/le di firmare per un conto di un giocatore reale. Inviando le informazioni per il programma invita un amico, la persona di riferimento è pienamente consapevole del fatto che la persona interessata ha presentato i propri dati in buona fede.",
            "7.2. Le persone che utilizzano lo stesso computer (la registrazione dallo stesso indirizzo IP) non saranno autorizzate a partecipare al bonus di chi porta un amico per motivi di sicurezza. I giocatori non devono già essere membri esistenti del Casino " + casino.name + ". L'indirizzo e-mail fornito dalla persona interessata non deve essere associato a qualsiasi giocatore di turno di Casino " + casino.name + " né deve essere la stessa e-mail del membro di riferimento.",
            "7.3. Il membro del rinvio dovrà compilare informazioni specifiche sul suo amico; tali informazioni includono, ma non si limitano a nome, cognome e indirizzo e-mail. Dopo che tutte le informazioni richieste saranno state fornite, presentate e poi verificate dal Casino " + casino.name + ", allora e solo allora il bonus sarà fornito ad entrambi i soci.",
            "7.4. Al fine di qualificarsi per il bonus per chi porta un amico, la persona interessata deve prima registrarsi con un conto in denaro reale e depositare un minimo di 25 $, €, £ in quel conto. Entrambi riceveranno il bonus solo dopo che avranno depositato 25 $, €,£ nel proprio conto come giocatori reali e dovranno lasciar passare 48 ore prima di ricevere il bonus nei loro conti reali."]
    }, {
        title: "Politica di ritorno",
        content: ["Grazie per aver aperto un conto con noi e depositato sul tuo conto presso i nostri siti Web.",
            "Noi ti offriamo la possibilità di un rimborso su tutti i depositi effettuati sui nostri siti Web. Tuttavia, devono essere soddisfatte determinate condizioni. Ti invitiamo a leggere le condizioni spiegate più in dettaglio di seguito, al fine di vedere la condizione applicabile alla tua situazione, seguendo la procedura corretta.",
            "La nostra politica di restituzione è semplice, diretta e verrà' elaborata immediatamente.",
            "Tuttavia, c'è un lasso di tempo limitato come indicato:",
            <ul>
                <li>Entro 24 ore dal deposito originale.</li>
                <li>Come richiesto al momento dell'apertura del conto, la conformità richiesta al momento
                    dell'apertura del conto deve essere completata e approvata dal nostro dipartimento addetto.
                </li>
                <li>Malfunzionamento dei giochi - Screenshot richiesto.</li>
                <li>Impossibile riprodurre i crediti - Saldo non giocato.</li>
                <li>Addebito errato - dichiarazione richiesta.</li>
            </ul>,
            <>Tassa di elaborazione <br/> È prevista una commissione per l'elaborazione e l'emissione del rimborso.
                Le diverse tasse (nella tua valuta) sono i seguenti:</>,
            <ul>
                <li>Verifica carta - 55.00</li>
                <li>SEPA - 20.00</li>
                <li>Cavo SWIFT - 75.00</li>
            </ul>,
            "Rimborsi mancati o in ritardo (se applicabile):",
            <ul>
                <li>Se non hai ancora ricevuto il rimborso, controlla di nuovo il tuo conto bancario.</li>
                <li>Quindi contattando direttamente il servizio clienti della carta di credito, potrebbe essere
                    necessario del tempo prima che il rimborso venga ufficialmente registrato.
                </li>
                <li>Se hai fatto tutto questo e non hai ancora ricevuto il rimborso, ti preghiamo di contattarci
                    all'indirizzo <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a>.
                </li>
            </ul>]
    }]
}


terms.en = untilWeDontHaveOther
terms.de = untilWeDontHaveOtherDE
terms.it = untilWeDontHaveOtherIT
terms.fr = untilWeDontHaveOtherFR
terms.es = untilWeDontHaveOtherES


export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}

export const promos = {

    bonus_slider_data: {
        en: [
            {
                background: "url(/brand/images/bonus_images/1.jpg) no-repeat center center / cover",
                button: {style: {}, text: "Play now"},
                image: {src: "/brand/images/bonus_images/1en.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.jpg) no-repeat center center / cover",
                button: {text: "Play now"},
                image: {src: "/brand/images/bonus_images/1en.svg"}
            },
        ],

        de: [
            {
                background: "url(/brand/images/bonus_images/1.jpg) no-repeat center center / cover",
                button: {text: "JETZT SPIELEN"},
                image: {src: "/brand/images/bonus_images/1de.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.jpg) no-repeat center center / cover",
                button: {text: "JETZT SPIELEN"},
                image: {src: "/brand/images/bonus_images/1de.svg"}
            },
        ],
        fr: [
            {
                background: "url(/brand/images/bonus_images/1.jpg) no-repeat center center / cover",
                button: {text: "JOUEZ MAINTENANT"},
                image: {src: "/brand/images/bonus_images/1fr.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.jpg)  no-repeat center center / cover",
                button: {text: "JOUEZ MAINTENANT"},
                image: {src: "/brand/images/bonus_images/1fr.svg"}
            },
        ],
        it: [
            {
                background: "url(/brand/images/bonus_images/1.jpg) no-repeat center center / cover",
                button: {text: "GIOCA ORA"},
                image: {src: "/brand/images/bonus_images/1it.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.jpg) no-repeat center center / cover",
                button: {text: "GIOCA ORA"},
                image: {src: "/brand/images/bonus_images/1it.svg"}
            },
        ],
        es: [
            {
                background: "url(/brand/images/bonus_images/1.jpg) no-repeat center center / cover",
                button: {text: "JUEGUE YA"},
                image: {src: "/brand/images/bonus_images/1es.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.jpg)  no-repeat center center / cover",
                button: {text: "JUEGUE YA"},
                image: {src: "/brand/images/bonus_images/1es.svg"}
            },
        ],
    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {
            category: 1,
            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },


            bonus_details: {
                en: "400% WEEKLY PROMOTIONS AND SPECIALS",
                de: "400% WILLKOMMENSPAKET",
                fr: "400% BONUS DE BIENVENUE",
                es: "400% PAQUETE DE BIENVENIDA",
                it: '400% PACCHETTO DI BENVENUTO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>DEPOSIT BONUS</h2>
                    <p>Your first deposit will be matched with 400% WELCOME BONUS</p>
                    <p>Here at Candy Land Casino you can play with two types of depositing incentives. Deposit bonuses
                        or Cashback Insurance.</p>
                    <p>Deposit bonuses are a bonus given to a player along with their deposit. Deposit bonuses are in
                        essence a match bonus. Whatever amount you deposit, the casino will match. The higher the
                        deposit amount, the higher your match bonus will be.</p>
                    <p>Click <a href="/terms">here</a> for the terms and conditions of this bonus.</p> </>,
                de: <>
                    <h2>EINZAHLUNGSBONUS</h2>
                    <p>Auf Ihre erste Einzahlung erhalten Sie einen 400% WILLKOMMENSBONUS.</p>
                    <p>Hier bei Candy Land haben Sie zwei Optionen, um mit Einzahlungsangeboten zu spielen. Entweder mit
                        einem Einzahlungsbonus oder mit einer Cashback- Versicherung.</p>
                    <p>Einzahungsboni sind Boni, die einem Spieler auf die Einzahlung angerechnet werden. Unabhängig
                        davon, welchen Betrag Sie einzahlen, wird die Spielbank die Prozente des Bonus direkt anrechnen.
                        Je höher der Einzahlungsbetrag, desto höher ist der gutgeschriebene Bonusbetrag.</p>
                    <p>Klicken Sie <a href="/terms">hier</a> für die Bonusbedingungen dieses Bonus.</p></>,
                fr: <><h2>BONUS SUR DÉPÔT</h2>
                    <p>Sur votre premier dépôt obtenez un BONUS DE BIENVENUE DE 400% !</p>
                    <p>Exemple: Déposez 100€ et jouez avec 500€ !</p>
                    <p>Sur Candy Land Casino, vous pouvez jouer avec 3 types d'offres sur chacun de vos dépôts.</p>
                    <p>Bonus avec ou sans exigences ou Assurance perte Cashback. Pour toute information supplémentaire
                        un agent de l'assistance chat en ligne se tient à votre entière disposition 24/7.</p>
                    <p>Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </p> </>,
                es: <><h2>BONO DE DEPÓSITO</h2>
                    <p>Su primer depósito será compensado con un BONO DE BIENVENIDA DEL 400%.</p>
                    <p>Aquí en Candy Land Casino puedes jugar con dos tipos de incentivos de depósito. Bonos de depósito
                        o seguro de reembolso de dinero.</p>
                    <p>Los bonos de depósito son bonos otorgados a un jugador junto con su depósito. Los bonos de
                        depósito son en esencia un bono de compensación. Cualquier cantidad que deposite, el casino la
                        igualará. Cuanto mayor sea el monto del depósito, mayor será su bonificación.</p>
                    <p> Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono.</p> </>,
                it: <><h2>BONUS DI DEPOSITO</h2>
                    <p>Il tuo primo deposito sarà abbinato al BONUS DI BENVENUTO DEL 400%.</p>
                    <p>Qui al Candy Land Casino puoi giocare con due tipi di incentivi col deposito. Bonus di deposito o
                        assicurazione di rimborso.</p>
                    <p>I bonus di deposito sono un bonus dato a un giocatore combinato col deposito. I bonus di deposito
                        sono essenzialmente un bonus di partita. Qualunque importo tu depositi, il casinò corrisponderà.
                        Maggiore è l'importo del deposito, maggiore sarà il bonus della tua partita.</p>,
                    <p>Clicca<a href="/terms">qui</a> per i termini e le condizioni di questo bonus</p> </>
            }
        },
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },

            bonus_details: {
                en: "100% CASHBACK INSURANCE",
                de: "100% CASHBACK-VERSICHERUNG",
                fr: "200 % BONUS SANS EXIGENCES",
                es: "100% SEGURO DE REEMBOLSO",
                it: '100% ASSICURAZIONE DI RIMBORSO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>CASHBACK INSURANCE</h2>
                    <p>Cashback insurance is just as it sounds. Insurance if the house win. Feel confident as you play
                        knowing that your money is guaranteed. Just speak with a support agent once your balance has
                        finished, and they will add your real cashback to your account.</p>

                </>,
                de: <>
                    <p>CASHBACK-VERSICHERUNG</p>
                    <p>Die Cashback-Versicherung ist genau das, wonach es sich anhört. Sollte das Haus gewinnen, greift
                        die Versicherung. Sie brauchen sich beim Spielen keine Sorgen machen, denn Sie wissen, dass Ihr
                        Geld abgesichert ist. Sprechen Sie einfach mit einem Support-Mitarbeiter, sobald Ihr Guthaben
                        aufgebraucht ist, und er wird Ihnen die Cashback-Versicherung in der Kasse hinterlegen.</p>
                </>,
                fr: <>
                    <h2>200% BONUS SANS EXIGENCES</h2>
                    <p>Candy Land casino propose à ses nouveaux joueurs un Méga bonus sans exigences de mises de 200%
                        !</p>
                    <p>Exemple: déposez 100€ et jouez avec 300€, une fois le bonus et dépôt joué une fois, vous pouvez
                        encaissez votre solde à tout moment, seul le montant du bonus vous sera déduit du gain final
                        !</p>
                </>,
                es: <><h2>SEGURO DE REEMBOLSO</h2>
                    <p>El seguro de reembolso de efectivo es tal como suena. Seguro si la casa gana. Siéntase tranquilo
                        mientras juega sabiendo que su dinero está garantizado. Simplemente hable con un representante
                        de soporte una vez que su saldo haya finalizado, y se le agregará el reembolso real a su
                        cuenta.</p>
                </>,
                it: <><h2>ASSICURAZIONE CASHBACK</h2>
                    <p>L'assicurazione cashback è come sembra. L'assicurazione, in caso vincesse il banco. Sentiti
                        sicuro mentre giochi sapendo che i tuoi soldi sono garantiti. Parla con un rappresentante della
                        chat una volta terminato il saldo e aggiungeranno il tuo vero cashback al tuo conto.</p>
                </>,
            }
        },
        {

            name: {
                fr: "BONUS DE BIENVENUE",
            },

            bonus_details: {
                fr: "100% D'ASSURANCE CASHBACK",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>D'ASSURANCE CASHBACK</h2>
                    <p>Jouez tête reposée, Candy Land Casino assure vos arrières !</p>
                    <p>L'assurance 100% Cashback est une assurance en cas de perte sur votre dépôt.</p>
                    <p>Jouez en toute sécurité, votre argent est garanti. Si vous ne faites aucun gain, réclamer à un
                        agent de l'assistance chat en ligne votre Assurance 100% CashBack pour recevoir en CASH
                        l'intégralité de votre dépot !</p>
                    <p>(Le 100% cashback doit être joué 1 fois et a une limite de retrait de 3 fois maximum)</p>
                    <p>Pour toute information supplémentaire un agent de l'assistance chat en ligne se tient à votre
                        entière disposition 24/7.</p>
                </>,
            }
        },

        {

            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "RAFFLE",
                de: "VERLOSUNG",
                fr: "TOURNOIS",
                es: 'SORTEO',
                it: 'LOTTERIA'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>RAFFLE</h2>
                    <p>Join one of our weekly raffles. Every deposit of 25 earns one raffle ticket. The more tickets you
                        earn, the better your chances of winnings a cash prize.</p>
                </>,

                de: <>
                    <h2>VERLOSUNG</h2>
                    <p>Nehmen Sie an einer unserer wöchentlichen Verlosungen teil. Jede Einzahlung i.H.v. 25 erhält
                        automatisch ein Los. Je mehr Lose Sie haben, desto besser stehen die Chancen auf den Gewinn in
                        Cash.</p>
                </>,
                fr: <>
                    <h2>TOURNOIS HEBDOMADAIRES VIP À 50.000€</h2>
                    <p>Chaque semaine Candy Land casino organise des tournois Vip avec 50.000€ de prix cash à partager !
                        Comment ça marche ?</p>
                    <p>Une fois inscrit (100€ cumulé ou pas), chaque euro misé est comptabilisé et vous rapporte 1 point
                        (1€ parié = 1 point), et ces points sont mis en concurrence avec ceux des autres participants.
                        Le joueur effectuant le plus de points repartira avec le premier prix qui est de 10.000€, le
                        second 8.000€, etc... Tous les prix sont en cash et encaissables de suite.</p>
                    <p>Chaque matin le joueur reçoit son classement actualisé en fonction des points effectués.</p>
                    <p>Tous les jeux sont autorisés dans le calcul des points, les prix sont crédités en cash sur la
                        base du classement final.</p>
                    <p>Voici les montants des prix: 25€, 50€, 75€, 100€ , 200€, 250€, 300€, 400€, 500€, 750€, 1.000€,
                        1.500€, 2.000€, 2.500€, 3.000€, 6.000€, 8.000 €, 10.000€</p>
                </>,
                es: <><h2>SORTEO</h2>
                    <p>Únase a uno de nuestros sorteos semanales. Con cada depósito de 25 gana un boleto de sorteo.
                        Cuantos más boletos gane, mayores serán sus posibilidades de ganar un premio en efectivo.</p>

                </>,
                it: <><h2>LOTTERIA</h2>
                    <p>Partecipa a una delle nostre lotterie settimanali. Ogni deposito di 25 guadagna un biglietto
                        della lotteria. Più biglietti guadagni, maggiori sono le possibilità di vincere un premio in
                        denaro.</p>
                </>,
            }
        },
        {

            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "CASHTRAVAGANZA",
                de: "CASHPARTY",
                fr: "LOTERIE",
                es: 'FIESTA DE CASH',
                it: 'CASHPARTY'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>CASHTRAVAGANZA</h2>
                    <p>Discover the craziness of our Cashparty special. You get a guarantee of up to 80% on all your
                        deposits during the Cashparty period. Next day you get cash right in your account.</p>
                </>,

                de: <>
                    <h2>CASHPARTY</h2>
                    <p>Entdecken Sie die Vorzüge unseres Cashparty-Specials. Dabei erhalten Sie eine Garantie von bis zu
                        80% auf all Ihre Einzahlungen während des Cashparty-Zeitraums. Am nächsten Tag erhalten Sie Ihr
                        Bargeld direkt zurück in Ihre Kasse.</p>
                </>,
                fr: <>
                    <h2>LOTERIE VIP À 50.000€</h2>
                    <p>Candy Land casino propose chaque mois à ses joueurs des loteries Vip avec 50.000€ de prix cash à
                        partager !</p>
                    <p>Aucune inscription est requise, connectez vous et jouez durant la période de la promotion et sur
                        chaque dépôt vous obtiendrez un ou plusieurs tickets de loterie.</p>
                    <p>Plus vous gagnez de tickets, meilleures sont vos chances de gagner un prix en cash. Les montants
                        des tickets sont de</p>
                    <p>10 / 25 / 50 / 100 / 250 / 500 / 1.000 / 2.000 / 3.000 / 5.000€.</p>
                </>,
                es: <><h2>FIESTA DE CASH</h2>
                    <p>Descubra la locura de nuestra especial ¨Fiesta de Chash¨. Obtenga una garantía de hasta el 80% en
                        todos sus depósitos durante el período de ¨Fiesta de Cash¨. Al día siguiente, obtendrá el
                        efectivo en su cuenta.</p>

                </>,
                it: <><h2>CASHPARTY</h2>
                    <p>Scopri la follia del nostro speciale Cashparty. Ottieni una garanzia fino all'80% su tutti i tuoi
                        depositi durante il periodo di Cashparty. Il giorno successivo ricevi denaro direttamente sul
                        tuo conto.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "COMP POINTS",
                de: "TREUEPUNKTE",
                fr: "JOUEZ-ENCAISSEZ",
                es: 'PUNTOS DE FIDELIDAD O PUNTOS COMP',
                it: 'PUNTI COMP'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>COMP POINTS</h2>
                    <p>Get your comp points. All depositing and eligible players and claim their comp points on real
                        money wagered. The comp point rate is 1 comp point per €1 wagered where 100000 comp points can
                        be converted into €100.</p>
                </>,

                de: <>
                    <h2>TREUEPUNKTE</h2>
                    <p>Holen Sie sich Ihre Treuepunkte. Alle einzahlenden und berechtigten Spieler können Ihre
                        Treuepunkte auf gewettetes Echtgeld anrechnen lassen. Die Treuepunkt-Rate beträgt 1Punkt pro 1€
                        Einsatz, wobei 100.000 Comp-Punkte in 100€ umgewandelt werden können.</p>
                </>,
                fr: <>
                    <h2>JOUEZ-ENCAISSEZ</h2>
                    <p>Candy Land casino propose chaque mois à ses joueurs des Jouez et Encaissez.</p>
                    <p>Comment ça marche ?</p>
                    <p>Une fois inscrit (100€ cumulé ou pas), sur chaque tranche de 1000 points réalisée sur la base de:
                        1€ parié = 1 point, le joueur reçoit en retour a l'issue de la promotion 10€ CASH, en plus des
                        offres Vip sur chaque dépôt !</p>
                    <p>Exemple: si un joueur réalise 50,000 points, il recevra 500€ de prix en Cash encaissable à
                        l'issue du Jouez et Encaissez.</p>
                </>,
                es: <><h2>PUNTOS DE FIDELIDAD O PUNTOS COMP</h2>
                    <p>Obtenga sus puntos de fidelidad. Todos los jugadores son elegibles, que depositan y reclaman sus
                        puntos de fidelidad con dinero real apostado. La tasa de puntos de fidelidad es de 1 punto comp
                        por cada € 1 apostado donde 100000 puntos comp se pueden convertir en €100.</p>

                </>,
                it: <><h2>PUNTI COMP</h2>
                    <p>Ottieni i tuoi punti comp. Tutti i giocatori depositanti e idonei possono richiedere i loro punti
                        comp su denaro reale scommesso. La percentuale di punti comp è 1 punto comp per $ 1 scommessi
                        dove 100000 punti comp possono essere convertiti in $ 100.</p>
                </>,
            }
        },
        {
            name: {
                fr: "PROMOTIONS HEBDOMADAIRES",

            },
            bonus_details: {
                fr: "WEEKY CASHBACK",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>WEEKY CASHBACK</h2>
                    <p>Le WEEKY-CASHBACK consiste à vous donner, en plus des offres sur chaque dépôt, une Assurance
                        perte Globale de tous les dépôts réalisés durant la promotion, si aucun gain n'as été réalisé
                        durant cette période. Voici en détail le pourcentage de l'assurance globale:</p>
                    <p>100€+ = 15%</p>
                    <p>200€+ = 20%</p>
                    <p>500€+ = 25%</p>
                    <p>1000€+ = 30%</p>
                </>,
            }
        },
        {
            name: {
                fr: "PROMOTIONS HEBDOMADAIRES",

            },
            bonus_details: {
                fr: "POINTS DE FIDÉLITÉS",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>POINTS DE FIDÉLITÉ</h2>
                    <p>Les joueurs ayant effectué un dépôt sont éligibles et reçoivent des points de fidélités sur
                        chaque session de jeu.</p>
                    <p>Vous pouvez les convertir vous même en argent réel sur votre compte.</p>
                    <p>Le taux des points de fidélités est de 1 point chaque 1€ parié, 100.000 points de fidélités
                        représentent 100€ cash d'argent réel.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS",

            },
            bonus_details: {
                en: "TOURNAMENTS",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <h2>TOURNAMENTS</h2>
                    <p>Every day at Casino Candy Land there is a new promotion available.</p>
                    <p> Take part in our weekly Tournaments where every bet you make earns a Tourney Point and those
                        with the most Tourney Points get to share the prize pool of $50K.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

export default casino_contents





